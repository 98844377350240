import React, {useEffect} from 'react'
import './../../../css/MLModelTraining.css'
import MultiValueReceiveDialog from "./dialogs/MultiValueReceiveDialog";
import NumberProperties from "./NumberProperties";
import {useMLTrainingOps} from "../../../../hooks/useMLTrainingOps";
import {usePersistedValueState} from "../../../../../lib/hooks/StorageHooks";
import NumberRecognitionLabels from "./NumberRecognitionLabels";
import TextRecognitionLabels from "./TextRecognitionLabels";
import {useHistory} from "react-router-dom";

// Show MLModelTraining page:
export default function MLModelTraining(props) {
    // used in page redirection:
    const history = useHistory()

    // text / number recognition: default 'text'
    const [recognitionValue, set_recognitionValue] = usePersistedValueState("local_mltraining_recognitionValue","text");

    // mltraining related ops:
    const mlTrainOps = useMLTrainingOps(props)

    // {"recognise": "text", "all_labels":["name", "Height", "TestLabel"],"all_fields":[{"name":"Value", "type": "text"}]}
    // {"recognise": "number", "all_labels":["Lbll","Lb12"], "all_fields": [{"name": "age", "type’ "number"}]}

    // on change model recognition type:
    function onChangeRecognitionType(e) {
        if(!mlTrainOps.hasLabels()) {
            // type can be changed only if no label is present
            set_recognitionValue(e.target.value)    // change recognition type
            mlTrainOps.withClone(clone=> {  // with cloned activity content
                if(e.target.value==="text"){
                    clone.recognise = "text";   // change recognise type to text
                } else {
                    clone.recognise = "number" // change recognise type to number
                    if(clone.value_names.length===0)    // if no value name
                        clone.value_names.push("Value") // push 'Value' as default
                }
            })
        } else {
            // if any label is set, display following message:
            props.toastError("Clear all labels to change recognition type.")
        }
    }

    useEffect(()=>{
        // set recognise type when view load or whenever activity content changes:
        set_recognitionValue(mlTrainOps.getTrainingJsonContent().recognise)
    },[props.userSelectedActivityContent])  // when activity content updates,
    // execute the effect() block


    return (
        <>
            {/* It is used to receive multiple field values at once */}
            <MultiValueReceiveDialog {...props} />

            <section>
                <div className="jumbotron jumbotron-fluid text-center">
                    <div className="container">
                        <h1 className="display-6 mont_txt">Build your Machine Learning Model</h1>
                        <p className="lead poppins_txt">Efficient , predictive modelling requires efficient Big Data.
                        </p>
                    </div>
                </div>
                <div className="container px-5 poppins_txt">
                    <div className="form-group">
                        <label htmlFor="rec_type">Select Machine Learning Recognition Type</label>
                        <select className="form-control" onChange={onChangeRecognitionType} value={recognitionValue}>
                            <option value="text">Text</option>
                            <option value="number">Number</option>
                        </select>
                    </div>

                    {recognitionValue==="number"?(
                        // Manage the properties of numeric fields:
                        <NumberProperties {...props}  />
                    ):("")}

                    <div className="mt-5">
                        <label>Train your Machine Learning Model</label>
                        <div className="card p-5">
                            {recognitionValue==="number"?(
                                // manage number recognition labels:
                                <NumberRecognitionLabels {...props} />
                            ):(
                                // manage text recognition labels:
                                <TextRecognitionLabels {...props} />
                            )}
                        </div>
                        <div className="mt-2 d-flex flex-row-reverse">

                            <button  className="btn btn-info" style={{'margin-left':'1em'}} onClick={()=>{
                                // on click train model, perform the network operation to train model
                                mlTrainOps.trainModel(()=>{
                                    // on successful training, redirect to /activity
                                    history.push('/activity')
                                })
                            }}>
                                <i className="fas fa-envelope-open-o"/>
                                Train Model
                            </button>



                            <button id="add_label" className="btn btn-info" onClick={()=>{
                                mlTrainOps.addLabel()
                            }}>
                                <i className="fas fa-envelope-open-o"/>
                                Add Label
                            </button>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}



/*
const textRecogContent = {recognise: "text", labels:{
        label1 : {
            name : "Light on",
            values : [
                "light on",
                "turn light on"
            ]
        },
        label2 : {
            name : "Light off",
            values : [
                "light off",
                "turn light off"
            ]
        },
        label3 : {
            name : "Light off",
            values : [
                "light off",
                "turn light off"
            ]
        },
    }}
const numberRecogContent = {recognise: "number", labels:{
        label1 : {
            name : "Light on",
            values : [
                {value : [
                        {name:"age", value:12},
                        {name:"height", value:4127}, ]},
                {value : [
                        {name:"age", value:12},
                        {name:"height", value:4127}, ]}
            ]
        },
        label2 : {
            name : "Light off",
            values : [
                {value : [
                        {name:"age", value:12},
                        {name:"height", value:4127}, ]},
                {value : [
                        {name:"age", value:12},
                        {name:"height", value:4127}, ]}
            ]
        },
        label3 : {
            name : "Light off",
            values : [
                {value : [
                        {name:"age", value:12},
                        {name:"height", value:4127}, ]},
                {value : [
                        {name:"age", value:12},
                        {name:"height", value:4127}, ]}
            ]
        },
    },
    value_names : ["age","height"]
}
*/
