import React from 'react'
import useAiConnectRestApi from "./useAiConnectRestApi";
import { useHistory } from "react-router-dom";
import { appData } from "../util/appData";
import useCallWraps from "./useCallWraps";
import { confirmDiagOps } from "../../lib/components/popups/ConfirmationDialog";
import { assertEmailID, assertNotEmpty } from "../../lib/js/FormValidationUtil";
import { ValidationFail } from "../../lib/js/Exceptions";
import useLoginOps from "./useLoginOps";
import useCustomActivityOps, { globalActivityCallbacks } from "./useCustomActivityOps";

import { cloneObj, delay } from "../../lib/js/jsUtil";

export default function useProjectAndActivityOps(props) {
    const history = useHistory()
    const call = useCallWraps(props)
    const restApi = useAiConnectRestApi(props)
    const keys = appData.keys
    const defaultData = appData.defaultNetworkResponses

    const userProjects = props.userProjects
    const userActivities = props.userActivities

    const loginOps = useLoginOps(props)
    const actOps = useCustomActivityOps(props)

    return {
        // return user all projects
        projects() {
            return userProjects
        },
        // return user all activities
        activities() {
            return userActivities
        },
        // return all project except the one provided as argument
        filterProject(project) {
            return this.projects().filter((_project) => _project.project_uid !== project.project_uid)
        },
        // return project, whose id is provided in argument
        getProjectById(project_uid) {
            return this.projects().filter((_project) => _project.project_uid === project_uid)[0]
        },
        // return all activities in the given project except the one provided in the argument
        filterActivity(project, activity) {
            return this.activities().filter((_activity) => !(_activity.project_uid === project.project_uid && _activity.activity_uid === activity.activity_uid))
        },
        // find all activities whose project_uid is given
        filterProjectActivities(project_uid) {
            return this.activities().filter((activity) => activity.project_uid === project_uid)
        },
        // count size of project activity
        countProjectActivities(project_uid) {
            return this.filterProjectActivities(project_uid).length
        },
        // check is project with same name (given in arg) already exists
        isProjectNameAlreadyExists(projectName) {
            projectName = projectName.trim().toLowerCase()
            return this.projects().filter((project) => project.project_name.toLowerCase().trim() === projectName).length === 1
        },
        // check if another activity with same name is in given project
        isProjectActivityNameAlreadyExists(project_uid, activity_name) {
            activity_name = activity_name.trim().toLowerCase()
            return this.filterProjectActivities(project_uid).filter((activity) => activity.activity_name.toLowerCase().trim() === activity_name).length === 1
        },


        // Networking Ops:

        // called when use create project:
        async createNewProject(projectName) {
            call.validationCheck(() => {
                // get userEmailId from loginDetails
                const userEmailId = loginOps.userEmailId()
                // check validity of emailId & project name
                assertEmailID(userEmailId)
                assertNotEmpty(projectName)

                // check if project name is not already present
                if (this.isProjectNameAlreadyExists(projectName)) {
                    throw ValidationFail("Project name already exists.")
                }

                // we create project object to be saved on server.
                // project object contains details like: project_uid, project_name, creation_data.
                // as (given in following project json object)
                const time = new Date().getTime()
                const newProject = {
                    "project_uid": userEmailId + '_project_' + time,
                    "project_name": projectName,
                    "creation_date": "" + time
                }

                // we make network request to save new project
                // on server. Once request is successful.
                // we update the userProjects global data with
                // old + newly created projects.
                call.asyncThen(call.updateUserDocKeyArrValue(
                    // key to use, to save in UserDocument (on server in MongoDB)
                    keys.userDocKeys.projects,
                    // new Data to save:
                    [...this.projects(), newProject],
                    // once successful, we update new data in globally stored data.
                    props.set_userProjects
                ).then(x => {
                    return true
                }))

                // whenever an new project is created,
                // we want to display the projects table.
                // And to unselect the project in table.
                // we set selected project to null.
                // resetting to show projects table.
                // if select project is null, then selected
                // activity must also be null
                props.set_userSelectedProject(null)
                props.set_userSelectedActivity(null)
            })
        },


        /**
         * Called when user create new activity.
         * @param project_uid (activity will be saved in this project)
         * @param activityName
         * @param activityType  (AI, BP, GD, ML)
         */
        createNewActivity(project_uid, activityName, activityType, stringXml = "default", onCreateActivity = (newActivity) => { }) {
            call.validationCheck(() => {
                // check that project must be selected by user, to create activity in:
                if (project_uid === undefined || project_uid == null || (project_uid + "").length < 5) {
                    throw new ValidationFail("Project not selected")
                }
                // validate values of : emailId, activityName, activityType
                const userEmailId = loginOps.userEmailId()
                assertNotEmpty(activityName)
                assertNotEmpty(activityType)

                // check if Activity with same name already exists in given project or not.
                if (this.isProjectActivityNameAlreadyExists(project_uid, activityName)) {
                    throw ValidationFail("Activity name already exists.")
                }

                // create new activity object:
                const time = new Date().getTime()
                const newActivity = {
                    "project_uid": project_uid,
                    "activity_uid": userEmailId + '_activity_' + time,
                    "activity_name": activityName,
                    "activity_type": activityType,
                    "creation_date": "" + time,
                    "last_modified_date": "" + time
                }
                const defautlNewContent = {
                    "about": "",
                    "workspaceContent": "<xml xmlns=\"http://www.w3.org/1999/xhtml\"></xml>",
                    "activityMetadata": newActivity

                }

                // we make network request to save new activity
                // on server in UserDocument(mongoDB).
                call.asyncThen(async () => {
                    await call.updateUserDocKeyArrValue(
                        // key to use, to save in UserDocument (on server in MongoDB)
                        keys.userDocKeys.activities,
                        // new Data to save:
                        [...this.activities(), newActivity],
                        // once successful, we update new data in globally stored data.
                        props.set_userActivities
                    ).then(data => {
                        props.set_userSelectedActivity(newActivity)
                        props.set_userSelectedActivityContent(defautlNewContent)
                        // to perform save as operation inside workspace
                        if (stringXml !== "default") {
                            actOps.updateActivityContentDocViaClone(
                                clone => clone.workspaceContent = stringXml,  // with activity_Content clone, update workspaceContent
                                () => props.toastSuccess("Saved Successfully.") // when update on server, toast successful message.
                                , newActivity
                            )
                        }
                    })
                    onCreateActivity(newActivity)
                    //props.openActivity(newActivity)

                    props.set_userSelectedActivity(newActivity)
                    props.set_userSelectedActivityContent(defautlNewContent)
                    history.push("/activity")
                })
            })

        },

        saveAsNewActivity(newActivityName) {
            const project_uid = props.userSelectedProject.project_uid;
            const activityType = props.userSelectedActivity.activity_type;

            function getNewActivityContent(newActivity) {
                const activityContent = { ...props.userSelectedActivityContent }
                activityContent.activityMetadata = newActivity
                activityContent.workspaceContent = globalActivityCallbacks.getActivityBlocklyXMLContent()

                // remove all files (having URL), new activity create it's own assets:
                try {
                    activityContent.model_file = null
                } catch (e) { }
                try {
                    activityContent.captureImageUrlPath = null
                } catch (e) { }


                return activityContent
            }

            this.createNewActivity(project_uid, newActivityName, activityType, (newActivity) => {
                actOps.saveActivityContent(
                    newActivity,
                    getNewActivityContent(newActivity),
                    () => {
                        props.toastSuccess("Activity Saved Successfully.")
                    }
                )
            })
        },

        /**
         * called when user click on delete project button
         * @param project (project json object saved in global storage)
         */
        deleteProject(project) {
            // show confirmation dialog
            call.deleteConfirmationDialog(`Are you sure you want to delete '${project.project_name}' project.`, () => {
                // removed the selected project (user choose to delete)
                const filteredProjects = this.filterProject(project)

                // we update the filteredProjects on server
                // and save it in local storage as well.
                call.asyncThen(call.updateUserDocKeyArrValue(
                    keys.userDocKeys.projects,
                    filteredProjects,
                    props.set_userProjects
                ))

                // if project to delete == user selected project (selected project display it's activity table on dashboard)
                // so, we need to set the selected project = null, because project is no longer available.
                if (props.userSelectedProject !== null && props.userSelectedProject.project_uid === project.project_uid) {
                    props.set_userSelectedProject(null)
                }
            })
        },

        /**
         * called when user click on delete activity button
         * @param activity (activity json object stored in global storage)
         */
        deleteActivity(activity) {
            // get activity project
            const project = this.getProjectById(activity.project_uid)
            // show confirmation before deleting activity:
            call.deleteConfirmationDialog(
                `Are you sure you want to delete '${activity.activity_name}' of '${project.project_name}' project.`,
                () => {
                    // filtered activities don't have given activity (removed given activity)
                    const filteredActivities = this.filterActivity(project, activity)
                    // update the filtered activities on server
                    // and when update is successful, it update the
                    // filtered activities in global storage.
                    call.asyncThen(call.updateUserDocKeyArrValue(
                        keys.userDocKeys.activities,
                        filteredActivities,
                        props.set_userActivities
                    ))
                })
        },

        /**
         * called when user click on any project.
         * @param project
         */
        setSelectedProject(project) {
            // setting this will trigger
            // showing given project activities table.
            props.set_userSelectedProject(project)
        },

        /**
         * called when user click on activity in UserDashboard.
         * @param activity
         */
        setSelectedActivity(activity) { // set this when use click on activity.
            // setting this will help us retrieve selected activity
            // data from other pages like (Blockly Workspace, MLTraining, etc)
            // These components may wants to know about which activity they are working with.
            props.set_userSelectedActivity(activity)
        },

        /**
         * Called when user click on any Activity in UserDashboard.
         *
         * @param userSelectedActivity (activity on which user clicked)
         * @param onFetch (once selected activity content is fetched, invoke this function)
         */
        fetchSelectedActivityContent(userSelectedActivity, onFetch = () => { }) {
            // get activityContent document ID. Using this ID, we can retrieve it's data
            // from server from 'CustomDocument' MongoDB table.
            const activityContentDocId = actOps.getActivityContentDocId(userSelectedActivity)
            call.asyncThen(async () => {
                await call.request(async () => {
                    // we first fetch defaultActivityContent, in case server return null. We use this default value to show.
                    const defaultActivityContent = actOps.getDefaultActivityContent(userSelectedActivity.activity_type)
                    // we make network request to
                    const customActivityContentDoc = await restApi.getCustomJsonDoc(activityContentDocId, defaultActivityContent)
                    const newContent = { ...defaultActivityContent, ...customActivityContentDoc }    // new local fields are added, & remote fields & data is also preserved.
                    newContent.activityMetadata = userSelectedActivity    // this will update old cached activityMetadata with new activity info.
                    props.set_userSelectedActivityContent(newContent)
                    onFetch(newContent) // do other work in current then
                })
            })
        },
    }
}