import React, { useEffect, useState } from 'react'
import useCustomActivityOps, {
    activityBlocklyContentFetcher,
    getActivityBlocklyXMLContent, globalActivityCallbacks
} from "../../../../hooks/useCustomActivityOps";
import { cloneElement } from "../../../../../lib/js/jsUtil";
import { useHistory } from "react-router-dom";
import useProjectAndActivityOps from "../../../../hooks/useProjectAndActivityOps";
import { appData } from "../../../../../app/util/appData";
import DialogCreateActivity from "../../../../../app/component/UserDashboard/components/DialogCreateActivity";

export default function useActivityCommonWorkspace(props, setupCustomActivityWorkspace) {
    // uses operation to redirect
    const history = useHistory()
    // uses operation related to ActivityContent
    const [activityDialogData, set_activityDialogData] = useState(null)
    const actOps = useCustomActivityOps(props)
    const projActOps = useProjectAndActivityOps(props)
    // handle frame loading state
    const [frameLoaded, set_frameLoaded] = useState(false)
    // get element by ID from html page defined in IFrame
    function getActivityIFrame() {
        return document.getElementById('wrkspc_frame');
    }

    // function to get xml content from directory
    function getLocalXmlDataFromDir(activity_type, dir) {
        const final_dir = "/assets/Tutorials/" + activity_type + "/" + dir + '.xml';
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", final_dir, false);
        xmlhttp.send();
        if (xmlhttp.status == 200) {
            return xmlhttp.responseText
        }
        else {
            return ""
        }
    }

    // dialog box for new activity create or perform save-as operation
    function openCreateActivityDialog(defaultActivityType) {

        const iframe = getActivityIFrame();
        const frm_window = iframe.contentWindow
        const frm_document = iframe.contentDocument
        const workspace = frm_window.blockpy.components.editor.blockly
        const xml = frm_window.Blockly.Xml

        set_activityDialogData({
            onCreateActivity(project_uid, activityName, activityType) {
                const xmlDom = xml.workspaceToDom(workspace)
                const stringXml = xml.domToText(xmlDom)
                projActOps.createNewActivity(project_uid, activityName, activityType, stringXml);
            },
            activityTypes: appData.activity.types,
            userProjects: props.userProjects,
            defaultActivityType: defaultActivityType != null ? defaultActivityType : appData.activity.types.BC[0],
            defaultProjectId: props.userSelectedProject != null ? props.userSelectedProject.project_uid : props.userProjects[0].project_uid,
        })
    }
    
    // get xml blockly-workspace content
    function getWorkspaceContent() {
        const iframe = getActivityIFrame();
        const frm_window = iframe.contentWindow
        const workspace = frm_window.blockpy.components.editor.blockly
        const xml = frm_window.Blockly.Xml
        const xmlDom = xml.workspaceToDom(workspace)
        const stringXml = xml.domToText(xmlDom)
        return stringXml
    }

    // set xml blockly-workspace content
    function loadWorkspaceContent(xmlContent) {
        // reset teachable link storage data
        sessionStorage.setItem('teachable_link', '')
        try {
            // to save teachable link from xmlcontent only if the activity_type is AC
            if(props.userSelectedActivity.activity_type === 'AC') {
                let parser = new DOMParser();  
                let xml_doc = parser.parseFromString(xmlContent, 'text/xml');
                let block_tags = xml_doc.getElementsByTagName("block");
                for(let i=0; i<block_tags.length; i++){
                    if(block_tags[i].getAttribute('type') === 'teachableDynamicLoadModelBlock'){
                        sessionStorage.setItem('teachable_link', block_tags[i].childNodes[1].firstChild.data)
                    }
                }
            }
            // 
            const iframe = getActivityIFrame();
            const frm_window = iframe.contentWindow
            const workspace = frm_window.blockpy.components.editor.blockly
            const xml = frm_window.Blockly.Xml
            workspace.clear()
            const xml_data = xml.textToDom(xmlContent)
            xml.domToWorkspace(xml_data, workspace)
        }
        catch (error) {
            const iframe = getActivityIFrame();
            const frm_window = iframe.contentWindow
            const workspace = frm_window.blockpy.components.editor.blockly
            workspace.clear()
            props.toastError("Certain Blocks has been updated. Please re-create the activity.")
        }
    }

    // this function setup 'activityBlocklyContentFetcher' so that,
    // activity content can be fetched from Ops class as well:
    function setupActivityContentFetcher(workspace, xml) {
        globalActivityCallbacks.getActivityBlocklyXMLContent = () => {
            const xmlDom = xml.workspaceToDom(workspace)
            const stringXml = xml.domToText(xmlDom)
            return stringXml
        }
    }
    
    // perform tasks when IFrame is loaded for the first time:
    function onLoadFrame() {
        const iframe = getActivityIFrame();
        const frm_window = iframe.contentWindow;
        const frm_document = iframe.contentDocument;
        const workspace = frm_window.blockpy.components.editor.blockly;
        const xml = frm_window.Blockly.Xml;
        setupActivityContentFetcher(workspace, xml);

        // setup common save button:
        cloneElement(frm_document.getElementById("btn-save")).addEventListener("click", function () {
            if (props.userSelectedActivity.activity_uid) {
                const xmlDom = xml.workspaceToDom(workspace)
                const stringXml = xml.domToText(xmlDom)
                // update activity blockly-content on server
                actOps.updateActivityContentDocViaClone(
                    clone => clone.workspaceContent = stringXml,  // with activity_Content clone, update workspaceContent
                    () => props.toastSuccess("Saved Successfully.")  // when update on server, toast successful message.
                )
            }
            else {
                openCreateActivityDialog(props.userSelectedActivity.activity_type);
            }
        });

        // setup common home button:
        cloneElement(frm_document.getElementById("btn-home")).addEventListener("click", function () {
            history.push("/dashboard")
        });

        // Setup common save as button
        cloneElement(frm_document.getElementById("btn-save-as")).addEventListener("click", function () {
            // props.inpDiag.receiveInput({
            //     onReceive(newActivityName) {
            //         projActOps.saveAsNewActivity(newActivityName)
            //     }, title: "Save Activity As", inputLabel: "Enter activity name", okBtnText: "Save"
            // })
            openCreateActivityDialog(props.userSelectedActivity.activity_type)
        });

        // setup common about button:
        cloneElement(frm_document.getElementById("btn-about")).addEventListener("click", function () {
            let actAbout = actOps.getUserSelectedActivityContent().about
            // ask for confirmation about editing the 'about'.
            props.confirmDiagOps.showConfirmationDialog("About Activity", actAbout, "Edit", () => {
                // on Edit: receive new user Input:
                props.inpDiag.receiveInput({
                    onReceive(aboutNote) {
                        // on receive new about:
                        // update the about
                        actOps.updateActivityContentDocViaClone(clone => {
                            clone.about = aboutNote
                        }, () => {
                            props.toastSuccess("Saved Successfully.")
                        })
                    }, value: actAbout, okBtnText: "Save", title: "Describe Activity", inputLabel: ""
                })
            })
        });

        // following function will be called for every activity to set
        // it's specific custom buttons. Like (train button AI, etc)
        setupCustomActivityWorkspace(iframe)
        // finally, update the blockly workspace content to saved activity content workspace:

        if (window.location.href.split('/')[3] === 'activity') {
            loadWorkspaceContent(actOps.getUserSelectedActivityContent().workspaceContent)
        }
        else if (window.location.href.split('/')[3] === 'examples') {
            // loadWorkspaceContent(props.selectedTutorial.workspaceContent)
            loadWorkspaceContent(getLocalXmlDataFromDir(props.selectedTutorial.activity_type, props.selectedTutorial.file_name))
        }
        else {
            loadWorkspaceContent(actOps.getUserSelectedActivityContent().workspaceContent)
        }
    }


    if (!frameLoaded) {
        // if frame is not loaded & view is being visible, show the fullscreen loading
        props.fullScreenLoading.show("Loading Workspace...")
    } else {
        // if frame is loaded, hide the fullscreen loading
        props.fullScreenLoading.forceClose()
    }

    // currently fetched activity workspace content
    const content = actOps.getUserSelectedActivityContent().workspaceContent
    useEffect(() => {
        if (frameLoaded)
            // if iframe is loaded, update the content
            loadWorkspaceContent(actOps.getUserSelectedActivityContent().workspaceContent)

    }, [frameLoaded, content])    // if frameLoaded dependency changes, or anything in
    // content changes, simply update the blockly workspace content.

    // setup buttons when frame loads for first time.
    useEffect(() => {
        if (frameLoaded) {
            // setup buttons, when frame loads for the first time:
            onLoadFrame()
        }
        // do this whenever frameLoaded state changes
        // or when activity content changes
    }, [frameLoaded, props.userSelectedActivityContent])


    // this will be called as callback from IFrame html:
    async function onFrameLoadFinish() {
        set_frameLoaded(true)
    }

    // loads the provided html page in IFrame, and return the create html:
    function getBlocklyWorkspaceFrame(activityHTMLPageName) {
        return <section id="frame_dynamic_content" style={{ "background-color": "#2F3F89" }}>
            <DialogCreateActivity data={[activityDialogData, set_activityDialogData]} title="Save As" />
            <iframe
                src={process.env.PUBLIC_URL + "/aiconnect-workspace/" + activityHTMLPageName + "?" + props.appVersion}
                id="wrkspc_frame"
                scrolling="no"
                onLoad={async e => {
                    // calls following to setup iFrame
                    await onFrameLoadFinish()
                }}
            />
        </section>
    }

    return {
        getActivityIFrame,
        getBlocklyWorkspaceFrame,
    }
}
