import React, {useState} from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";


export const processedImageDialog = {
    // this function can be called from outside to show image.
    // ShowProcessedImageDialog will override this function:
    showProcessedImage : (imageUrlPath)=>{}
}

export default function ShowProcessedImageDialog() {
    // maintain dialog visibility
    const [open,set_open] = useState(false)
    // set resulting image src
    const [imageSrc,set_imageSrc] = useState(null)

    // override function: sets the image in src:
    processedImageDialog.showProcessedImage = (imageUrlPath)=>{
        set_open(true)  // show dialog
        set_imageSrc(imageUrlPath)  // set image src
    }

    const handleClose = ()=> {
        set_open(false) // hide dialog
        set_imageSrc(null)  // remove image src
    }


    return (
        <>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Output Image</DialogTitle>
                    <DialogContent>
                        <div>
                            <div id="my_result" className="col">
                                {/* If imageSrc is set, show it: */}
                                {imageSrc===null || imageSrc===""?(""):(<img src={imageSrc}/>)}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

        </>
    )
}