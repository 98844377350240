import React, {useContext, useRef, useState} from 'react'
import LoadingOverlay from 'react-loading-overlay';

export const fullScreenLoading = {
    show(text){},
    close(){},
    forceClose(){},
}

export default function FullscreenLoading(props) {
    const [open, set_open] = useState(false);
    const [data, set_data] = useState({loading_text:"Loading..."});

    const countRef = useRef(0)

    fullScreenLoading.show = (text = "Loading...")=>{
        countRef.current = countRef.current+1
        // console.log("Open : countRef "+countRef.current)

        set_open(true)
        set_data({loading_text: text})
    }

    fullScreenLoading.close = () => {
        countRef.current = countRef.current-1       // this count may get weird
        // console.log("Close : countRef "+countRef.current)
        if(countRef.current<=0) {
            countRef.current = 0
            set_open(false)
        }
    }
    fullScreenLoading.forceClose = () => {
        countRef.current = 0
        set_open(false)
    }

    return (
        <>
            <LoadingOverlay
                style={{"height":"inherit"}}
                active={open}
                spinner
                text={data.loading_text}
            >
                {props.children}
            </LoadingOverlay>
        </>
    )
}