// import { useEffect } from 'react'
// import useHeaderOps from '../../../hooks/useHeaderOps'
// import React from 'react'

// export default function ActivitySC(props) {

//     // to do header operations
//     const headerOps = useHeaderOps(props)
//     useEffect(()=>{
//         // when page loads, set the sub-heading
//     headerOps.setUserContentSubHeader("STEMBOT Coding")
//     },[])

//     // loads the provided html file in IFrame
//     return (<>
//         <section id="frame_dynamic_content" style={{ "background-color": "#2f3f89" }}>
//             <iframe
//                 src={process.env.PUBLIC_URL + "/scratch3/index.html"}
//                 id="wrkspc_frame"
//                 // className='workspace-scratch'
//                 style={{height: "94vh",minHeight:'auto'}}
//             />
//         </section>
//     </>)
// }



import React, { useEffect, useState } from 'react';

const ActivitySC = ({ headerOps }) => {
    const [xmlContent, setXmlContent] = useState(null);

    // Fetch XML data from the assets folder
    useEffect(() => {
        console.log("iframe:", document.getElementById('wrkspc_frame')); // Check if iframe is present

        if (headerOps && headerOps.setUserContentSubHeader) {
            headerOps.setUserContentSubHeader("STEMBOT Coding");
        } else {
            console.error("headerOps is not properly defined or imported.");
        }

        // Fetch XML content for SC activity
        fetch('/assets/Tutorials/SC/VoiceControlRobot.xml')
            .then(response => response.text())
            .then(data => {
                setXmlContent(data);
                sendXmlToIframe(data);
            })
            .catch(error => console.error('Error loading XML file:', error));
    }, [headerOps]);

    // Send XML to the iframe once it is loaded
    const sendXmlToIframe = (xml) => {
        const iframe = document.getElementById('wrkspc_frame');
        console.log("Sending XML to iframe:", xml); // Log XML being sent

        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage({
                type: 'LOAD_WORKSPACE',
                xml: xml
            }, '*');
        } else {
            console.error("Iframe not found or not ready.");
        }
    };

    return (
        <div>
            <iframe
                src={process.env.PUBLIC_URL + "/scratch3/index.html"}
                id="wrkspc_frame"
                style={{ height: "94vh", width: "100%" }}
                title="Scratch Workspace"
            />
        </div>
    );
};

export default ActivitySC;
