import useAiConnectRestApi from "./useAiConnectRestApi";
import {appData} from "../util/appData";
import useCallWraps from "./useCallWraps";
import {cloneObj, delay} from "../../lib/js/jsUtil";
import useLoginOps from "./useLoginOps";

// global function that fatches the xml content of an activity:
export const globalActivityCallbacks = {
    // this function will be overridden in 'useActivityCommonWorkspace' class, to provide the
    // xml from iFrame:
    // the ops class use this function to retrieve the xml at the time of cloning, so pushed clone
    // will contain the last xml code.
    getActivityBlocklyXMLContent(){}
}

export default function useCustomActivityOps(props) {
    const call = useCallWraps(props)
    const restApi = useAiConnectRestApi(props)
    const loginOps = useLoginOps(props)
    // const keys = appData.keys
    // const defaultData = appData.defaultNetworkResponses

    const userSelectedActivity = props.userSelectedActivity


    return {
        getActivityContentDocId(activity) {
            return activity.activity_uid+"_activityContent"
        },

        getSelectedActivityType(){
            return userSelectedActivity.activity_type
        },

        withClone(ops = (clone)=>{}) {
            const content = cloneObj(props.userSelectedActivityContent)
            ops(content)
            props.set_userSelectedActivityContent(content)
        },

        getDefaultActivityContent(activity_type) {
            const contentTypes = appData.defaultNetworkResponses.defaultCustomActivityContent.types
            const content = contentTypes[activity_type]
            return content
        },

        getUserSelectedActivityContent() {
          return props.userSelectedActivityContent
        },
        // Networking Ops:

        saveActivityContent(selectedActivity, content, onUpdate) {
            const docId = this.getActivityContentDocId(selectedActivity)
            // perform network call
            call.asyncThen(async () => {
                // update docId(key) -> activity content (value) in custom documents:
                await call.updateCustomDocument(docId, content, props.set_userSelectedActivityContent)
                onUpdate() // on successful update, call onUpdate function
            })
        },

        updateActivityContentDocViaClone(withClone = (clone)=>{}, onUpdate = ()=>{}, newActivity="default") {
            const content = cloneObj(props.userSelectedActivityContent)
            const newContent = globalActivityCallbacks.getActivityBlocklyXMLContent()
            if(newContent!==undefined && newContent!==null)
                content.workspaceContent = newContent
            withClone(content)  // perform operations on clone
            // to save activity content if current selected acitity not exist
            if(newActivity !== 'default'){
                this.saveActivityContent(newActivity,content, onUpdate);
            }
            // get document Unique ID using userSelectedActivity
            // save existed activity
            else {
                this.saveActivityContent(props.userSelectedActivity,content, onUpdate);
            }
        },

        // return the image path with 'upload' dir as prefix & cache bursting id as suffix.
        getImageRemotePath(imageName) {
            const random = Math.floor((Math.random() * 1000000) + 1)
            return restApi.getUploadsDir()+'/'+imageName+"?"+random;
        },

        // upload the base64 image on server:
        // call onUpload with uploaded image fileName.
        uploadBase64Image(base64ImageContent,onUpload = (remoteFileUrl)=>{}){
            call.asyncThen(async ()=>{
                // directory where the image will be saved
                const directory = "images/temp"
                // extension of image file (png, jpg, etc)
                const extText = base64ImageContent.split(';')[0].split('/')[1]
                const ext = extText==="png"? "png" : "jpg"
                // generate fileName
                const fileName = loginOps.userEmailId()+"_"+props.userSelectedActivity.creation_date+"_aifile."+ext
                // upload the file with the generated name
                const response = await restApi.uploadBase64Image(directory,fileName,base64ImageContent)
                // response will contain the uploaded file path.
                // update the activity content (set uploaded image file path)
                this.updateActivityContentDocViaClone(clone=>{
                    clone.captureImageUrlPath = response.file
                })
                // once uploaded, call onUpload function with uploaded remote file path:
                onUpload(this.getImageRemotePath(response.file))
            })
        },

        // update activity content document:
        updateActivityContentDoc(contentDoc) {
            // activity content is the custom document:
            // first we find the custom document id
            const docId = this.getActivityContentDocId(props.userSelectedActivity)
            // now we make a network call to update the custom document, and once updated,
            // set the updated content in global content in (AppInit).
            call.asyncThen(call.updateCustomDocument(
                docId,contentDoc,   // update document
                props.set_userSelectedActivityContent // update global content (AppInit)
            ))
        },

        // run AIActivity code on server, and return processed image path:
        runAiCodeOnImage(modelDir,code, imagePath, processedImage = (urlPath)=>{}) {
            call.asyncThen(call.request(async ()=>{
                // with help of code, imagePath, we get processedImagePath:
                const processedImagePath = await restApi.runAiCode(modelDir,code,imagePath)
                if('file' in processedImagePath) {
                    // if file is present in response: get proper image path with domain name attached
                    const filePath = this.getImageRemotePath(processedImagePath.file)
                    // give back the processed image:
                    processedImage(filePath)
                } else {
                    // response don't have file, it has error field.
                    props.toastError(processedImagePath.error)
                }
            }))
        },

        // get the file details:
        uploadAITrainModelZipFile(file,directory) {
            call.asyncThen(call.request(async ()=>{
                // make network request to upload the selected file:
                await restApi.uploadAITrainFile(file,directory)
                // on success:
                props.toastSuccess("Model uploaded successfully.")
                // after 1 sec, show the last message:
                await delay(1000)
                props.toastSuccess("Now, capture image, & run Image Train.")
            }))
        },

        // retrieve sprite images from server, and return them
        getUserSprites(userSprites) {
            call.asyncThen(call.request(async ()=>{
                try {
                    // make network request to retrieve user sprite images:
                    const userSpritesJson = await restApi.getUserSprites()
                    // once sprite images are fetched, give back the sprite images
                    // to be set in UI
                    userSprites(userSpritesJson)
                }catch (e) {
                    props.toastError("Fail to retrieve sprite images.")
                }
            }))
        },

        getModelFileLocation(props) {
            return 'ai_train_models/'+props.loginDetails.userEmailId+"_"+props.userSelectedActivity.creation_date
        },




    }
}