import React, {useEffect} from 'react'
import "../css/UserActivity.css"
import {useHistory} from "react-router-dom";
import useCustomActivityOps from "../../hooks/useCustomActivityOps";
import {appData} from "../../util/appData";
import {KnownError} from "../../../lib/js/Exceptions";
import ActivityBP from "./activityTypes/ActivityBP";
import ActivityAI from "./activityTypes/ActivityAI";
import ActivityML from "./activityTypes/ActivityML";
import ActivityGD from "./activityTypes/ActivityGD";
import ActivityBC from "./activityTypes/ActivityBC";
import ActivityAC from "./activityTypes/ActivityAC";
import ActivityTC from "./activityTypes/ActivityTC";
import ActivitySC from './activityTypes/ActivitySC';
import useHeaderOps from "../../hooks/useHeaderOps";


export default function ExampleActivity(props) {
    // for navigation between pages:
    const history = useHistory()
    // to do custom activity operations
    const actOps = useCustomActivityOps(props)
    // console.log("props", props, "\nactOps", actOps);
    // to do header operations
    const headerOps = useHeaderOps(props)

    // on page load for the first time:
    useEffect(()=>{
           
        // setup header sub-heading:
        switch(props.selectedTutorial.activity_type) {
            case appData.activity.typesOld.AI[0] :
                headerOps.setUserContentSubHeader("AI Real World")
                break;
            case appData.activity.typesOld.ML[0] :
                headerOps.setUserContentSubHeader("Machine Learning")
                break;
            case appData.activity.typesOld.GD[0] :
                headerOps.setUserContentSubHeader("Graphic Design")
                break;
            case appData.activity.types.BC[0] :
                headerOps.setUserContentSubHeader("Basic Coding")
                break;
            case appData.activity.types.AC[0] :
                headerOps.setUserContentSubHeader("AI Coding")
                break;
            case appData.activity.types.TC[0] :
                headerOps.setUserContentSubHeader("Tinker Orbit Connect")
                break;
            case appData.activity.types.SC[0]:
                headerOps.setUserContentSubHeader("STEMBOT Coding");
                break;    
            default: //case appData.activity.types.BP[0] :
                headerOps.setUserContentSubHeader("Basic Coding")
        }


        // add custom 'activity' css class to body:
        document.body.classList.add("activity")
        return ()=>{
            // tear down
            document.body.classList.remove("activity")
        }
    },[])
    // select which activity to show based on used selected activity type:
    function getActivityWorkspace() {
        switch(props.selectedTutorial.activity_type) {
            case appData.activity.typesOld.AI[0] :
                return <ActivityAI {...props} />
            case appData.activity.typesOld.ML[0] :
                return <ActivityML {...props} />
            case appData.activity.typesOld.GD[0] :
                return <ActivityGD {...props} />
            case appData.activity.typesOld.BP[0] :
                return <ActivityBP {...props} />
            case appData.activity.types.BC[0] :
                return <ActivityBC {...props} />
            case appData.activity.types.AC[0] :
                return <ActivityAC {...props} />
            case appData.activity.types.SC[0]: // Add SC (Stembot Coding) activity
                return <ActivitySC {...props} />; 
            case appData.activity.types.TC[0] :
                return <ActivityTC {...props} />
            default: throw KnownError("ActivityError")
        }
    }

    // returns the selected activity tag
    return getActivityWorkspace()

}








// import React, { useEffect } from 'react';
// import "../css/UserActivity.css";
// import { useHistory } from "react-router-dom";
// import useCustomActivityOps from "../../hooks/useCustomActivityOps";
// import { appData } from "../../util/appData";
// import { KnownError } from "../../../lib/js/Exceptions";
// import ActivityBP from "./activityTypes/ActivityBP";
// import ActivityAI from "./activityTypes/ActivityAI";
// import ActivityML from "./activityTypes/ActivityML";
// import ActivityGD from "./activityTypes/ActivityGD";
// import ActivityBC from "./activityTypes/ActivityBC";
// import ActivityAC from "./activityTypes/ActivityAC";
// import ActivityTC from "./activityTypes/ActivityTC";
// import ActivitySC from './activityTypes/ActivitySC';
// import useHeaderOps from "../../hooks/useHeaderOps";

// export default function ExampleActivity(props) {
//     // for navigation between pages:
//     const history = useHistory();
//     // to do custom activity operations
//     const actOps = useCustomActivityOps(props);
//     // to do header operations
//     const headerOps = useHeaderOps(props);

//     // Debugging log for props and activity type
//     useEffect(() => {
       
//         const activityType = props.selectedTutorial.activity_type;
//         console.log("Activity Type (Processed):", activityType);
        
//         // setup header sub-heading:
//         switch (props.selectedTutorial.activity_type) {
//             case appData.activity.typesOld.AI[0]:
//                 console.log("Setting sub-header to 'AI Real World'");
//                 headerOps.setUserContentSubHeader("AI Real World");
//                 break;
//             case appData.activity.typesOld.ML[0]:
//                 console.log("Setting sub-header to 'Machine Learning'");
//                 headerOps.setUserContentSubHeader("Machine Learning");
//                 break;
//             case appData.activity.typesOld.GD[0]:
//                 console.log("Setting sub-header to 'Graphic Design'");
//                 headerOps.setUserContentSubHeader("Graphic Design");
//                 break;
//             case appData.activity.types.BC[0]:
//                 console.log("Setting sub-header to 'Basic Coding'");
//                 headerOps.setUserContentSubHeader("Basic Coding");
//                 break;
//             case appData.activity.types.AC[0]:
//                 console.log("Setting sub-header to 'AI Coding'");
//                 headerOps.setUserContentSubHeader("AI Coding");
//                 break;
//             case appData.activity.types.TC[0]:
//                 console.log("Setting sub-header to 'Tinker Orbit Connect'");
//                 headerOps.setUserContentSubHeader("Tinker Orbit Connect");
//                 break;
//             case appData.activity.types.SC[0]:
//                 console.log("Setting sub-header to 'STEMBOT Coding'");
//                 headerOps.setUserContentSubHeader("STEMBOT Coding");
//                 break;
//             default:
//                 console.log("Setting sub-header to 'Basic Coding'");
//                 headerOps.setUserContentSubHeader("Basic Coding");
//         }

//         // add custom 'activity' CSS class to body
//         document.body.classList.add("activity");

//         // Cleanup
//         return () => {
//             document.body.classList.remove("activity");
//         };
//     }, [props.selectedTutorial.activity_type]);

//     // Debugging log before rendering activity
//     function getActivityWorkspace() {
//         console.log(appData.activity.types); // This should print the entire `types` object


//         switch (props.selectedTutorial.activity_type) {
//             case appData.activity.typesOld.AI[0]:
//                 console.log("Rendering ActivityAI");
//                 return <ActivityAI {...props} />;
//             case appData.activity.typesOld.ML[0]:
//                 console.log("Rendering ActivityML");
//                 return <ActivityML {...props} />;
//             case appData.activity.typesOld.GD[0]:
//                 console.log("Rendering ActivityGD");
//                 return <ActivityGD {...props} />;
//             case appData.activity.typesOld.BP[0]:
//                 console.log("Rendering ActivityBP");
//                 return <ActivityBP {...props} />;
//             case appData.activity.types.BC[0]:
//                 console.log("Rendering ActivityBC");
//                 return <ActivityBC {...props} />;
//             case appData.activity.types.AC[0]:
//                 console.log("Rendering ActivityAC");
//                 return <ActivityAC {...props} />;
//             case appData.activity.types.SC[0]:
//                 console.log("Rendering ActivitySC (STEMBOT Coding)");
//                 return <ActivitySC {...props} />;
//             case appData.activity.types.TC[0]:
//                 console.log("Rendering ActivityTC");
//                 return <ActivityTC {...props} />;
//             default:
//                 console.error("Error: Unknown activity type:", props.selectedTutorial.activity_type);
//                 throw KnownError("ActivityError");
//         }
//     }

//     // returns the selected activity tag
//     return getActivityWorkspace();
// }

