import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useHeaderOps from "../../hooks/useHeaderOps";
import '../css/FreeActivity.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const pdfs = [
    {path: "/assets/Tutorials/BC/DrawSquare.pdf", title: "Draw a Square"},
    {path: "/assets/Tutorials/BC/DrawACircle.pdf", title: "Draw a Circle"},
    {path: "/assets/Tutorials/AC/FaceCount.pdf", title: "Face Count"},
    {path: "/assets/Tutorials/AC/FingerCount.pdf", title: "Finger Count"},
    {path: "/assets/Tutorials/SC/VoiceControlRobot.pdf", title: "Voice Control Robot"},
    {path: "/assets/Tutorials/SC/SmartDirectionRobot.pdf", title: "Smart Direction Robot"},
  ];

const videoUrls = [
    'https://player.vimeo.com/video/831799450?h=b4844e90f5&title=0&byline=0&portrait=0%27', // Draw a Square
    'https://player.vimeo.com/video/831797669?h=bdce26f433&title=0&byline=0&portrait=0%27', // Draw a Circle
    'https://player.vimeo.com/video/853267590?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', //Face Count
    'https://player.vimeo.com/video/853269385?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22', //Finger Count
    'https://player.vimeo.com/video/832854426?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22', //Voice Control Robot
    'https://player.vimeo.com/video/832856844?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22', //Gesture Control Robot
  ];

export default function FreeActivity(props) {
    const headerOps = useHeaderOps(props);
    const [frameLoaded, setFrameLoaded] = useState(false);
    const history = useHistory();

    // Function to handle iframe load
    async function onFrameLoadFinish() {
        setFrameLoaded(true);
    }

    // UseEffect to clear header operations
    useEffect(() => {
        return () => {
            headerOps.clearHeader();
        };
    }, []);

    // UseEffect to add and remove class from body
    useEffect(() => {
        document.body.classList.add("activity");
        return () => {
            document.body.classList.remove("activity");
        };
    }, []);

    // Function to open iframe in new tab
    const openIFrame = () => {
        window.open(process.env.PUBLIC_URL + "/aiconnect-workspace/try_without_login.html", '_blank');
    };

    // Function to open Vimeo video in popup iframe
    const openVideoInPopup = (videoUrl) => {
        const popupWindow = window.open('', '_blank', 'width=800,height=600');
        popupWindow.document.write(`
            <html>
            <body style="margin: 0; overflow: hidden;">
                <iframe src="${videoUrl}" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </body>
            </html>
        `);
    };

    // Function to open PDF in popup iframe
    const openPdfInPopup = (pdfUrl) => {
        const popupWindow = window.open('', '_blank', 'width=800,height=600');
        popupWindow.document.write(`
            <html>
            <body style="margin: 0; overflow: hidden;">
                <embed width="100%" height="100%" src="${pdfUrl}" type="application/pdf">
            </body>
            </html>
        `);
    };




    // Data for activity types
    const appData = {
        activity: {
            types: {
                BC: ['BasicCoding'],
                AC: ['AICoding'],
                TC: ['TinkerCoding'],
                SL: ['STEMLight'],
                DEMO: ['Demo']
            }
        }
    };

    // Function to handle quick activity creation
    const quickActivityCreate = (type) => {
        console.log(`Creating quick activity of type ${type}`);
    };

    // Function to handle click on create button
    const onClickCreate = (event, type) => {
        console.log(`Creating activity on click for type ${type}`);
    };

    // More option content for different types of coding
    const moreOptionAllContent = {
        BASIC_CODING: 'Content for Basic Coding',
        AI_CODING: 'Content for AI Coding',
        TINKER_CODING: 'Content for Tinker Orbit Coding',
        STEM_CODING: 'Content for STEMBOT Coding',
        DEMO: 'Content for Demo'
    };

    // Function to toggle more options
    const setMoreOptionToggle = () => {
        console.log('Setting more option toggle');
    };

    // Function to set more option content
    const setMoreOptionContent = (content) => {
        console.log(`Setting more option content: ${content}`);
    };

    // Function to open new tab and focus on URL
    const openNewTabAndFocus = (url) => {
        console.log(`Opening new tab and focusing on ${url}`);
    };

    // Render the component
    return (
        <section id="frame_dynamic_content">
            <div id="activity_cards">
                <div className="row-centered">
                    <div className="column-4">
                        <div className="custom-card bg-info-new1 text-white" style={{ cursor: 'pointer' }} onClick={event => { quickActivityCreate(appData.activity.types.BC[0]) }}>
                            <div className="card-body">
                                <h4 className="card-title">Basic<br />Coding</h4>
                                <p className="card-text small-description">Let’s learn the basics to improve your Intelligence.</p>
                                <div className="buttons-container">
                                    <button onClick={event => { onClickCreate(event, appData.activity.types.BC[0]) }} className="custom-button">Create &gt;&gt;</button>
                                    <button onClick={event => { event.stopPropagation(); setMoreOptionToggle(true); setMoreOptionContent(moreOptionAllContent['BASIC_CODING']) }} className="custom-button">More &gt;&gt;</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column-4">
                        <div className="custom-card bg-info-new2 text-white" style={{ cursor: 'pointer' }} onClick={event => { quickActivityCreate(appData.activity.types.AC[0]) }}>
                            <div className="card-body">
                                <h4 className="card-title">AI<br />Coding</h4>
                                <p className="card-text small-description">Unlock the potential of AI through coding.</p>
                                <div className="buttons-container">
                                    <button onClick={event => { onClickCreate(event, appData.activity.types.AC[0]) }} className="custom-button">Create &gt;&gt;</button>
                                    <button onClick={event => { event.stopPropagation(); setMoreOptionToggle(true); setMoreOptionContent(moreOptionAllContent['AI_CODING']) }} className="custom-button">More &gt;&gt;</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column-4">
                        <div className="custom-card bg-info-new3 text-white" style={{ cursor: 'pointer' }} onClick={event => { quickActivityCreate(appData.activity.types.TC[0]) }}>
                            <div className="card-body">
                                <h4 className="card-title">Tinker Orbit<br />Coding</h4>
                                <p className="card-text small-description">Explore the possibilities of technology with Tinker Orbit.</p>
                                <div className="buttons-container">
                                    <button onClick={event => { onClickCreate(event, appData.activity.types.TC[0]) }} className="custom-button">Create &gt;&gt;</button>
                                    <button onClick={event => { event.stopPropagation(); setMoreOptionToggle(true); setMoreOptionContent(moreOptionAllContent['TINKER_CODING']) }} className="custom-button">More &gt;&gt;</button>
                                </div>
                            </div>
                        </div>
                    </div>

    <div className="column-4">
     <div className="custom-card bg-info-new4 text-white" style={{ cursor: 'pointer' }}>
        <div className="card-body">
            <h4 className="card-title">STEMBOT<br />Coding</h4>
           <p className="card-text small-description">Unleashing Creativity through STEMBOT Coding.</p>
         <div className="buttons-container">
               <button 
                  onClick={event => event.stopPropagation()} 
                  className="custom-button"
                 >
                     Create &gt;&gt;
                </button>
                <button 
                    onClick={event => event.stopPropagation()} 
                    className="custom-button"
                >
                    More &gt;&gt;
                </button>
            </div>   
        </div>
    </div>
</div>


                    <div className="column-4">
                        <div className="custom-card bg-info-new5 text-white" style={{ cursor: 'pointer' }}>
                            <div className="card-body">
                                <h4 className="card-title">STEM<br />LIGHT</h4>
                                <p className="card-text small-description">Explore STEM concepts in a lighter and Easy way.</p>
                                <div className="buttons-container">
                                    <button onClick={() => openNewTabAndFocus("https://staging.aiconnect.cc/lightbot")} className="custom-button">Play &gt;&gt;</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column-4">
                        <div className="custom-card bg-info-new6 text-white">
                            <div className="card-body">
                                <h4 className="card-title">Demo<br />Coding</h4>
                                <p className="card-text small-description">Explore the world of block coding</p>
                                <div className="buttons-container">
                                    <button onClick={openIFrame} className="custom-button">Click Me &gt;&gt;</button>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="tutorials_section">
      <h2 className="tutorials-heading">Tutorials</h2>

      {/* Basic Coding */}
      <div className="tutorials-topic">
        <h3>Basic Coding</h3>
        {pdfs.slice(0, 2).map((pdf, index) => (
          <div className="tutorials-item" key={index}>
            <span>{pdf.title}</span>
            <div className="icon-container">
              <div className="pdf-icon" onClick={() => openPdfInPopup(pdf.path)}>
                <i className="fas fa-file-pdf"></i> PDF
              </div>
              <div className="video-icon" onClick={() => openVideoInPopup(videoUrls[index])}>
                <i className="fas fa-video"></i> Video
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* AI Coding */}
      <div className="tutorials-topic">
        <h3>AI Coding</h3>
        {pdfs.slice(2, 4).map((pdf, index) => (
          <div className="tutorials-item" key={index}>
            <span>{pdf.title}</span>
            <div className="icon-container">
              <div className="pdf-icon" onClick={() => openPdfInPopup(pdf.path)}>
                <i className="fas fa-file-pdf"></i> PDF
              </div>
              <div className="video-icon" onClick={() => openVideoInPopup(videoUrls[index + 2])}>
                <i className="fas fa-video"></i> Video
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* STEMBOT Coding */}
      <div className="tutorials-topic">
        <h3>Stembot Coding</h3>
        {pdfs.slice(4).map((pdf, index) => (
          <div className="tutorials-item" key={index}>
            <span>{pdf.title}</span>
            <div className="icon-container">
              <div className="pdf-icon" onClick={() => openPdfInPopup(pdf.path)}>
                <i className="fas fa-file-pdf"></i> PDF
              </div>
              <div className="video-icon" onClick={() => openVideoInPopup(videoUrls[index + 4])}>
                <i className="fas fa-video"></i> Video
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
        </section>
    );
}



