import './App.css';
import FullscreenLoading, {fullScreenLoading} from "./lib/components/popups/FullscreenLoading";
import Alerts, {toastOps} from "./lib/components/popups/SnackbarAlerts";
import InputDialog, {ops as inpDiag} from "./lib/components/popups/InputDialog";
import AppInit from "./app/component/AppInit";
import ConfirmationDialog, {confirmDiagOps} from "./lib/components/popups/ConfirmationDialog";
// import CacheBuster from 'react-cache-buster';
//import { version } from '../package.json';
import {usePersistedJsonState} from "./lib/hooks/StorageHooks";
// import {emptyCache} from "./lib/js/jsUtil";
import packageInfo from "../package.json";
import React from 'react';


function App() {
    function deleteConfirmationDialog(description,ops) {
        confirmDiagOps.show({onConfirm(){
                ops()
            }, title : "Confirmation",description:description,okBtnText:"Delete"})
    }

    const [appVersion, set_appVersion] = usePersistedJsonState('local_app_version', packageInfo.version)


    const appProps = {
        appVersion, set_appVersion,
        ...toastOps,
        confirmDiagOps,
        deleteConfirmationDialog,
        inpDiag,
        fullScreenLoading
    }

    console.log("Version : "+appVersion)

    return (
    <>
        {/* Show all the toast messages like "Logged Out", "Success",
         "Warning", etc. */}
        <Alerts />

        {/* Show confirmation dialogs like, "Do you want to delete"? (Yes/No), etc. */}
        <ConfirmationDialog />

        {/* Take single value input form User. Like "About Activity",
         "Project Name", "Change value", etc. */}
        <InputDialog {...appProps} />

        {/* Shown the fullscreen loading, with circular progress. */}
        <FullscreenLoading>

            {/* Initialize app Data */}
            <AppInit {...appProps} />

        </FullscreenLoading>
    </>
  );
}

export default App;



// Signup issue resolved Ayushman oct19 2024

// import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import FullscreenLoading, { fullScreenLoading } from "./lib/components/popups/FullscreenLoading";
// import Alerts, { toastOps } from "./lib/components/popups/SnackbarAlerts";
// import InputDialog, { ops as inpDiag } from "./lib/components/popups/InputDialog";
// import AppInit from "./app/component/AppInit";
// import ConfirmationDialog, { confirmDiagOps } from "./lib/components/popups/ConfirmationDialog";
// import SignupPage from './app/component/SignupPage'; // Ensure this path is correct
// import packageInfo from "../package.json";
// import { usePersistedJsonState } from "./lib/hooks/StorageHooks";

// function App() {
//     const [appVersion, set_appVersion] = usePersistedJsonState('local_app_version', packageInfo.version);

//     const appProps = {
//         appVersion, set_appVersion,
//         ...toastOps,
//         confirmDiagOps,
//         inpDiag,
//         fullScreenLoading
//     };

//     return (
//         <Router>
//             <Alerts />
//             <ConfirmationDialog />
//             <InputDialog {...appProps} />
//             <FullscreenLoading>
//                 <AppInit {...appProps} />
//                 <Switch>
//                     <Route path="/signup" component={SignupPage} />
//                 </Switch>
//             </FullscreenLoading>
//         </Router>
//     );
// }

// export default App;



// This code is not used just kept as option ignore 

// import './App.css';
// import FullscreenLoading, { fullScreenLoading } from "./lib/components/popups/FullscreenLoading";
// import Alerts, { toastOps } from "./lib/components/popups/SnackbarAlerts";
// import InputDialog, { ops as inpDiag } from "./lib/components/popups/InputDialog";
// import AppInit from "./app/component/AppInit";
// import ConfirmationDialog, { confirmDiagOps } from "./lib/components/popups/ConfirmationDialog";
// import packageInfo from "../package.json";
// import React from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { usePersistedJsonState } from "./lib/hooks/StorageHooks";  // Import the hook

// function App() {
//     function deleteConfirmationDialog(description, ops) {
//         confirmDiagOps.show({
//             onConfirm() {
//                 ops();
//             },
//             title: "Confirmation",
//             description: description,
//             okBtnText: "Delete"
//         });
//     }

//     const [appVersion, set_appVersion] = usePersistedJsonState('local_app_version', packageInfo.version);

//     const appProps = {
//         appVersion, set_appVersion,
//         ...toastOps,
//         confirmDiagOps,
//         deleteConfirmationDialog,
//         inpDiag,
//         fullScreenLoading
//     };

//     console.log("Version: " + appVersion);

//     return (
//         <Router>
//             {/* Show all the toast messages like "Logged Out", "Success", "Warning", etc. */}
//             <Alerts />

//             {/* Show confirmation dialogs like, "Do you want to delete"? (Yes/No), etc. */}
//             <ConfirmationDialog />

//             {/* Take single value input form User. Like "About Activity", "Project Name", "Change value", etc. */}
//             <InputDialog {...appProps} />

//             {/* Shown the fullscreen loading, with circular progress. */}
//             <FullscreenLoading>
//                 {/* Initialize app Data */}
//                 <AppInit {...appProps} />
//             </FullscreenLoading>
//         </Router>
//     );
// }

// export default App;

