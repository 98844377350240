import React, {useEffect} from "react";

// use state
export function usePersistedJsonState(key, defaultValue) {  // defaultValue = null or {}
    const [state, setState] = React.useState(
        JSON.parse(sessionStorage.getItem(key)) || defaultValue
    );


    useEffect(() => {
        if(state===null) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, JSON.stringify(state));
        }
    }, [state]);
    return [state, setState];
}
export function usePersistedValueState(key, defaultValue) {
    const [state, setState] = React.useState(
        // w
        () => sessionStorage.getItem(key) || defaultValue
    );
    useEffect(() => {
        if(state===null) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, state);
        }
    }, [state]);
    return [state, setState];
}

// include heavy storage ops:
export function jsonSessionStore(key,defaultValue=null) {

    function get() {
        return JSON.parse(sessionStorage.getItem(key)) || defaultValue
    }

    function set(value) {
        if(value===null) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    return [
        get,
        set
    ]
}
export function valueSessionStore(key,defaultValue=null) {

    function get() {
        return sessionStorage.getItem(key) || defaultValue
    }

    function set(value) {
        if(value===null) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, value);
        }
    }

    return [
        get,
        set
    ]
}
