import React from 'react'

export default function  useHeaderOps(props) {

    return {

        getHeader() {
            return {
                subHeading : null,
                isFreeActivity : false
            }
        },
        setHeader(withClone) {
            const header = {...this.getHeader()};   // create clone object from existing
            withClone(header)   // work with the clone object
            props.set_header(header)    // set header data object in global storage
        },
        setFreeActivityHeader() {
            this.setHeader(clone=>{ // update using clone object
                clone.subHeading = "Free Activity"
                clone.isFreeActivity = true
            })
        },
        setUserContentSubHeader(subHeader) {
            this.setHeader(clone=>{ // update using clone object
                clone.subHeading = subHeader
                clone.isFreeActivity = false
            })
        },
        clearHeader() {
            this.setHeader(clone=>{ // update using clone object
                clone.subHeading = null
                clone.isFreeActivity = false
            })
        },
    }

}