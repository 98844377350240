import moment from "moment/moment";

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function cloneObj(obj) { return {...obj} }

export function readJsonValue(defaultValue,op) {
    try {
        return op()
    } catch (e) {
        return defaultValue
    }
}

export function dateToReadableTime(time) {
    return moment(time,'x').format("DD MMM YYYY, hh:mm A")
}

// unbinds old event listeners, by making clone
export function cloneElement(element) {
    if(element===null || element===undefined) {
        console.error("Null element at cloning.")
        return document.createElement("button") // dummy element
    }
    const clone = element.cloneNode(true)
    element.parentNode.replaceChild(clone,element)
    return clone
}

export function remove(haystackArr,needle) {
    return haystackArr.filter((_value)=> _value!==needle);
}

export function isWordWithChars(str) {
    return str.trim().match(/^[A-Za-z]+$/i)
}
export function isAlphaNumericWord(str) {
    return str.trim().match(/^[A-Za-z0-9]+$/i)
}

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function inputAlphaNumericSpaceCharsOnly(str) {
    return str.match(/^[A-Za-z 0-9]+$/i)
}

export function hasKeys(jsObj) {
    return Object.keys(jsObj).length>0
}

export function objKeysAsArr(object) {
    return Object.keys(object)
}

export function itrOnObjKeys(object,onItr=(key,value,idx)=>{}) {
    const keys = Object.keys(object)
    const content = []
    keys.map((key,index)=>{ content.push(onItr(key,object[key],index)); return 1; })
    return content
}

export function arraysExactMatch(arr1, arr2) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    // Otherwise, return true
    return true;
}


export function emptyCache(){
    if('caches' in window){
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
    }
}