import {
    lib_getJsonResponse,
    lib_getOkResponse,
    lib_postForm,
    lib_postJson,
    lib_postZip
} from "../../lib/js/RestApiUtil";
import {ServerError} from "../../lib/js/Exceptions";
// return basic functions get more details about the domain:
function getDomainInfo(){
    // TODO: set below two based on url:
    // if domain start with http://localhost means it's a local server
    // let isLocal = window.location.href.startsWith("http://localhost")
    // get restApiRoot based on domain name:
    //let restApiRoot = isLocal?`http://localhost:35878`: (window.location.href.startsWith("https://dev.aiconnect.cc")?`https://dev.aiconnect.cc:35878`:`https://aiconnect.cc:35878`);
    // let restApiRoot = isLocal?`http://localhost:35878`: (window.location.href.startsWith("https://aiconnect.clomosoft.in")?`https://aiconnect.clomosoft.in:35878`:`https://aiconnect.cc:35878`);
    // let restApiRoot = `https://aiconnect.clomosoft.in:35878`


    let webAddress = window.location.href
    // if(webAddress.startsWith("https://aiconnect.cc") || webAddress.startsWith("https://www.aiconnect.cc")){
    //     webAddress = "https://www.aiconnect.cc"
    // } else if (webAddress.startsWith("https://aiconnect.clomosoft.in")|| webAddress.startsWith("https://aiconnect.clomosoft.in")){
    //     webAddress = "https://aiconnect.clomosoft.in"
    // }
    // else
    if(webAddress.startsWith("http://localhost")) {
        webAddress = "http://localhost"
    } else if(window.location.href.indexOf('/',10)!==-1) {
        webAddress = window.location.href.substr(0, window.location.href.indexOf('/',10) )
    }

    let restApiRoot = webAddress+`:35878`

    // webapp root
    let webAppRoot = webAddress
    // get restApi address
    const restApiAddress = `${restApiRoot}/restapi`

    // get upload dir based on configuration
    const uploadsDir = `${process.env.PUBLIC_URL}/uploads`
    // returns the uploadsDir
    function getUploadsDir() {
        return uploadsDir
    }
    // return all the functions as a JSON object:
    return {
        restApiRoot,
        webAppRoot,
        restApiAddress,
        uploadsDir,
        getUploadsDir,
    }
}

export default function useAiConnectRestApi(props) {

    // retrieve user loginDetails from global storage:
    const loginDetails = props.loginDetails

    // get all functions return by getDomainInfo:
    const {
        restApiRoot,
        webAppRoot,
        restApiAddress,
        uploadsDir,
        getUploadsDir
    } = getDomainInfo()
    // prepares the request header using the user loginDetails:
    const getUserRequestHeaderToken = () => {
        const token = loginDetails.token
        const userEmailId = loginDetails.userEmailId
        return {    // return header details as jsonObject
            'Authorization': 'Bearer '+token,
            'userEmailId' : userEmailId,
        }
    }
    // get value corresponding to key from UserDocument from server:
    async function getUserDocKeyValue(key,defaultValueOnNotFound){
        try {
            return await lib_getJsonResponse(
                `${restApiAddress}/getUserDocKeyValue`, // RestEnd point from where to get the value of the key
                // prepare post request form: key -> content key(projects, activities, etc)
                lib_postForm({key},getUserRequestHeaderToken()
                ))
        }catch (e) {
            // if error, check it and throw error accordingly:
            if(e.type===ServerError && e.message.startsWith("NotFound")) {
                return defaultValueOnNotFound   // return default value if error is 'not found'.
            } else {
                throw e
            }
        }
    }
    // save key -> value(jsonObj) on server
    async function updateUserDocKeyValue(key,value) {
        const header = {...getUserRequestHeaderToken(), key}    // header values
        await lib_getOkResponse(    // get ok response when update successfully happen on server.
            `${restApiAddress}/upsertUserDocKeyValue`,  // end point
            lib_postJson(header,value)) // post jsonObject request prepare
    }
    // custom doc:
    async function getCustomJsonDoc(documentId,defaultValueOnNotFound = {}){
        try {
            // get Json in response
            return await lib_getJsonResponse(
                `${restApiAddress}/getCustomDocument`,  // api endpoint
                lib_postForm({'document_id' : documentId},getUserRequestHeaderToken())
                // custom document unique id & request header is provided
                // On success, returns the custom document json content
            )
        }catch (e) {
            // if error, check it and throw error accordingly:
            if(e.type===ServerError && e.message.startsWith("NotFound")) {
                return defaultValueOnNotFound   // return default value if error is 'not found'.
            } else {
                throw e
            }
        }
    }
    // custom document Unique ID & custom document jsonObject are provided
    async function updateCustomJsonDoc(documentId,jsonDoc) {
        const header = {...getUserRequestHeaderToken(), 'document_id' : documentId}
        // header contains the custom document unique ID
        await lib_getOkResponse(
            `${restApiAddress}/upsertCustomDocument`,   // api endpoint
            lib_postJson(header,jsonDoc))   // create post request with jsonObject & header
    }
    // user emailID and password are provided
    async function loginUser(emailId,password){
        const postParams = {
            userEmailId : emailId,    // replace with actual
            userMD5Pass : password  // convertt password to MD5 password
        }
        const loginResponseObj = await lib_getJsonResponse( // get jsonObject in response
            `${restApiAddress}/login`,  // api endpoint
            lib_postForm(postParams)    // prepare post form
        )
        return loginResponseObj // return received login response jsonObject
    }
    async function signInUser(emailId,password){
        const postParams = {
            userEmailId : emailId,    // replace with actual
            userMD5Pass : password  // convert password to MD5 password
        }
        await lib_getOkResponse( // get ok response
            `${restApiAddress}/signup`,  // api endpoint
            lib_postForm(postParams)    // prepare post form
        )
    }
    async function resetCode(emailId){
        const postParams = {
            userEmailId : emailId // convert password to MD5 password
        }
        await lib_getOkResponse( // get ok response
            `${restApiAddress}/passwordResetMail`,  // api endpoint
            lib_postForm(postParams)    // prepare post form
        )
    }
    async function loginUserGoogle(emailId){
        const postParams = {
            userEmailId : emailId,    // replace with actual
        }
        const loginResponseObj = await lib_getJsonResponse( // get jsonObject in response
            `${restApiAddress}/logingoogle`,  // api endpoint
            lib_postForm(postParams)    // prepare post form
        )
        return loginResponseObj // return received login response jsonObject
    }
    async function updatePassword(emailId,resetCodeValue,password){
        const postParams = {
            userEmailId : emailId, // convert password to MD5 password
            passwordResetCode: resetCodeValue,
            newMD5Password: password
        }
        await lib_getOkResponse( // get ok response
            `${restApiAddress}/updatePasswordReset`,  // api endpoint
            lib_postForm(postParams)    // prepare post form
        )
    }

    // runAiCode on image, and return jsonObject with resulting file path.
    async function runAiCode(modelDir,code,imagePath) {
        const result = await lib_getJsonResponse(   // get jsonObject from server
            `${restApiAddress}/ai-activity/run-code-with-image`, // api endpoint
            lib_postForm({  // prepare request, pass code, original image path on server
                directory : modelDir,
                code,
                imagePath
            }, getUserRequestHeaderToken()))    // pass user header containing token
        return result // return the received json Object
    }

    // prepare the .sav model file on server
    // passes the csv content & recognition type
    async function runMLCSVModel(csv_content,recog_type) {
        const requestObj = lib_postForm({   // post form request
            csv_content,
            recog_type
        }, getUserRequestHeaderToken()) // add user header token

        const result = await lib_getJsonResponse(
            `${restApiAddress}/ml-activity/ml-build-csv-model`, // api endpoint
            requestObj  // post request options
        )
        return result   // return result (it contains the url to .sav file)
    }

    // provide directory where to save the file
    // provide fileName
    // provide base64ImageContent
    async function uploadBase64Image(directory,fileName,base64ImageContent) {
        const requestObj = lib_postForm({   // prepare post form
            directory,
            fileName,
            base64ImageContent
        },getUserRequestHeaderToken())  // add user header

        const result = await lib_getJsonResponse(   // get json response containing original image url
            `${restApiAddress}/uploadBase64ToJPG`,  // api endpoint
            requestObj  // post request options
        )
        return result
    }

    // return json object containing processed image url:
    async function uploadAITrainFile(file,directory) {
        const header = {...getUserRequestHeaderToken(), directory }
        // console.log(`${restApiAddress}/ai-activity/upload-teachable-model-zip`)
        await lib_getOkResponse(    // send ok response
            `${restApiAddress}/ai-activity/upload-teachable-model-zip`, // api endpoint
            lib_postZip(file,header)    // post request options
        )
    }

    // return json object containing sprite images url
    async function getUserSprites(){
        const userEmailId = loginDetails.userEmailId
        return await lib_getJsonResponse(   // return json response containing the images url
            `${restApiAddress}/ai-activity/get-sprites`,    // api endpoint
            lib_postForm({'userEmailId' : userEmailId}, // post request options
            getUserRequestHeaderToken() // add user header
            ))
    }

    return {
        loginUser,
        loginUserGoogle,
        signInUser,
        getUserDocKeyValue,
        updateUserDocKeyValue,
        getCustomJsonDoc,
        updateCustomJsonDoc,
        runAiCode,
        runMLCSVModel,
        uploadsDir,
        uploadBase64Image,
        getUploadsDir,
        uploadAITrainFile,
        getUserSprites,
        resetCode,
        updatePassword,
    }
}