import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export const multiValueReceiveDialogOps = {
    // following can be invoked from anywhere
    invoke(states){}, // this will be overwritten by system
    receiveInput({labelName="Label",inputType="name",fieldNames=[], onReceive=(valuesArr)=>{}} = {}){
        this.invoke({
            onReceive,labelName,inputType,fieldNames
        })
    },
}

export default function MultiValueReceiveDialog(props) {

    const [open,set_open] = useState(false)
    const [state,set_state] = useState(null)
    const [inputValues, set_inputValues] = useState([]);

    // override invoke:
    multiValueReceiveDialogOps.invoke = (states) => {
        // set default empty values for each filedNames
        const defaultInputValues = states.fieldNames.map(f=>"")
        // set values
        set_inputValues(defaultInputValues)
        // set states data
        set_state(states)
        // set show dialog:
        set_open(true)
    }

    // if state is not set, return empty html
    if(state===null) return (<></>)

    // on click create : give input values back
    const handleCreate = () => {
        state.onReceive(inputValues)
        handleClose()
    };

    // close:
    const handleClose = () => {
        set_open(false)
        set_state(null)
    };


    // check if only 1 property is set or multiple
    const single = state.fieldNames.length<2

    // set title:
    const title = `Enter value${!single?'s':''} for label '${state.labelName}'`

    // add this json object only to first property: so cursor is set to only first input:
    const textFieldProps = { autoFocus:"autoFocus" }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {state.fieldNames.map((field,idx)=>{
                        const props = idx===0?textFieldProps:{} // apply focus only to 1st element
                        // for each field: return empty value input field:
                        return <TextField
                            margin="dense"
                            {...props}
                            id={`${field}_${idx}`}
                            label={single?`Enter '${field}'` : `Enter value for '${field}'`}
                            type={state.inputType}
                            fullWidth
                            values={inputValues[idx]}
                            onChange = {event => {
                                // update value on change any value:
                                const values = [...inputValues]
                                values[idx] = event.target.value
                                set_inputValues(values)
                            }}
                        />
                    })}

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreate} color="primary">
                        {`Add Value${!single?'s':''}`}
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}