import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const confirmDiagCallback = { setOpen : (data={})=>{} }
export const confirmDiagOps = {
    // following can be invoked from anywhere
    show({onConfirm=()=>{},onCancel=()=>{},title="Confirm", description = "Description text", autoclosable = true, okBtnText="OK", cancelBtnTxt="Cancel", } = {}) {
        confirmDiagCallback.setOpen({
            onConfirm, onCancel, title, description, autoclosable, okBtnText,cancelBtnTxt,
        })
    },

    deleteConfirmationDialog(description,ops) {
        this.show({onConfirm(){
            ops()
        }, title : "Confirmation",description:description,okBtnText:"Delete"})
    },

    showConfirmationDialog(title,description,okBtnTxt,ops) {
        this.show({onConfirm(){
                ops()
            }, title : title,description:description,okBtnText:okBtnTxt})
    },

}



export default function ConfirmationDialog() {
    const [open, set_open] = useState(false);
    const [data,set_data] = useState(null);

    confirmDiagCallback.setOpen = (data)=>{
        set_open(true)
        set_data(data)
    }

    const handleClose = () => {
        set_open(false);
    };

    function onConfirm() {

        data.onConfirm()
        handleClose()
    }
    function onCancel() {

        data.onCancel()
        handleClose()
    }

    if(data===null) {
        return (<></>)
    }

    const autoCloseProps = data.autoclosable===false? {
        disableBackdropClick : true,
        disableEscapeKeyDown : true,
    } : {};

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                {...autoCloseProps}
            >
                <DialogTitle id="alert-dialog-title">{data.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{data.description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirm} color="primary" autoFocus>
                        {data.okBtnText}
                    </Button>

                    <Button onClick={onCancel} color="primary">
                        {data.cancelBtnTxt}
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}