import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Gallery from "react-grid-gallery";


export default function GridDialog(props) {

    // maintain grid dialog visibility:
    const showGridImageDialog = props.showGridImageDialog
    const set_showGridImageDialog = props.set_showGridImageDialog


    // if not show -> return empty html.
    if(showGridImageDialog===false) return <></>

    // sample images: uncomment below, to test the grid:
    // const IMAGES = [
    //     {
    //         thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
    //         thumbnailWidth: 320,
    //         thumbnailHeight: 212,
    //         thumbnailCaption: 'ocean',
    //     },
    //     {
    //         thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
    //         thumbnailWidth: 320,
    //         thumbnailHeight: 212,
    //         thumbnailCaption: 'beach',
    //     },
    //     {
    //         thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
    //         thumbnailWidth: 320,
    //         thumbnailHeight: 212,
    //         thumbnailCaption: 'send',
    //     }
    // ]

    // get output like above comments from server image files array
    const images = props.spriteImages.map( fileUrl =>{
        return {
            thumbnail: process.env.PUBLIC_URL+"/"+fileUrl,
            thumbnailCaption: fileUrl.split('/').pop(),
            thumbnailWidth: 80,
            thumbnailHeight: 80,
        }
    })

    // set visibility false on handleClose
    function handleClose() {
        set_showGridImageDialog(false)
    }

    return (
        <div>
            <Dialog open={showGridImageDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Sprite Images</DialogTitle>
                <DialogContent>
                    <Gallery images={images} enableLightbox={false} enableImageSelection={true}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}