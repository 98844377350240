import React, {useEffect} from 'react'
import './css/LoginPage.css'
import {Link, useHistory} from "react-router-dom";
import useLoginOps from "../hooks/useLoginOps";
import useHeaderOps from "../hooks/useHeaderOps";
//import {version} from "../../../package.json";
import {emptyCache} from "../../lib/js/jsUtil";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import packageInfo from "../../../package.json";




{  /*
    THIS WAS WRITTEM FOR BYPASS LOGIN
    export default function LoginPage() {
    const history = useHistory();

    useEffect(() => {
          Redirect the user to the dashboard
          history.push("/dashboard");
        }, []);

    return null; // This component doesn't render anything
*/}



export default function LoginPage(props) {

    // used for redirection:
    const history = useHistory()

    // used for login related operations
    const loginOps = useLoginOps(props)

    // used for header related operations
    const headerOps = useHeaderOps(props)


    useEffect(()=>{
        // if user is logged in
        if(props.loginDetails!==null) {

            // & projects are not set:
             if(props.userProjects===null) {
            //      fetch projects & activities of user
                 // avoid, infinite try on dashboard while fail to fetch project/activities
                 loginOps.fetchProjectsAndActivities()
             } else {    // project & activities of user are set
               // goto dashboard
                history.push("/dashboard")
         }
        }
        // above operation happen when, login details or user projects are changed:
    },[props.loginDetails,props.userProjects])

    // using references to get email & password at login time. These refs are being
    // used with HTML elements.
    const emailInput = React.createRef()
    const passInput = React.createRef()


    // adding css 'login' class for custom css on this page
    useEffect(()=>{
        // setup (add)
        document.body.classList.add("login")

        // return function will execute when we move
        // from login page to some other page
        return ()=>{
            // tear down (remove)
            document.body.classList.remove("login")
        }
    })

    const tryWithoutLogin = ()=> {
        // set free activity related details in header data
        headerOps.setFreeActivityHeader()
        // open free-activity page
        history.push("/free-activity")
    }
    
    

    
    // login/signUp using google auth


    const googleAuthLogin = useGoogleLogin({
        onSuccess: async respose => {
            try {
                const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                    headers: {
                        "Authorization": `Bearer ${respose.access_token}`
                    }
                })
                // console.log(res.data, "email: ", res.data.email)
                if(res.data.email_verified){
                    loginOps.loginUserGoogle(res.data.email)
                }
                else{
                    props.toastSuccess("Error during Google signIn/Out, please try again")
                }
            } catch (err) {
                // console.log(err)
                props.toastSuccess("Error during Google signIn/Out, please try again")
            }
        }
    });

    // when user click login
    function handleLoginClick() {
        // perform login operation:
        loginOps.loginUser(emailInput.current.value,passInput.current.value)
    }

    function onClickCreateAccount() {
        window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=0MsZjfXntUu1QNND29n9aCs6dQCEBZxIocOLPfbNKIdUREs3WkNBMEhRVTRSRjEyQkg1SVFQOTNDVy4u&embed=true","_blank")
        // history.push("/signup-41251234123")
    }

    function onClickForgotPassword() {
        // forget password feature will be available when email sending issue with https is fixed.
        // alert("Password reset feature will be available soon.")

        history.push("/forget-password")

    }



    // For : Test caching:
    // useEffect(()=>{
    //     props.toastInfo("Testing Cache Burst. Serial 2311321__4")
    // })

    // cache burst when user comes on login page:
    useEffect(()=>{
        async function fetchVersion() {
            const cacheBurst = new Date().getTime()

            let result = await fetch(`${process.env.PUBLIC_URL}/meta.json?`+cacheBurst)
            result = await result.json()
            const oldVersion = packageInfo.version
            const newVersion = result.version
            if(oldVersion!==newVersion) {
                props.set_appVersion(result.version)
                emptyCache()
            }
        }
        fetchVersion().then()
    },[])




    return (
        <>
            <header id="home-section">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        {/* process.env.PUBLIC_URL = points to the public directory of the project. */}
                        <div className="col-lg-8 p-0 text-white cover_img" style={{backgroundImage:'url('+process.env.PUBLIC_URL + '/assets/images/web/geometric-1732847_960_720.webp)'}}>
                            <div className="dark-overlay d-flex flex-column align-items-center justify-content-center">
                                <h1 className="display-4 my-5 mont_txt">AI Connect</h1>

                                <div id="slider4" className="container carousel slide mb-5" data-ride="carousel">
                                    <ol className="carousel-indicators">
                                        <li className="active" data-target="#slider4" data-slide-to="0"/>
                                        <li data-target="#slider4" data-slide-to="1"/>
                                        <li data-target="#slider4" data-slide-to="2"/>
                                        <li data-target="#slider4" data-slide-to="3"/>
                                    </ol>

                                    {/* Moving Slider on Login Page  */}
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <h2 className="title2 mb-5 text-center poppins_txt">Graphics
                                                        Designing</h2>
                                                    <ul className="desc2 p-3 mt-3 ml-3 poppins_txt">
                                                        <li> Block based coding to learn Graphics Designing.</li>
                                                        <li> Learn Block Coding & Python Coding.</li>
                                                        <li> 50 + Graphics Designing Activities, more than 25 + Graphics
                                                            Designing Projects.
                                                        </li>
                                                        <li> Draw your Imaginations & learn coding.</li>
                                                        <li> Think Out of the box & draw your own graphics.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <img className="img-fluid py-3 w-75 m-auto"
                                                         src={process.env.PUBLIC_URL + '/assets/images/web/Graphics_Designing.png' }
                                                         alt={"Graphics_Designing"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <h2 className="title2 mb-5 text-center poppins_txt">Basic
                                                        Python</h2>
                                                    <ul className="desc2 p-3 pt-5 mt-3 ml-3 poppins_txt">
                                                        <li> GUI based coding to learn Python Coding.</li>
                                                        <li> Learn Block Coding & Python Coding.</li>
                                                        <li> 100 + Basic Python Activities, more than 50 + Basic Python
                                                            Projects.
                                                        </li>
                                                        <li> Draw your Imaginations & learn coding.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <img className="img-fluid py-3 w-75 m-auto"
                                                         src={process.env.PUBLIC_URL + '/assets/images/web/Basic_Python.png' }
                                                         alt={"Basic_Python"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <h2 className="title2 mb-5 text-center poppins_txt">Machine
                                                        Learning</h2>
                                                    <ul className="desc2 p-3 pt-5 mt-3 ml-3 poppins_txt">
                                                        <li> Train your own machine.</li>
                                                        <li> Test & Develop cases for your machine.</li>
                                                        <li> Deploy your own predictive learning.</li>
                                                        <li> Learn Block Coding & Python Coding.</li>
                                                        <li> 50 + Machine Learning projects.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <img className="img-fluid py-3 w-75 m-auto"
                                                         src={process.env.PUBLIC_URL + '/assets/images/web/Machine_Learning.png' }
                                                         alt={"Machine_Learning"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <h2 className="title2 mb-5 text-center poppins_txt">AI Real
                                                        World</h2>
                                                    <ul className="desc2 p-3 pt-5 mt-3 ml-3 poppins_txt">
                                                        <li> Learn to detect & recognize human faces.</li>
                                                        <li> Learn to recognize objects.</li>
                                                        <li> Learn to recognize color & many more.</li>
                                                        <li> Integration of AI with hardware .</li>
                                                        <li> 50 + Artificial Intelligence projects.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <img className="img-fluid py-3 w-75 m-auto"
                                                         src={process.env.PUBLIC_URL + '/assets/images/web/AI_Real_World.png' }
                                                         alt={"AI_Real_World"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <a href="#slider4" className="carousel-control-prev" data-slide="prev">
                                        <span className="carousel-control-prev-icon"/>
                                    </a>

                                    <a href="#slider4" className="carousel-control-next" data-slide="next">
                                        <span className="carousel-control-next-icon"/>
                                    </a>
                                </div>


                            </div>
                        </div>


                        <div className="col-lg-4 d-flex flex-column align-items-center justify-content-center"
                             style={{backgroundColor: "White" }}>
                            <img id="com_logo" src={process.env.PUBLIC_URL + '/assets/images/web/stemrobo_logo.png' } className="img-fluid mt-5 mb-3"
                                 width="220px" alt={"stemrobo_logo"}/>

                            <form>
                                <h3 className="text-center ai_h2 my-5">Login to Continue</h3>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">@</span>
                                    </div>
                                    <input className="form-control mont_input1" name="email" type="email"
                                           maxLength="150"
                                           placeholder="Email-Id"
                                           ref={emailInput}
                                        // value={"ankit.kumar@tevatrontech.org"}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-lock" /> </span>
                                    </div>
                                    <input className="form-control mont_input1" name="password" type="password"
                                           maxLength="50"
                                           placeholder="Password"
                                           ref={passInput}
                                        // value={"md5pass"}
                                    />
                                </div>
                                <div className="d-flex px-3">
                                    <div className="checkbox mr-auto invisible">
                                    </div>
                                    <span id="frgt_pass" onClick={ onClickForgotPassword } className="btn-link mont_txt txt_sm" style={{cursor: "pointer" }}>Forget Password?</span>
                                </div>


                                {/* Remove below div, when create account is enabled. */}

                                {/*<div className="d-flex justify-content-around my-5">*/}
                                {/*    <button id="btn_login" className="bt1 btn btn-primary" type="button" onClick={ handleLoginClick }>Login</button>*/}
                                {/*</div>*/}

                                <div className="btn-toolbar justify-content-between" role="toolbar"
                                     aria-label="Toolbar with button groups">
                                    <div className="btn-group" role="group" aria-label="First group">
                                        <div className="d-flex justify-content-around-1 my-4">
                                            <button id="btn_login" className="bt1 btn btn-primary" type="button" onClick={ handleLoginClick }>Login</button>
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="d-flex justify-content-around my-4">
                                            <button id="btn_login" style={{'background-color':'green','color':'white'}} className="bt1 btn btn-light" type="button" onClick={ onClickCreateAccount }>Request a Callback</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="hr-div">
                                    <hr class="hr-two-divider" style={{ "margin": "24px 0px" }}></hr>
                                    <div class="hr-middle-text">or</div>
                                    <hr class="hr-two-divider" style={{ "margin": "24px 0px" }}></hr>
                                </div>

                                
                                    <div className="d-flex justify-content-around my-3">
                                    <button id="btn_google_login" className="bt1 btn btn-primary btn-transparent" type="button" onClick={googleAuthLogin}>
                                    <img style={{height: "1.5rem", marginBottom: "0.2rem"}} src={process.env.PUBLIC_URL + '/assets/images/web/google-icon.png'}/>  Continue with google
                                    </button>
                                </div>
                                <hr /> 
                            </form>
                            <br/>
                            <Link onClick={ tryWithoutLogin } className="mb-5">Try without login &rarr;</Link>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}










