// import React from 'react'
// import footerBackground from './istockphoto-1396069649-612x612.jpg';
// import '@fortawesome/fontawesome-free/css/all.min.css';


// export default function Footer() {
//     return (
//         <>
//             {/*<section id="footer" className="bg-info" className="bg-dark" style={{"margin-top": "3rem"}}>*/ }
//             <section id="footer" style={{marginTop: "3rem", backgroundImage: `url(${footerBackground})`, backgroundSize: 'cover', backgroundPosition: 'center', color: 'white'}}>
//                 <div className="container">
//                   <div className="d-flex flex-column justify-content-center poppins_txt text-white text-center"
//                         style={{padding: "1rem 0"}}>
//                        <h5>© All rights reserved. Stemrobo Technologies Pvt. Ltd.</h5>
//                        <p>Contact us:  sales@stemrobo.com</p>
//                        {/* <p>Visit our website: <a href="https://www.stemrobo.com">https://www.stemrobo.com</a></p>    */}
//                        <p>Visit our website: <a href="https://www.stemrobo.com" style={{ color: 'white' }}>https://www.stemrobo.com</a></p>

//                        <div>
//                      {/* <p>Follow us on social media:</p>
//                     <ul className="list-inline-new" >
//                         <li className="list-inline-item"><a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
//                         <li className="list-inline-item"><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">Twitter</a></li>
//                         <li className="list-inline-item"><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
//                     </ul>  */}
//                 </div>   

//                    </div>
//               </div>
              
//            </section>
//         </>
        
//     )
// }


import React from 'react';
import footerBackground from './New_F.jpg';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Footer() {
    return (
        <section
            id="footer"
            style={{
                marginTop: "3rem",
                backgroundImage: `url(${footerBackground})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white',
                padding: '2rem 0'
            }}
        >
            <div className="container text-center text-white poppins_txt">
                <h5>
                    <span style={{ marginRight: '8px' }}>©</span>
                    All rights reserved. Stemrobo Technologies Pvt. Ltd.
                </h5>
                <p>
                    <i className="fas fa-envelope" style={{ marginRight: '8px' }}></i>
                    Contact us: <a href="mailto:sales@stemrobo.com" style={{ color: 'white' }}>sales@stemrobo.com</a>
                </p>
                <p>
                    <i className="fas fa-globe" style={{ marginRight: '8px' }}></i>
                    Visit our website: <a href="https://www.stemrobo.com" style={{ color: 'white' }}>https://www.stemrobo.com</a>
                </p>
                <div className="social-media-container">
                    <p style={{ marginBottom: '0.5rem' }}>Follow us on social media:</p>
                    <ul className="list-inline-new">
                        <li className="list-inline-item">
                            <a href="https://www.facebook.com/stemrobo/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://twitter.com/stemrobo" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.instagram.com/stemrobotechnologies/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.linkedin.com/company/stemrobo/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a href="https://www.youtube.com/channel/UCGEHSbR0QO8ih6DZ-Al9NXQ" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}




