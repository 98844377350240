// import { useEffect, useState } from 'react'
// import ActivitiesIntroCardGrid from "./components/ActivityIntroCardGrid";
// import { usePersistedValueState } from "../../../lib/hooks/StorageHooks";
// import UserProjectsTable from "./components/UserProjectsTable";
// import useProjectAndActivityOps from "../../hooks/useProjectAndActivityOps";
// import DialogCreateActivity from "./components/DialogCreateActivity";
// import { appData } from "../../util/appData";
// import "../css/UserDashboard.css"
// import { useHistory } from "react-router-dom";
// import useCustomActivityOps from "../../hooks/useCustomActivityOps";
// import useHeaderOps from "../../hooks/useHeaderOps";
// import React from 'react';

// export default function UserDashboard(props) {

//     // for page redirection
//     const history = useHistory()
//     // for project & activities operations
//     const projActOps = useProjectAndActivityOps(props)
//     // for activity content operations
//     const actOps = useCustomActivityOps(props)
//     // for changing header content
//     const headerOps = useHeaderOps(props)

//     // for search term entered by the user in projects section
//     const [userProjectsSearchTermState, set_userProjectsSearchTermState] = usePersistedValueState("local_userDashboard_userProjectsSearchTermState", "")
//     // for search term entered by the user in sample-projects section
//     const [sampleProjectsSearchTermState, set_sampleProjectsSearchTermState] = usePersistedValueState("local_userDashboard_sampleProjectsSearchTermState", "")
//     // for data to be used when create activity dialog open (like project name, activity types, etc)
//     const [activityDialogData, set_activityDialogData] = useState(null)


//     useEffect(() => {
//         // when login detail change (user logout), set search term to empty
//         set_userProjectsSearchTermState("")
//         set_sampleProjectsSearchTermState("")
//     }, [props.loginDetails])

//     function openCreateProjectDialog() {
//         props.inpDiag.receiveInput({
//             onReceive(input) {
//                 projActOps.createNewProject(input)
//             }, title: "Create Project", inputLabel: "Project Name", okBtnText: "Create"
//         })
//     }
    
//     // to open create-project dialog and redirect to activity page
//     function openQuickCreateProjectDialog(defaultActivityType) {
//         props.inpDiag.receiveInput({
//             onReceive(input) {
//                 projActOps.createNewProject(input).then(data => {
//                     const time = new Date().getTime()
//                     const newActivity = {
//                         // "project_uid": project_uid,
//                         // "activity_uid":userEmailId+'_activity_'+time,
//                         // "activity_name":activityName,
//                         "activity_type": defaultActivityType,
//                         "creation_date": "" + time,
//                         "last_modified_date": "" + time
//                     }
//                     const defautlNewContent = {
//                         "about": "",
//                         "workspaceContent": "<xml xmlns=\"http://www.w3.org/1999/xhtml\"></xml>",
//                         "activityMetadata": newActivity

//                     }
//                     props.set_userSelectedActivity(newActivity)
//                     props.set_userSelectedActivityContent(defautlNewContent)
//                     history.push("/activity")
//                 })
//             }, title: "Create Project", inputLabel: "Project Name", okBtnText: "Create"
//         })
//     }

//     function openCreateActivityDialog(defaultActivityType) {
//         // if project is not set, open 'create project' dialog first
//         if (props.userProjects == null || props.userProjects.length === 0) {
//             openCreateProjectDialog()
//         } else {
//             // to show 'create activity dialog', set_activityDialogData to set the details:
//             // when details are set, activity dialog will automatically open.
//             set_activityDialogData({
//                 onCreateActivity(project_uid, activityName, activityType) {
//                     projActOps.createNewActivity(project_uid, activityName, activityType)
//                 },
//                 activityTypes: appData.activity.types,
//                 userProjects: props.userProjects,
//                 defaultActivityType: defaultActivityType != null ? defaultActivityType : appData.activity.types.BC[0],
//                 defaultProjectId: props.userSelectedProject != null ? props.userSelectedProject.project_uid : props.userProjects[0].project_uid,
//             })
//         }
//     }

//     // dialog for quick activity create
//     function openQuickCreateActivityDialog(defaultActivityType) {
//         if (props.userProjects.length <= 0) {
//             openQuickCreateProjectDialog(defaultActivityType)
//         }
//         else {
//             const time = new Date().getTime()
//             const newActivity = {
//                 // "project_uid": project_uid,
//                 // "activity_uid":userEmailId+'_activity_'+time,
//                 // "activity_name":activityName,
//                 "activity_type": defaultActivityType,
//                 "creation_date": "" + time,
//                 "last_modified_date": "" + time
//             }
//             const defautlNewContent = {
//                 "about": "",
//                 "workspaceContent": "<xml xmlns=\"http://www.w3.org/1999/xhtml\"></xml>",
//                 "activityMetadata": newActivity

//             }
//             props.set_userSelectedActivity(newActivity)
//             props.set_userSelectedActivityContent(defautlNewContent)
//             history.push("/activity")
//         }
//     }

//     useEffect(() => {
//         // when page loads, set the sub-heading
//         headerOps.setUserContentSubHeader("Welcome to User Dashboard")
//     }, [])


//     // returns the HTML for UserDashboard page:
//     return (
//         <>
//             {/* <DialogCreateActivity data={[activityDialogData, set_activityDialogData]} title="Create Activity" />

//             <section id="pro_cat" className="jumbotron jumbotron-fluid text-center">
//                 <div className="container">
//                     <h1 className="display-5 mont_txt">Artificial Intelligence Based <br />Coding Platform</h1>
//                     <p className="lead poppins_txt">Let’s explore the world of Artificial Intelligence & Machine Learning</p>
//                     <ActivitiesIntroCardGrid {...{
//                         onClickCreate(activityType) {
//                             openCreateActivityDialog(activityType)
//                         },
//                         onClickQuickCreate(activityType) {
//                             openQuickCreateActivityDialog(activityType)
//                         }
//                     }} />
//                 </div>
//             </section> */}

//              {/* This is new function created for background */}

            

//              <section id="pro_cat" className="jumbotron jumbotron-fluid text-center custom-bg text-very-bold-black">
//     <div className="container">
//         <h1 className="display-5 mont_txt">
//             Artificial Intelligence Based <br /> Coding Platform
//         </h1>
//         <p className="lead poppins_txt">
//             Let’s explore the world of Artificial Intelligence & Machine Learning
//         </p>
//         <ActivitiesIntroCardGrid
//             onClickCreate={(activityType) => {
//                 openCreateActivityDialog(activityType);
//             }}
//             onClickQuickCreate={(activityType) => {
//                 openQuickCreateActivityDialog(activityType);
//             }}
//         />
//     </div>
// </section> 



//             <section id="projects">
//                 <div className="container poppins_txt">
//                     <h1 className="display-5 mont_txt text-center">Projects</h1>

//                     <div className="d-flex flex-wrap justify-content-center ">
//                         <div className="form-inline mx-3 mt-5 ">
//                             <input id="search_terms" name="search_terms" type="text"
//                                 className="form-control mr-2" placeholder="Search"
//                                 value={userProjectsSearchTermState}
//                                 onChange={event => { set_userProjectsSearchTermState(event.target.value) }}
//                             />
//                             <button id="clear_btn" onClick={event => { set_userProjectsSearchTermState("") }}
//                                 className="btn btn-outline-info ml-auto mr-2">Clear</button>
//                         </div>
//                         <div className="mx-3 mt-5">
//                             <button className="btn btn-info-1 btn-block" data-toggle="modal"
//                                 data-target="#createProject" onClick={event => { openCreateProjectDialog() }}>
//                                 <i className="fas fa-envelope-open-o" /> Create Project
//                             </button>
//                         </div>

//                         <div className="mx-3 mt-5">
//                             <button id="showCreateActivityBtn" className="btn btn-info-2 btn-block" onClick={event => { openCreateActivityDialog(null) }}>
//                                 <i className="fas fa-envelope-open-o" /> Create Activity
//                             </button>
//                         </div>
//                     </div>

//                     <UserProjectsTable {...props} {...{
//                         userProjectsSearchTermState, set_userProjectsSearchTermState,
//                         // when user clicks on activity, following method get called
//                         openActivity(activity) {
//                             // fetch activity custom content first. (If found, proceed further)
//                             projActOps.fetchSelectedActivityContent(activity, (userSelectedActivityContent) => {
//                                 // set use clicked activity as the selected activity:
//                                 //debugger
//                                 projActOps.setSelectedActivity(activity)
//                                 // if activity is ML activity & it is not trained, redirect to training:
//                                 // if(activity.activity_type===appData.activity.types.ML[0] && !userSelectedActivityContent.isTrained){
//                                 //     history.push("/activity")
//                                 //
//                                 //     // if activity is AI activity, get default & user sprites first, then goto activity
//                                 // } else
//                                 actOps.getUserSprites(sprites => {
//                                     props.set_spriteImages(sprites.default)
//                                     history.push("/activity")
//                                 })
//                                 // if(activity.activity_type===appData.activity.types.AI[0]) {
//                                 //     actOps.getUserSprites(sprites=>{
//                                 //         props.set_spriteImages(sprites.default)
//                                 //         history.push("/activity")
//                                 //     })
//                                 // } else {
//                                 //     // simply go to activity in every other case
//                                 //     actOps.getUserSprites(sprites=>{
//                                 //         props.set_spriteImages(sprites.default)
//                                 //         history.push("/activity")
//                                 //     })
//                                 // }
//                             })
//                         },
//                         openExampleActivity(activity) {
//                             const time = new Date().getTime()
//                             const newActivity = {
//                                 // "project_uid": project_uid,
//                                 // "activity_uid":userEmailId+'_activity_'+time,
//                                 // "activity_name":activityName,
//                                 "activity_type": activity.activity_type,
//                                 "creation_date": "" + time,
//                                 "last_modified_date": "" + time
//                             }
//                             const defautlNewContent = {
//                                 "about": "",
//                                 "workspaceContent": "<xml xmlns=\"http://www.w3.org/1999/xhtml\"></xml>",
//                                 "activityMetadata": newActivity

//                             }
//                             props.set_userSelectedActivity(newActivity)
//                             props.set_userSelectedActivityContent(defautlNewContent)
//                             props.set_selectedTutorial(prevState => ({ ...prevState, ...activity }))
//                             history.push("/examples")
//                         }
//                     }} />

//                 </div>
//             </section>
//         </>
//     )
// }


import { useEffect, useState } from 'react';
import ActivitiesIntroCardGrid from "./components/ActivityIntroCardGrid";
import { usePersistedValueState } from "../../../lib/hooks/StorageHooks";
import UserProjectsTable from "./components/UserProjectsTable";
import useProjectAndActivityOps from "../../hooks/useProjectAndActivityOps";
import DialogCreateActivity from "./components/DialogCreateActivity";
import { appData } from "../../util/appData";
import "../css/UserDashboard.css";
import { useHistory } from "react-router-dom";
import useCustomActivityOps from "../../hooks/useCustomActivityOps";
import useHeaderOps from "../../hooks/useHeaderOps";
import React from 'react';

export default function UserDashboard(props) {
    const history = useHistory();
    const projActOps = useProjectAndActivityOps(props);
    const actOps = useCustomActivityOps(props);
    const headerOps = useHeaderOps(props);

    const [userProjectsSearchTermState, set_userProjectsSearchTermState] = usePersistedValueState("local_userDashboard_userProjectsSearchTermState", "");
    const [sampleProjectsSearchTermState, set_sampleProjectsSearchTermState] = usePersistedValueState("local_userDashboard_sampleProjectsSearchTermState", "");
    const [activityDialogData, set_activityDialogData] = useState(null);

    useEffect(() => {
        set_userProjectsSearchTermState("");
        set_sampleProjectsSearchTermState("");
    }, [props.loginDetails]);

    function openCreateProjectDialog() {
        props.inpDiag.receiveInput({
            onReceive(input) {
                projActOps.createNewProject(input);
            },
            title: "Create Project",
            inputLabel: "Project Name",
            okBtnText: "Create"
        });
    }

    function openQuickCreateProjectDialog(defaultActivityType) {
        props.inpDiag.receiveInput({
            onReceive(input) {
                projActOps.createNewProject(input).then(data => {
                    const time = new Date().getTime();
                    const newActivity = {
                        "activity_type": defaultActivityType,
                        "creation_date": "" + time,
                        "last_modified_date": "" + time
                    };
                    const defautlNewContent = {
                        "about": "",
                        "workspaceContent": "<xml xmlns=\"http://www.w3.org/1999/xhtml\"></xml>",
                        "activityMetadata": newActivity
                    };
                    props.set_userSelectedActivity(newActivity);
                    props.set_userSelectedActivityContent(defautlNewContent);
                    history.push("/activity");
                });
            },
            title: "Create Project",
            inputLabel: "Project Name",
            okBtnText: "Create"
        });
    }

    function openCreateActivityDialog(defaultActivityType) {
        console.log('openCreateActivityDialog called');
        if (props.userProjects == null || props.userProjects.length === 0) {
            openCreateProjectDialog();
        } else {
            set_activityDialogData({
                onCreateActivity(project_uid, activityName, activityType) {
                    projActOps.createNewActivity(project_uid, activityName, activityType);
                },
                activityTypes: appData.activity.types,
                userProjects: props.userProjects,
                defaultActivityType: defaultActivityType != null ? defaultActivityType : appData.activity.types.BC[0],
                defaultProjectId: props.userSelectedProject != null ? props.userSelectedProject.project_uid : props.userProjects[0].project_uid,
            });
        }
    }

    function openQuickCreateActivityDialog(defaultActivityType) {
        console.log('openQuickCreateActivityDialog called');
        if (props.userProjects.length <= 0) {
            openQuickCreateProjectDialog(defaultActivityType);
        } else {
            const time = new Date().getTime();
            const newActivity = {
                "activity_type": defaultActivityType,
                "creation_date": "" + time,
                "last_modified_date": "" + time
            };
            const defautlNewContent = {
                "about": "",
                "workspaceContent": "<xml xmlns=\"http://www.w3.org/1999/xhtml\"></xml>",
                "activityMetadata": newActivity
            };
            props.set_userSelectedActivity(newActivity);
            props.set_userSelectedActivityContent(defautlNewContent);
            history.push("/activity");
        }
    }

    useEffect(() => {
        headerOps.setUserContentSubHeader("Welcome to User Dashboard");
    }, []);

    return (
        <>
            <DialogCreateActivity data={[activityDialogData, set_activityDialogData]} title="Create Activity" />

            <section id="pro_cat" className="jumbotron jumbotron-fluid text-center custom-bg text-very-bold-black">
                <div className="container">
                    <h1 className="display-5 mont_txt">
                        Artificial Intelligence Based <br /> Coding Platform
                    </h1>
                    <p className="lead poppins_txt">
                        Let’s explore the world of Artificial Intelligence & Machine Learning
                    </p>
                    <ActivitiesIntroCardGrid
                        onClickCreate={(activityType) => {
                            openCreateActivityDialog(activityType);
                        }}
                        onClickQuickCreate={(activityType) => {
                            openQuickCreateActivityDialog(activityType);
                        }}
                    />
                </div>
            </section>

            <section id="projects">
                <div className="container poppins_txt">
                    <h1 className="display-5 mont_txt text-center">Projects</h1>

                    <div className="d-flex flex-wrap justify-content-center">
                        <div className="form-inline mx-3 mt-5">
                            <input id="search_terms" name="search_terms" type="text"
                                className="form-control mr-2" placeholder="Search"
                                value={userProjectsSearchTermState}
                                onChange={event => { set_userProjectsSearchTermState(event.target.value); }}
                            />
                            <button id="clear_btn" onClick={event => { set_userProjectsSearchTermState(""); }}
                                className="btn btn-outline-info ml-auto mr-2">Clear</button>
                        </div>
                        <div className="mx-3 mt-5">
                            <button className="btn btn-info-1 btn-block" data-toggle="modal"
                                data-target="#createProject" onClick={event => { openCreateProjectDialog(); }}>
                                <i className="fas fa-envelope-open-o" /> Create Project
                            </button>
                        </div>

                        <div className="mx-3 mt-5">
                            <button id="showCreateActivityBtn" className="btn btn-info-2 btn-block" onClick={event => { openCreateActivityDialog(null); }}>
                                <i className="fas fa-envelope-open-o" /> Create Activity
                            </button>
                        </div>
                    </div>

                    <UserProjectsTable {...props} {...{
                        userProjectsSearchTermState, set_userProjectsSearchTermState,
                        openActivity(activity) {
                            projActOps.fetchSelectedActivityContent(activity, (userSelectedActivityContent) => {
                                projActOps.setSelectedActivity(activity);
                                actOps.getUserSprites(sprites => {
                                    props.set_spriteImages(sprites.default);
                                    history.push("/activity");
                                });
                            });
                        },
                        openExampleActivity(activity) {
                            const time = new Date().getTime();
                            const newActivity = {
                                "activity_type": activity.activity_type,
                                "creation_date": "" + time,
                                "last_modified_date": "" + time
                            };
                            const defautlNewContent = {
                                "about": "",
                                "workspaceContent": "<xml xmlns=\"http://www.w3.org/1999/xhtml\"></xml>",
                                "activityMetadata": newActivity
                            };
                            props.set_userSelectedActivity(newActivity);
                            props.set_userSelectedActivityContent(defautlNewContent);
                            props.set_selectedTutorial(prevState => ({ ...prevState, ...activity }));
                            history.push("/examples");
                        }
                    }} />

                </div>
            </section>
        </>
    );
}


