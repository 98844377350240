import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

export default function ChipsArray(props) {

    const classes = useStyles();
    const {chipData, set_chipData} = props.data

    const handleDelete = (chipToDelete) => {
        props.onDeleteChip(chipToDelete)
    };

    const handleOnClick = (chipToDelete) => {
        props.onClickChip(chipToDelete)
    };


    return (
        <Paper component="ul" className={classes.root}>
            {chipData.map((data,key) => {
                return (
                    <li key={key}>
                        <Chip
                            label={data}
                            onDelete={event => { handleDelete(data) }}
                            onClick={event => { handleOnClick(data) }}
                            className={classes.chip}
                        />
                    </li>
                );
            })}
        </Paper>
    );
}
