import React from 'react'
import useCallWraps, {delay} from "./useCallWraps";
import useAiConnectRestApi from "./useAiConnectRestApi";
import {assertEmailID, assertNotEmpty} from "../../lib/js/FormValidationUtil";
import {appData} from "../util/appData";
import md5 from 'md5';
import {ValidationFail} from "../../lib/js/Exceptions";
import {useHistory} from "react-router-dom";


export default function useLoginOps(props) {
    const history = useHistory()
    const call = useCallWraps(props)
    const restApi = useAiConnectRestApi(props)
    const keys = appData.keys
    const defaultData = appData.defaultNetworkResponses

    return {

        // if loginDetails (which contains loginResponse) is
        // present, means user is logged in else not.
        isLoggedIn() {
            return props.loginDetails !== null
        },

        // Called when user click login:
        loginUser(userEmailId, userNormalPassword) {
            call.validationCheck(()=> {
                // check user entered valid emailId and password
                assertEmailID(userEmailId)
                assertNotEmpty(userNormalPassword)
                // convert password to MD5
                const userMD5Password = md5(userNormalPassword.trim())

                // we perform login with the help of restApi.loginUser function, which takes
                // user emailId & MD5Password
                call.asyncThen(call.request(async ()=>{
                    // perform the loginUser request & receive the response in loginResponse variable
                    // response will be like: {"extraInfo": [], "token": "42E7573C2669238DB89FA498F800FFD44cea6001-560d-4cb7-8e55-34f7d4b6429d", "userEmailId": "ankit@gmail.com"}
                    const loginResponse = await restApi.loginUser(userEmailId.trim(),userMD5Password)
                    // set the response in Global data (in AppInit.js)
                    props.set_loginDetails(loginResponse)
                }))
            })
        },

        // Called when user login using google:
        loginUserGoogle(userEmailId) {
            call.validationCheck(()=> {
                // check user entered valid emailId and password
                assertEmailID(userEmailId)
                // we perform login with the help of restApi.loginUser function, which takes
                // user emailId & MD5Password
                call.asyncThen(call.request(async ()=>{
                    const loginResponse = await restApi.loginUserGoogle(userEmailId.trim())
                    // set the response in Global data (in AppInit.js)
                    props.set_loginDetails(loginResponse)
                }))
            })
        },
        resetCode(userEmailId) {
            call.validationCheck(()=> {
                // check user entered valid emailId and password
                assertEmailID(userEmailId)
                // we perform login with the help of restApi.loginUser function, which takes
                // user emailId & MD5Password
                call.asyncThen(call.request(async ()=>{
                    // perform the loginUser request & receive the response in loginResponse variable
                    // response will be like: {"extraInfo": [], "token": "42E7573C2669238DB89FA498F800FFD44cea6001-560d-4cb7-8e55-34f7d4b6429d", "userEmailId": "ankit@gmail.com"}

                    await restApi.resetCode(userEmailId.trim())
                    props.toastSuccess("Reset Code has been Sent in your mail id")
                    history.push("/reset-password")

                    //console.log(resetResponse); // set the response in Global data (in AppInit.js)

                    //props.set_loginDetails(resetResponse)
                }))
            })
        },
        updatePassword(userEmailId,resetCodeValue,userNewNormalPassword,reenterNewNormalPassword) {
            call.validationCheck(()=> {
                // check user entered valid emailId and password
                assertEmailID(userEmailId)
                assertNotEmpty(userNewNormalPassword)
                assertNotEmpty(reenterNewNormalPassword)
                assertNotEmpty(resetCodeValue)
                userNewNormalPassword = userNewNormalPassword.trim()
                reenterNewNormalPassword = reenterNewNormalPassword.trim()
                if(userNewNormalPassword!==reenterNewNormalPassword)
                    throw new ValidationFail("Password Mismatch")
                const userNewMD5Password = md5(userNewNormalPassword.trim())
                // we perform login with the help of restApi.loginUser function, which takes
                // user emailId & MD5Password
                call.asyncThen(call.request(async ()=>{
                    // perform the loginUser request & receive the response in loginResponse variable
                    // response will be like: {"extraInfo": [], "token": "42E7573C2669238DB89FA498F800FFD44cea6001-560d-4cb7-8e55-34f7d4b6429d", "userEmailId": "ankit@gmail.com"}
                    try {
                        await restApi.updatePassword(userEmailId.trim(),resetCodeValue.trim(),userNewMD5Password)
                        props.toastSuccess("Password changed successfully")
                        history.push("/login")
                    }
                    catch (e) {
                        // console.log("error",e);
                        props.toastError(" Failed to Reset Password")
                    }
                    //console.log(resetResponse); // set the response in Global data (in AppInit.js)
                    //props.set_loginDetails(resetResponse)
                }))
            })
        },

        signupUser(userEmailId,userNormalPassword,reenterNormalPassword) {
            call.validationCheck(()=> {
                // check user entered valid emailId and password
                assertEmailID(userEmailId)
                assertNotEmpty(userNormalPassword)
                assertNotEmpty(reenterNormalPassword)

                userNormalPassword = userNormalPassword.trim()
                reenterNormalPassword = reenterNormalPassword.trim()
                if(userNormalPassword!==reenterNormalPassword)
                    throw new ValidationFail("Password Mismatch")

                // convert password to MD5
                const userMD5Password = md5(userNormalPassword.trim())
                call.asyncThen(call.request(async ()=>{
                    // In this we can remove the try and catch statement
                    try {
                        await restApi.signInUser(userEmailId.trim(), userMD5Password)
                        // console.log(userEmailId.trim(),"created.")
                        props.toastSuccess("Sign-up successfully, you can login now.")
                    }catch (e) {
                        // console.log(userEmailId.trim(),"Error : "+e)
                    }
                    await delay(1000)
                    // history.push("/login")
                }))
            })
        },
        
        // retrieve userEmailID from loginDetails (if present) else return empty ""
        userEmailId() {
            try { return props.loginDetails.userEmailId } catch (e) {return ""}
        },


        // Once login is successful, We retrieve user projects & activities
        // and set them in Global data (in AppInit.js).
        fetchProjectsAndActivities() {
            call.asyncThen(call.request(async () => {
                /*
                We get user projects & activities from server 'from UserDocument (MongoDB)', we
                1. pass the key (whose value we need from server), and
                2. default value (in case if null/empty is returned from server, when key is
                not already present/saved on server.
                 */
                const projects = await restApi.getUserDocKeyValue(
                    keys.userDocKeys.projects, defaultData.defaultArrayData
                )
                const activities = await restApi.getUserDocKeyValue(
                    keys.userDocKeys.activities, defaultData.defaultArrayData
                )

                // once retrieve user Projects & Activities, we save them
                // in Global data (in AppInit.js).
                props.set_userProjects(projects.content)
                props.set_userActivities(activities.content)
            }))
        },
    }
}





/*This code was written for Bypassing login page*/

// import React from 'react';
// import useCallWraps, { delay } from "./useCallWraps";
// import useAiConnectRestApi from "./useAiConnectRestApi";
// import { assertEmailID, assertNotEmpty } from "../../lib/js/FormValidationUtil";
// import { appData } from "../util/appData";
// import md5 from 'md5';
// import { ValidationFail } from "../../lib/js/Exceptions";
// import { useHistory } from "react-router-dom";

// export default function useLoginOps(props) {
//     const history = useHistory();
//     const call = useCallWraps(props);
//     const restApi = useAiConnectRestApi(props);
//     const keys = appData.keys;
//     const defaultData = appData.defaultNetworkResponses;

//     return {
//         isLoggedIn() {
//             // Always return true to bypass the login check
//             return true;
//         },

//         loginUser(userEmailId, userNormalPassword) {
//             call.validationCheck(() => {
//                 assertEmailID(userEmailId);
//                 assertNotEmpty(userNormalPassword);
//                 const userMD5Password = md5(userNormalPassword.trim());
//                 call.asyncThen(call.request(async () => {
//                     const loginResponse = await restApi.loginUser(userEmailId.trim(), userMD5Password);
//                     props.set_loginDetails(loginResponse);
//                 }));
//             });
//         },

//         loginUserGoogle(userEmailId) {
//             call.validationCheck(() => {
//                 assertEmailID(userEmailId);
//                 call.asyncThen(call.request(async () => {
//                     const loginResponse = await restApi.loginUserGoogle(userEmailId.trim());
//                     props.set_loginDetails(loginResponse);
//                 }));
//             });
//         },

//         resetCode(userEmailId) {
//             call.validationCheck(() => {
//                 assertEmailID(userEmailId);
//                 call.asyncThen(call.request(async () => {
//                     await restApi.resetCode(userEmailId.trim());
//                     props.toastSuccess("Reset Code has been Sent in your mail id");
//                     history.push("/reset-password");
//                 }));
//             });
//         },

//         updatePassword(userEmailId, resetCodeValue, userNewNormalPassword, reenterNewNormalPassword) {
//             call.validationCheck(() => {
//                 assertEmailID(userEmailId);
//                 assertNotEmpty(userNewNormalPassword);
//                 assertNotEmpty(reenterNewNormalPassword);
//                 assertNotEmpty(resetCodeValue);
//                 userNewNormalPassword = userNewNormalPassword.trim();
//                 reenterNewNormalPassword = reenterNewNormalPassword.trim();
//                 if (userNewNormalPassword !== reenterNewNormalPassword)
//                     throw new ValidationFail("Password Mismatch");
//                 const userNewMD5Password = md5(userNewNormalPassword.trim());
//                 call.asyncThen(call.request(async () => {
//                     try {
//                         await restApi.updatePassword(userEmailId.trim(), resetCodeValue.trim(), userNewMD5Password);
//                         props.toastSuccess("Password changed successfully");
//                         history.push("/login");
//                     } catch (e) {
//                         props.toastError("Failed to Reset Password");
//                     }
//                 }));
//             });
//         },

//         signupUser(userEmailId, userNormalPassword, reenterNormalPassword) {
//             call.validationCheck(() => {
//                 assertEmailID(userEmailId);
//                 assertNotEmpty(userNormalPassword);
//                 assertNotEmpty(reenterNormalPassword);
//                 userNormalPassword = userNormalPassword.trim();
//                 reenterNormalPassword = reenterNormalPassword.trim();
//                 if (userNormalPassword !== reenterNormalPassword)
//                     throw new ValidationFail("Password Mismatch");
//                 const userMD5Password = md5(userNormalPassword.trim());
//                 call.asyncThen(call.request(async () => {
//                     try {
//                         await restApi.signInUser(userEmailId.trim(), userMD5Password);
//                         props.toastSuccess("Sign-up successfully, you can login now.");
//                     } catch (e) {}
//                     await delay(1000);
//                 }));
//             });
//         },

//         userEmailId() {
//             try {
//                 return props.loginDetails.userEmailId;
//             } catch (e) {
//                 return "";
//             }
//         },

//         fetchProjectsAndActivities() {
//             call.asyncThen(call.request(async () => {
//                 const projects = await restApi.getUserDocKeyValue(keys.userDocKeys.projects, defaultData.defaultArrayData);
//                 const activities = await restApi.getUserDocKeyValue(keys.userDocKeys.activities, defaultData.defaultArrayData);
//                 props.set_userProjects(projects.content);
//                 props.set_userActivities(activities.content);
//             }));
//         },
//     };
// }



















