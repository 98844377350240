import React from 'react'
import {KnownError, ServerError} from "./Exceptions";

// don't export locally used functions


// prepare a post form body:
function prepareFormBody(keyValue) {
    let formBody = [];
    for (let property in keyValue) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(keyValue[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    return formBody.join("&");
}

// return post form key-values & header key-values
export function lib_postForm(formKeyVal={},headerKeyVal={}) {
    return {
        method: 'POST',
        headers: new Headers( {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            ...headerKeyVal
        }),
        body : prepareFormBody(formKeyVal),
    }
}

// return post zipFile & header key-values
export function lib_postZip(file,headerKeyVal={}) {
    const formData = new FormData()
    formData.append('file', file);
    return {
        method: 'POST',
        headers: new Headers( {
            // 'Content-Type': 'multipart/form-data',   // uncommenting this, upload will not work
            ...headerKeyVal
        }),
        body : formData,
    }
}

// return post jsonObject & header key-value.
export function lib_postJson(headerKeyVal={},jsonBody) {
    return {
        method: 'POST',
        headers: new Headers( {
            'Content-Type': 'application/json',
            ...headerKeyVal
        }),
        body : JSON.stringify(jsonBody),
    }
}

// return response from server (json + empty + text)
async function lib_getResponse(url,requestOptions,processResponse) {
    let response = undefined
    try {
        // console.log(url)
        response = await fetch(url,requestOptions)
    } catch (e) {
        throw KnownError("Fail to connect to system.",e)
    }
    if(response.ok) {
        const output = await processResponse(response)
        return output
    } else {
        let errOutput = undefined
        try {
            errOutput = await response.text()
        } catch (e) {
            throw KnownError("Unable to read error from server.",e)
        }
        throw ServerError(errOutput)
    }
}

// checks that server has sent OK response or not (returns true on OK)
export async function lib_getOkResponse(url,requestOptions) {
    return lib_getResponse(url,requestOptions,async (response)=>{
        return true;
    })
}

// return json response from server:
export async function lib_getJsonResponse(url,requestOptions) {
    return lib_getResponse(url,requestOptions,async (response)=>{
        return await response.json()
    })
}


