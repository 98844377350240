import React, {useEffect} from 'react'
import './css/LoginPage.css'
import {Link, useHistory} from "react-router-dom";
import useLoginOps from "../hooks/useLoginOps";
import useHeaderOps from "../hooks/useHeaderOps";
import {version} from "../../../package.json";
import {emptyCache} from "../../lib/js/jsUtil";
//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export default function ResetPasswordPage(props) {

    // used for redirection:
    const history = useHistory()

    // used for login related operations
    const loginOps = useLoginOps(props)

    // used for header related operations
    const headerOps = useHeaderOps(props)


    useEffect(()=>{

    },[props.loginDetails,props.userProjects])

    // using references to get email & password at login time. These refs are being
    // used with HTML elements.
    //const nameInput = React.createRef()
    const emailInput = React.createRef()
    const passInput = React.createRef()
    const confirmPassInput = React.createRef()
    // const captchaInput = React.createRef()
    const resetCode = React.createRef()


    // adding css 'login' class for custom css on this page
    useEffect(()=>{
        // setup (add)
        document.body.classList.add("login")

        // return function will execute when we move
        // from login page to some other page
        return ()=>{
            // tear down (remove)
            document.body.classList.remove("login")
        }
    })

    const tryWithoutLogin = ()=> {
        // set free activity related details in header data
        headerOps.setFreeActivityHeader()
        // open free-activity page
        history.push("/free-activity")
    }

    // when user click login

    function backToLoginPage () {
        loginOps.updatePassword(emailInput.current.value,resetCode.current.value,passInput.current.value,confirmPassInput.current.value)

    }

    // function onClickResetPassword() {
    //     console.log(emailInput.current.value);
    //     emailInput.current.value = "";
    //     props.toastSuccess("Reset Code has been sent to your mail id")
    //     // if (validateCaptcha(captchaInput.current.value)===true) {
    //     //     loginOps.signupUser(emailInput.current.value,passInput.current.value,confirmPassInput.current.value)
    //     // } else {
    //     //     props.toastWarning("Invalid Captcha")
    //     // }
    // }

    // useEffect(()=>{
    //     loadCaptchaEnginge(8)
    // },[])


    return (
        <>
            <header id="home-section">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        {/* process.env.PUBLIC_URL = points to the public directory of the project. */}
                        <div className="col-lg-8 p-0 text-white cover_img" style={{backgroundImage:'url('+process.env.PUBLIC_URL + '/assets/images/web/geometric-1732847_960_720.webp)'}}>
                            <div className="dark-overlay d-flex flex-column align-items-center justify-content-center">
                                <h1 className="display-4 my-5 mont_txt">AI Connect</h1>

                                <div id="slider4" className="container carousel slide mb-5" data-ride="carousel">
                                    <ol className="carousel-indicators">
                                        <li className="active" data-target="#slider4" data-slide-to="0"/>
                                        <li data-target="#slider4" data-slide-to="1"/>
                                        <li data-target="#slider4" data-slide-to="2"/>
                                        <li data-target="#slider4" data-slide-to="3"/>
                                    </ol>

                                    {/* Moving Slider on Login Page  */}
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <h2 className="title2 mb-5 text-center poppins_txt">Graphics
                                                        Designing</h2>
                                                    <ul className="desc2 p-3 mt-3 ml-3 poppins_txt">
                                                        <li> Block based coding to learn Graphics Designing.</li>
                                                        <li> Learn Block Coding & Python Coding.</li>
                                                        <li> 50 + Graphics Designing Activities, more than 25 + Graphics
                                                            Designing Projects.
                                                        </li>
                                                        <li> Draw your Imaginations & learn coding.</li>
                                                        <li> Think Out of the box & draw your own graphics.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <img className="img-fluid py-3 w-75 m-auto"
                                                         src={process.env.PUBLIC_URL + '/assets/images/web/Graphics_Designing.png' }
                                                         alt={"Graphics_Designing"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <h2 className="title2 mb-5 text-center poppins_txt">Basic
                                                        Python</h2>
                                                    <ul className="desc2 p-3 pt-5 mt-3 ml-3 poppins_txt">
                                                        <li> GUI based coding to learn Python Coding.</li>
                                                        <li> Learn Block Coding & Python Coding.</li>
                                                        <li> 100 + Basic Python Activities, more than 50 + Basic Python
                                                            Projects.
                                                        </li>
                                                        <li> Draw your Imaginations & learn coding.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <img className="img-fluid py-3 w-75 m-auto"
                                                         src={process.env.PUBLIC_URL + '/assets/images/web/Basic_Python.png' }
                                                         alt={"Basic_Python"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <h2 className="title2 mb-5 text-center poppins_txt">Machine
                                                        Learning</h2>
                                                    <ul className="desc2 p-3 pt-5 mt-3 ml-3 poppins_txt">
                                                        <li> Train your own machine.</li>
                                                        <li> Test & Develop cases for your machine.</li>
                                                        <li> Deploy your own predictive learning.</li>
                                                        <li> Learn Block Coding & Python Coding.</li>
                                                        <li> 50 + Machine Learning projects.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <img className="img-fluid py-3 w-75 m-auto"
                                                         src={process.env.PUBLIC_URL + '/assets/images/web/Machine_Learning.png' }
                                                         alt={"Machine_Learning"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <h2 className="title2 mb-5 text-center poppins_txt">AI Real World</h2>
                                                    <ul className="desc2 p-3 pt-5 mt-3 ml-3 poppins_txt">
                                                        <li> Learn to detect & recognize human faces.</li>
                                                        <li> Learn to recognize objects.</li>
                                                        <li> Learn to recognize color & many more.</li>
                                                        <li> Integration of AI with hardware .</li>
                                                        <li> 50 + Artificial Intelligence projects.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <img className="img-fluid py-3 w-75 m-auto"
                                                         src={process.env.PUBLIC_URL + '/assets/images/web/AI_Real_World.png' }
                                                         alt={"AI_Real_World"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <a href="#slider4" className="carousel-control-prev" data-slide="prev">
                                        <span className="carousel-control-prev-icon"/>
                                    </a>

                                    <a href="#slider4" className="carousel-control-next" data-slide="next">
                                        <span className="carousel-control-next-icon"/>
                                    </a>
                                </div>
                            </div>
                        </div>
        {/*In the section below there is reset code / reset password*/}

                        <div className="col-lg-4 d-flex flex-column align-items-center justify-content-center"
                             style={{backgroundColor: "White" }}>
                            <img id="com_logo" src={process.env.PUBLIC_URL + '/assets/images/web/stemrobo_logo.png' } className="img-fluid mt-5 mb-3"
                                 width="220px" alt={"stemrobo_logo"}/>

                            <form>
                                <h3 className="text-center ai_h2 my-5">Reset Password</h3>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">@</span>
                                    </div>
                                    <input className="form-control mont_input1" name="password" type="email"
                                           maxLength="150"
                                           placeholder="Email-Id"
                                           ref={emailInput}
                                        // value={"ankit.kumar@tevatrontech.org"}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="far fa-keyboard"></i></span>
                                    </div>
                                    <input className="form-control mont_input1" name="resetCode" type="text"
                                           maxLength="50"
                                           placeholder="Reset Code"
                                           ref={resetCode}
                                        // value={"md5pass"}
                                    />
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-lock" /> </span>
                                    </div>
                                    <input className="form-control mont_input1" name="password" type="text"
                                           maxLength="50"
                                           placeholder="New Password"
                                           ref={passInput}
                                           onChange={ event => { confirmPassInput.current.value=passInput.current.value }}
                                        // value={"md5pass"}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-lock" /> </span>
                                    </div>
                                    <input className="form-control mont_input1" name="password" type="password"
                                           maxLength="50"
                                           placeholder="Confirm Password"
                                           ref={confirmPassInput}
                                        // value={"md5pass"}
                                    />
                                </div>
                                <div className="d-flex justify-content-around my-5">
                                    <button id="btn_login" className="bt1 btn btn-primary" type="button" onClick={ backToLoginPage }>Change Password</button>
                                </div>

                                <hr />
                            </form>
                            <br/>
                            <Link onClick={ tryWithoutLogin } className="mb-5">Try not without login &rarr;</Link>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}