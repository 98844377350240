import React from 'react'
import {appData} from "../util/appData";
import {ValidationFail} from "../../lib/js/Exceptions";
import useAiConnectRestApi from "./useAiConnectRestApi";

// delay for testing:
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


// it provide basic wrapping around calls to provide consistent functionality
export default function useCallWraps(props) {
    // wrap restApi calls:
    const restApi = useAiConnectRestApi(props)

    // delete confirmation:
    function deleteConfirmationDialog(message, ops = ()=>{}) {
        props.confirmDiagOps.deleteConfirmationDialog(message,ops)
    }

    /*
    Executes the Promise / await block of code passed
    as Argument.
     */
    function asyncThen(ops = async ()=>{}){
        if(ops instanceof Promise) ops.then(); else ops().then()
    }

    // perform the network request 'ops()' block. It also handles the issue
    // raise in ops() block execution, like show 'error' as Toast.
    async function request(ops=async ()=>{}){
        try {
            props.fullScreenLoading.show("Processing...")
            if(ops instanceof Promise) await ops; else await ops()
        } catch (e) {
            //console.log(e)
            props.toastError(e.message)
            props.fullScreenLoading.forceClose()
        } finally {
            // console.log("REQ Loading : HIDE")
            props.fullScreenLoading.close()
        }
    }

    // this function is for Future perspective, it is not in use.
    async function requestWithRetry(ops=async ()=>{}, toastMessageOnFail = "Connection issue, retrying.", tryCount=-1){

        let succeed = false
        let abort = false
        let idx = 0;

        while (!succeed && !abort) {
            idx++;

            if(tryCount>-1){
                tryCount-=1;
                if(tryCount===0) abort = true
            }
            props.fullScreenLoading.show(idx===1?"Processing...":toastMessageOnFail)

            await delay(1000)

            try {
                if(ops instanceof Promise) await ops; else await ops()
                succeed = true
            } catch (e) {
                //console.log(e)
                props.toastInfo(toastMessageOnFail)
            }
            props.fullScreenLoading.close()
        }
    }

    // when array content is expected from server, we can extract the array content
    // from json object, and return the array
    function networkArrayContent(arr) {
        const contentObj = {...appData.defaultNetworkResponses.defaultArrayData}
        contentObj.content = arr
        return contentObj
    }

    /**
     * perform the validation in ops(), if any error occur,
     * handle the error in catch block.
     */
    function validationCheck(ops){
        try {
            ops()
        } catch (e) {
            // if error is validation error, show toast, else re-throw the error
            if(e.type===ValidationFail) {
                props.toastWarning(e.message)
            } else {
                throw e
            }
        }
    }

    // update user document key -> with json array value, on server
    async function updateUserDocKeyArrValue(userDocKey,newArr,setNewContentIn) {
        const arrContentObj = networkArrayContent(newArr)
        await request( restApi.updateUserDocKeyValue(userDocKey,arrContentObj) )
        setNewContentIn(newArr) // this will update the local data, when
        // updating on server is successful
    }

    // update custom document to jsonDoc, given is documentId
    async function updateCustomDocument(documentId,jsonDoc,setNewContentIn) {
        await request( restApi.updateCustomJsonDoc(documentId,jsonDoc) )
        setNewContentIn(jsonDoc) // this will update the local data, when
        // updating on server is successful
    }

    return {
        deleteConfirmationDialog,
        asyncThen,
        request,
        requestWithRetry,
        networkArrayContent,
        validationCheck,
        updateUserDocKeyArrValue,
        updateCustomDocument,
    }

}