
export const appData = {
    activity : {
        typesOld : {
            GD : ["GD","Graphic Design"],
            BP : ["BP","Basic Python"],
            ML : ["ML","Machine Learning"],
            AI : ["AI","AI Real World"],
        },
        types : {
            BC : ["BC","Basic Coding"],
            AC : ["AC","AI Coding"],
            TC : ["TC","Tinker Orbit Coding"],
            SC : ["SC","STEMBOT Coding"],  // Added SC later
        }
    },

    keys : {
        userDocKeys : {
            projects : "projects",
            activities : "activities",
        }
    },

    defaultNetworkResponses : {
        defaultArrayData : { content:[] },
        defaultCustomActivityContent : {
            types : {
                // custom new fields will be added in default content below. Different types can have different fields.
                // fields are only 1 level along side workspaceContent (key : value) for json arr/obj as value.
                GD : {
                    about:'Write Your Activity Details here',
                    workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
                    activityMetadata : null,
                },
                BP : {
                    about:'Write Your Activity Details here',
                    workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
                    activityMetadata : null,
                },
                ML : {
                    about:'Write Your Activity Details here',
                    workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
                    activityMetadata : null,
                    isTrained : false,  // when custom content fetched on dashboard, this field will tell where to go.
                    trainingJsonContent: { recognise: "text", labels:{} , value_names:[]},  // default content
                    model_file : null,
                    csv : null,
                    config : null,
                },
                AI : {
                    about:'Write Your Activity Details here',
                    workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
                    activityMetadata : null,
                    captureImageUrlPath : null,
                },
                BC : {
                    about:'Write Your Activity Details here',
                    workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
                    activityMetadata : null,
                    
                },
                AC : {
                    about:'Write Your Activity Details here',
                    workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
                    activityMetadata : null,
                    // captureImageUrlPath : null,
                },
                TC : {
                    about:'Write Your Activity Details here',
                    workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
                    activityMetadata : null,
                    
                },
                SC : {  // Add SC (Stembot Coding) activity type
                    about: 'Write Your STEMBOT Coding Activity Details here',
                    workspaceContent: `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
                    activityMetadata: null,
                }
                
                
            }
        }
    },
}


// {
//             return {
//                 workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                 activityMetadata,
//             }
// }
 








// export const appData = {
//     activity : {
//         typesOld : {
//             GD : ["GD","Graphic Design"],
//             BP : ["BP","Basic Python"],
//             ML : ["ML","Machine Learning"],
//             AI : ["AI","AI Real World"],
//         },
//         types : {
//             BC : ["BC","Basic Coding"],
//             AC : ["AC","AI Coding"],
//             TC : ["TC","Tinker Orbit Coding"],
//             SL : ["SL","Some New Activity Type"], // Added new type here
//         }
//     },

//     keys : {
//         userDocKeys : {
//             projects : "projects",
//             activities : "activities",
//         }
//     },

//     defaultNetworkResponses : {
//         defaultArrayData : { content:[] },
//         defaultCustomActivityContent : {
//             types : {
//                 // custom new fields will be added in default content below. Different types can have different fields.
//                 // fields are only 1 level along side workspaceContent (key : value) for json arr/obj as value.
//                 GD : {
//                     about:'Write Your Activity Details here',
//                     workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                     activityMetadata : null,
//                 },
//                 BP : {
//                     about:'Write Your Activity Details here',
//                     workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                     activityMetadata : null,
//                 },
//                 ML : {
//                     about:'Write Your Activity Details here',
//                     workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                     activityMetadata : null,
//                     isTrained : false,  // when custom content fetched on dashboard, this field will tell where to go.
//                     trainingJsonContent: { recognise: "text", labels:{} , value_names:[]},  // default content
//                     model_file : null,
//                     csv : null,
//                     config : null,
//                 },
//                 AI : {
//                     about:'Write Your Activity Details here',
//                     workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                     activityMetadata : null,
//                     captureImageUrlPath : null,
//                 },
//                 BC : {
//                     about:'Write Your Activity Details here',
//                     workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                     activityMetadata : null,
                    
//                 },
//                 AC : {
//                     about:'Write Your Activity Details here',
//                     workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                     activityMetadata : null,
//                     // captureImageUrlPath : null,
//                 },
//                 TC : {
//                     about:'Write Your Activity Details here',
//                     workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                     activityMetadata : null,
                    
//                 },
//                 SL : {
//                     about:'Write Your Activity Details here',
//                     workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                     activityMetadata : null,
//                 },
//             }
//         }
//     },
// }

// /*
// {
//             return {
//                 workspaceContent : `<xml xmlns="http://www.w3.org/1999/xhtml"></xml>`,
//                 activityMetadata,
//             }
//         },
//  */
