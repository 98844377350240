// import React, { useState, useRef, useEffect } from 'react';
// import { appData } from "../../../util/appData";
// import { useHistory } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

// export default function ActivitiesIntroCardGrid(props) {
//     const [moreOptionToggle, setMoreOptionToggle] = useState(false);
//     const [moreOptionContent, setMoreOptionContent] = useState({});
//     const descriptionElementRef = useRef(null);
//     useEffect(() => {
//         if (moreOptionToggle) {
//             const { current: descriptionElement } = descriptionElementRef;
//             if (descriptionElement !== null) {
//                 descriptionElement.focus();
//             }
//         }
//     }, [moreOptionToggle]);

//     const history = useHistory();

//     const onClickCreate = (event, activityType) => {
//         event.stopPropagation();
//         props.onClickCreate(activityType);
//     };

//     const quickActivityCreate = (activityType) => {
//         props.onClickQuickCreate(activityType);
//     };

//     const moreOptionAllContent = {
//         'BASIC_CODING': {
//             title: 'Basic Coding',
//             image: '/assets/images/moreSectionImages/Basic_Python.png',
//             description: 'Python is a computer programming language. The basic python coding environment that lets you work with blocks, text, or both. Basic python helps students to solve interesting and authentic problems of maths and science. Also with the help of python graphics students can make different types of graphic projects.',
//             moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
//             links: ''
//         },
//         'AI_CODING': {
//             title: 'AI Coding',
//             image: '/assets/images/moreSectionImages/AI_Real_World.png',
//             description: 'Artificial Intelligence (AI) is an umbrella term for computer software that mimics human cognition in order to perform complex tasks and learn from them. Machine learning (ML) is a subfield of AI that uses algorithms trained on data to produce adaptable models that can perform a variety of complex tasks. With the block, text, both helps students to understand the logic and fundamentals of AI & ML Coding.',
//             moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
//         },
//         'TINKER_CODING': {
//             title: 'Tinker Orbit Coding',
//             image: '/assets/images/moreSectionImages/Graphics_Designing.png',
//             description: 'Tinker Orbit is a STEAM & IoT based kit to explore the basic concepts of electronic circuit, sensors, logic building, programming and IoT with the help of plug & play modules. Students can learn serveral concepts through tinker orbit kit. With the help of block coding students can learn and make different logics.',
//             moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
//         },
//         'STEM_CODING': {
//             title: 'Stem Coding',
//             image: '/assets/images/moreSectionImages/Machine_Learning.png',
//             description: 'STEMBOT is a graphically and textually programmable STEM robotics educational kit, which inherits playability and simple operation on the micro: bit(v2). The robot has interesting features and is plug and play which allows students to quickly learn hardware and robotics concepts with graphic programming.',
//             moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
//         },
//         'MIT_Inventor': { 
//             title: 'MIT App Inventor',
//             image: '/assets/images/moreSectionImages/MIT_App_Inventor.png', // Adjust the image path as needed
//             description: 'MIT App Inventor provides an intuitive, visual programming environment that allows everyone to build fully functional apps for smartphones and tablets. This hands-on approach to app building empowers students to engage in coding and computational thinking.',
//             moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
//         },
//         'Blockly_Games': { 
//             title: 'Blockly Games',
//             image: '/assets/images/moreSectionImages/Blockly_Games.png', // Adjust the image path as needed
//             description: 'Blockly Games is a series of educational games that teach programming. It is designed for children who have not had prior experience with computer programming.',
//             moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
//         },
//         'Stem_Light': { 
//             title: 'Stem Light',
//             image: '/assets/images/moreSectionImages/Stem_Light.png', // Adjust the image path as needed
//             description: 'Lightbot is an educational video game for learning programming concepts the goal of Lightbot is to command a little robot to navigate a maze and turn on lights.',
//             moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
//         }

//     };

//     const openNewTabAndFocus = (url) => {
//         const newTab = window.open(url, '_blank');
//         if (newTab) {
//             newTab.focus();
//         } else {
//             // Handle if the browser blocks opening a new tab
//             console.error('Failed to open new tab');
//         }
//     };

//     const moreOptionDialog = (
//         <Dialog
//             open={moreOptionToggle}
//             onClose={() => setMoreOptionToggle(false)}
//             scroll="paper"
//             aria-labelledby="scroll-dialog-title"
//             aria-describedby="scroll-dialog-description"
//         >
//             <DialogTitle id="scroll-dialog-title">{moreOptionContent.title}</DialogTitle>
//             <DialogContent dividers={true}>
//                 <DialogContentText
//                     id="scroll-dialog-description"
//                     ref={descriptionElementRef}
//                     tabIndex={-1}
//                 >
//                     <div className='more-help-content'>
//                         <img className="more-option-img"
//                             src={process.env.PUBLIC_URL + moreOptionContent.image}
//                             alt={"temp"}
//                         />
//                     </div>
//                     <div>
//                         <p>{moreOptionContent.description}</p>
//                         <p>
//                             {moreOptionContent.moreInfo}<a target='_blank' href="https://www.stemrobo.com">(https://www.stemrobo.com).</a>
//                         </p>
//                     </div>
//                 </DialogContentText>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={() => { setMoreOptionToggle(false) }} color="primary">
//                     Close
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );

//     return (
//         <>
//             <div id="pro_cat_cards" className="d-flex flex-wrap justify-content-center">
//                 {moreOptionToggle ? moreOptionDialog : ''}
//                 {/* Existing Block Cards */}
//                 {/* Basic Coding */}
//                 <div className="my_card card card-bg-info-1 text-white" style={{ cursor: 'pointer' }} onClick={event => { quickActivityCreate(appData.activity.types.BC[0]) }}>
//                     <div className="card-body">
//                         <h4 className="card-title">Basic<br />Coding</h4>
//                         <p className="card-text little-desc-1">Let’s learn the basics to improve your Intelligence.</p>
//                         <div className="Buttons_create_more">
//                             <button id="create_bp" onClick={event => { onClickCreate(event, appData.activity.types.BC[0]) }} className="btn btn-outline-light">Create &gt;&gt;</button>
//                             <button id="more_ds" onClick={event => { event.stopPropagation(); setMoreOptionToggle(true); setMoreOptionContent(moreOptionAllContent['BASIC_CODING']) }} className="btn btn-outline-light">More &gt;&gt;</button>
//                         </div>
//                     </div>
//                 </div>

//                 {/* AI Coding */}
//                 <div className="my_card card">
//                     <div className="card-body card-bg-info-2 text-white" style={{ cursor: 'pointer' }} onClick={event => { quickActivityCreate(appData.activity.types.AC[0]) }}>
//                         <h4 className="card-title">AI<br />Coding</h4>
//                         <p className="card-text little-desc-1">Unlock the potential of AI through coding.</p>
//                         <div className="Buttons_create_more1">
//                             <button id="create_pg" onClick={event => { onClickCreate(event, appData.activity.types.AC[0]) }} className="btn btn-outline-light">Create &gt;&gt;</button>
//                             <button id="more_ds1" onClick={event => { event.stopPropagation(); setMoreOptionToggle(true); setMoreOptionContent(moreOptionAllContent['AI_CODING']) }} className="btn btn-outline-light">More &gt;&gt;</button>
//                         </div>
//                     </div>
//                 </div>

//                 {/* Tinker Orbit Coding */}
//                 <div className="my_card card">
//                     <div className="card-body card-bg-info-3 text-white" style={{ cursor: 'pointer' }} onClick={event => { quickActivityCreate(appData.activity.types.TC[0]) }}>
//                         <h4 className="card-title">Tinker Orbit<br />Coding</h4>
//                         <p className="card-text little-desc-1">Explore the possibilities of technology with Tinker Orbit.</p>
//                         <div className="Buttons_create_more2">
//                             <button id="create_pg" onClick={event => { onClickCreate(event, appData.activity.types.TC[0]) }} className="btn btn-outline-light">Create &gt;&gt;</button>
//                             <button id="more_ds2" onClick={event => { event.stopPropagation(); setMoreOptionToggle(true); setMoreOptionContent(moreOptionAllContent['TINKER_CODING']) }} className="btn btn-outline-light">More &gt;&gt;</button>
//                         </div>
//                     </div>
//                 </div>

//                 {/* STEMBOT Coding */}
//                 <div className="my_card card card-bg-info-4 text-white" style={{ cursor: 'pointer' }} onClick={() => history.push("/activity/scratch-coding")}>
//                     <div className="card-body">
//                         <h4 className="card-title">STEMBOT<br />Coding</h4>
//                         <p className="card-text little-desc-1">Unleashing Creativity through STEMBOT Coding.</p>
//                         <div className="Buttons_create_more3">
//                             <button id="create_ai" onClick={() => history.push("/activity/scratch-coding")} className="btn btn-outline-light">Create &gt;&gt;</button>
//                             <button id="more_ds" onClick={event => { event.stopPropagation(); setMoreOptionToggle(true); setMoreOptionContent(moreOptionAllContent['STEM_CODING']) }} className="btn btn-outline-light">More &gt;&gt;</button>
//                         </div>
//                     </div>
//                 </div>


// {/* New Block Card: STEM Light */}
// <div
//     className="my_card card card-bg-info-5 text-white"
//     style={{ cursor: 'pointer' }}
//     onClick={() => {
//         // Trigger the same functionality as the "Create" button
//         window.open(`https://staging.aiconnect.cc/lightbot/?t=${new Date().getTime()}`, "_blank");
//     }}
// >
//     <div className="card-body">
//         <h4 className="card-title">STEM<br />LIGHT</h4>
//         <p className="card-text little-desc-1">Explore STEM concepts in a lighter and easy way</p>
//         <div className="Buttons_create_more3">
//             <button
//                 id="create_ai"
//                 onClick={event => {
//                     event.stopPropagation(); // Prevent the click from affecting other elements
//                     window.open(`https://staging.aiconnect.cc/lightbot/?t=${new Date().getTime()}`, "_blank");
//                 }}
//                 className="btn btn-outline-light"
//             >
//                 Create &gt;&gt;
//             </button>
//             <button
//                 id="more_ds"
//                 onClick={event => {
//                     event.stopPropagation(); // Prevent propagation to the card
//                     setMoreOptionToggle(true);
//                     setMoreOptionContent(moreOptionAllContent['Stem_Light']);
//                 }}
//                 className="btn btn-outline-light"
//             >
//                 More &gt;&gt;
//             </button>
//         </div>
//     </div>
// </div>







// {/* MIT APP INVENTOR */}
// <div
//     className="my_card card card-bg-info-6 text-white"
//     style={{ cursor: 'pointer' }}
//     onClick={() => {
//         // Trigger the same functionality as the "Create" button
//         window.open(`https://code.appinventor.mit.edu/?t=${new Date().getTime()}`, "_blank");
//     }}
// >
//     <div className="card-body">
//         <h4 className="card-title">MIT APP<br />Inventor</h4>
//         <p className="card-text little-desc-1">Create Apps, Empower Innovation Easily using MIT App Inventor!!</p>
//         <div className="Buttons_create_more3">
//             <button
//                 id="create_ai"
//                 onClick={event => {
//                     event.stopPropagation(); // Prevent the click from affecting other elements
//                     window.open(`https://code.appinventor.mit.edu/?t=${new Date().getTime()}`, "_blank");
//                 }}
//                 className="btn btn-outline-light"
//             >
//                 Create &gt;&gt;
//             </button>
//             <button
//                 id="more_ds"
//                 onClick={event => {
//                     event.stopPropagation(); // Prevent propagation to the card
//                     setMoreOptionToggle(true);
//                     setMoreOptionContent(moreOptionAllContent['MIT_Inventor']);
//                 }}
//                 className="btn btn-outline-light"
//             >
//                 More &gt;&gt;
//             </button>
//         </div>
//     </div>
// </div>

// {/* Blockly Games */}
// <div
//     className="my_card card card-bg-info-7 text-white"
//     style={{ cursor: 'pointer' }}
//     onClick={() => {
//         // Trigger the same functionality as the "Create" button
//         window.open(`https://blockly.games/?t=${new Date().getTime()}`, "_blank");
//     }}
// >
//     <div className="card-body">
//         <h4 className="card-title">Blockly<br />Games</h4>
//         <p className="card-text little-desc-1">Code, Create, Learn, and Play using Google Blockly Games!!</p>
//         <div className="Buttons_create_more3">
//             <button
//                 id="create_ai"
//                 onClick={event => {
//                     event.stopPropagation(); // Prevent the click from affecting other elements
//                     window.open(`https://blockly.games/?t=${new Date().getTime()}`, "_blank");
//                 }}
//                 className="btn btn-outline-light"
//             >
//                 Create &gt;&gt;
//             </button>
//             <button
//                 id="more_ds"
//                 onClick={event => {
//                     event.stopPropagation(); // Prevent propagation to the card
//                     setMoreOptionToggle(true);
//                     setMoreOptionContent(moreOptionAllContent['Blockly_Games']);
//                 }}
//                 className="btn btn-outline-light"
//             >
//                 More &gt;&gt;
//             </button>
//         </div>
//     </div>
// </div>



// {/* Python Compiler */}
// <div className="my_card card card-bg-info-8 text-white" style={{ cursor: 'pointer' }}>
//     <div className="card-body">
//         <h4 className="card-title">Coming <br />Soon</h4>
//         <p className="card-text little-desc-1">Code to Create, Learn, Innovate using Textual Python Compiler!!</p>
//         <div className="Buttons_create_more3">
//             {/* Ensure nothing happens on the dashboard by preventing default and stopping propagation */}
//             <button 
//                 id="create_ai" 
//                 // onClick={event => {
//                 //     event.stopPropagation(); // Prevent the click from affecting other elements
//                 //     window.open(`https://code.appinventor.mit.edu/?t=${new Date().getTime()}`, "_blank"); // Open MIT in new tab
//                 // }} 
//                 className="btn btn-outline-light"
//             >
//                 Create &gt;&gt;
//             </button>
//             <button 
//                 id="more_ds" 
//                 // onClick={event => { 
//                 //     event.stopPropagation(); 
//                 //     setMoreOptionToggle(true); 
//                 //     setMoreOptionContent(moreOptionAllContent['Blockly_Games']); 
//                 // }} 
//                 className="btn btn-outline-light"
//             >
//                 More &gt;&gt;
//             </button>
//         </div>
//     </div>
// </div>





//             </div>
//         </>
//     );
// }





// This code is written with Button for going next-Ayushman

import React, { useState, useRef, useEffect } from 'react';
import { appData } from "../../../util/appData";
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ActivitiesIntroCardGrid(props) {
    const [moreOptionToggle, setMoreOptionToggle] = useState(false);
    const [moreOptionContent, setMoreOptionContent] = useState({});
    const [showExtraCards, setShowExtraCards] = useState(false); // State to toggle extra cards
    const descriptionElementRef = useRef(null);
    const history = useHistory();

    useEffect(() => {
        if (moreOptionToggle) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [moreOptionToggle]);

    const onClickCreate = (event, activityType) => {
        event.stopPropagation();
        props.onClickCreate(activityType);
    };

    const quickActivityCreate = (activityType) => {
        props.onClickQuickCreate(activityType);
    };

    const moreOptionAllContent = {
        'BASIC_CODING': {
            title: 'Basic Coding',
            image: '/assets/images/moreSectionImages/Basic_Python.png',
            description: 'Python is a computer programming language. The basic python coding environment that lets you work with blocks, text, or both. Basic python helps students to solve interesting and authentic problems of maths and science. Also with the help of python graphics students can make different types of graphic projects.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
            links: ''
        },
        'AI_CODING': {
            title: 'AI Coding',
            image: '/assets/images/moreSectionImages/AI_Real_World.png',
            description: 'Artificial Intelligence (AI) is an umbrella term for computer software that mimics human cognition in order to perform complex tasks and learn from them. Machine learning (ML) is a subfield of AI that uses algorithms trained on data to produce adaptable models that can perform a variety of complex tasks. With the block, text, both helps students to understand the logic and fundamentals of AI & ML Coding.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        },
        'TINKER_CODING': {
            title: 'Tinker Orbits Coding',
            image: '/assets/images/moreSectionImages/Graphics_Designing.png',
            description: 'Tinker Orbits is a STEAM & IoT based kit to explore the basic concepts of electronic circuit, sensors, logic building, programming and IoT with the help of plug & play modules. Students can learn several concepts through tinker orbit kit. With the help of block coding students can learn and make different logics.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        },
        'STEM_CODING': {
            title: 'Stem Coding',
            image: '/assets/images/moreSectionImages/Machine_Learning.png',
            description: 'STEMBOT is a graphically and textually programmable STEM robotics educational kit, which inherits playability and simple operation on the micro: bit(v2). The robot has interesting features and is plug and play which allows students to quickly learn hardware and robotics concepts with graphic programming.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        },
        'MIT_Inventor': { 
            title: 'MIT App Inventor',
            image: '/assets/images/moreSectionImages/MIT_App_Inventor.png', 
            description: 'MIT App Inventor provides an intuitive, visual programming environment that allows everyone to build fully functional apps for smartphones and tablets. This hands-on approach to app building empowers students to engage in coding and computational thinking.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        },
        'Blockly_Games': { 
            title: 'Blockly Games',
            image: '/assets/images/moreSectionImages/Blockly_Games.png',
            description: 'Blockly Games is a series of educational games that teach programming. It is designed for children who have not had prior experience with computer programming.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        },
        'Jupyter_Notebook': { 
            title: 'Jupyter Notebook',
            image: '/assets/images/moreSectionImages/Jupyter_logo.png',
            description: 'Jupyter Notebook is an interactive web-based environment that allows users to create and share documents containing live code.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        },
        'Stem_Light': { 
            title: 'Stem Light',
            image: '/assets/images/moreSectionImages/Stem_Light.png', 
            description: 'Lightbot is an educational video game for learning programming concepts the goal of Lightbot is to command a little robot to navigate a maze and turn on lights.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        },
        'Java_Compiler': { 
            title: 'Java Compiler',
            image: '/assets/images/moreSectionImages/Javanew.png', 
            description: 'JavaCompiler is a online compiler.A compiler transforms high-level language (source code) into machine language (object language)',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        },
        'Microsoft_Acrade': { 
            title: 'Microsoft Acrade',
            image: '/assets/images/moreSectionImages/logoacrade.png', 
            description: 'Microsoft MakeCode Arcade is a code editor to build Retro Arcade games for the browser and handheld consoles.',
            moreInfo: 'Contact For STEM Education & ATL Lab Setup, STEM Lab & Robotics Lab in schools ',
        }
    };

    const openNewTabAndFocus = (url) => {
        const newTab = window.open(url, '_blank');
        if (newTab) {
            newTab.focus();
        } else {
            // Handle if the browser blocks opening a new tab
            console.error('Failed to open new tab');
        }
    };

    const moreOptionDialog = (
        <Dialog
            open={moreOptionToggle}
            onClose={() => setMoreOptionToggle(false)}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{moreOptionContent.title}</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <div className='more-help-content'>
                        <img className="more-option-img"
                            src={process.env.PUBLIC_URL + moreOptionContent.image}
                            alt={"temp"}
                        />
                    </div>
                    <div>
                        <p>{moreOptionContent.description}</p>
                        <p>
                            {moreOptionContent.moreInfo}<a target='_blank' rel="noopener noreferrer" href="https://www.stemrobo.com">(https://www.stemrobo.com).</a>
                        </p>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setMoreOptionToggle(false) }} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Extra Cards (9 and 10)
    const extraCards = [
        {
            id: 'Extra9',
            title: 'Extra Card 9',
            description: 'Description for extra card 9...',
            onClick: () => alert('Extra Card 9 Clicked!'),
        },
        {
            id: 'Extra10',
            title: 'Extra Card 10',
            description: 'Description for extra card 10...',
            onClick: () => alert('Extra Card 10 Clicked!'),
        },
    ];

    return (
        <>
            <div id="pro_cat_cards" className="d-flex flex-wrap justify-content-center">
                {moreOptionToggle ? moreOptionDialog : ''}
                {/* Existing Block Cards */}
                {/* Basic Coding */}
                <div className="my_card card card-bg-info-1 text-white" style={{ cursor: 'pointer' }} onClick={() => quickActivityCreate(appData.activity.types.BC[0])}>
                    <div className="card-body">
                        <h4 className="card-title">Basic<br />Coding</h4>
                        <p className="card-text little-desc-1">Let's learn the basics of Python to improve your intelligence.</p>
                        <div className="Buttons_create_more">
                            <button id="create_bp" onClick={(e) => onClickCreate(e, appData.activity.types.BC[0])} className="btn btn-outline-light">Create &gt;&gt;</button>
                            <button id="more_ds" onClick={(e) => { 
                                e.stopPropagation(); 
                                setMoreOptionToggle(true); 
                                setMoreOptionContent(moreOptionAllContent['BASIC_CODING']); 
                            }} className="btn btn-outline-light">More &gt;&gt;</button>
                        </div>
                    </div>
                </div>

                {/* AI Coding */}
                <div className="my_card card">
                    <div className="card-body card-bg-info-2 text-white" style={{ cursor: 'pointer' }} onClick={() => quickActivityCreate(appData.activity.types.AC[0])}>
                        <h4 className="card-title">AI<br />Coding</h4>
                        <p className="card-text little-desc-1">Unlock the potential of ML and AI through coding.</p>
                        <div className="Buttons_create_more1">
                            <button id="create_pg" onClick={(e) => onClickCreate(e, appData.activity.types.AC[0])} className="btn btn-outline-light">Create &gt;&gt;</button>
                            <button id="more_ds1" onClick={(e) => { 
                                e.stopPropagation(); 
                                setMoreOptionToggle(true); 
                                setMoreOptionContent(moreOptionAllContent['AI_CODING']); 
                            }} className="btn btn-outline-light">More &gt;&gt;</button>
                        </div>
                    </div>
                </div>

                {/* Tinker Orbits Coding */}
                <div className="my_card card">
                    <div className="card-body card-bg-info-3 text-white" style={{ cursor: 'pointer' }} onClick={() => quickActivityCreate(appData.activity.types.TC[0])}>
                        <h4 className="card-title">Tinker Orbits<br />Coding</h4>
                        <p className="card-text little-desc-1">Explore the possibilities of technology with Tinker Orbits.</p>
                        <div className="Buttons_create_more2">
                            <button id="create_tc" onClick={(e) => onClickCreate(e, appData.activity.types.TC[0])} className="btn btn-outline-light">Create &gt;&gt;</button>
                            <button id="more_ds2" onClick={(e) => { 
                                e.stopPropagation(); 
                                setMoreOptionToggle(true); 
                                setMoreOptionContent(moreOptionAllContent['TINKER_CODING']); 
                            }} className="btn btn-outline-light">More &gt;&gt;</button>
                        </div>
                    </div>
                </div>

                {/* STEMBOT Coding */}
                <div className="my_card card card-bg-info-4 text-white" style={{ cursor: 'pointer' }} onClick={() => history.push("/activity/scratch-coding")}>
                    <div className="card-body">
                        <h4 className="card-title">STEMBOT<br />Coding</h4>
                        <p className="card-text little-desc-1">Unleashing the concept of NLP, ML and AI using STEMBOT.</p>
                        <div className="Buttons_create_more3">
                            <button id="create_stem" onClick={() => history.push("/activity/scratch-coding")} className="btn btn-outline-light">Create &gt;&gt;</button>
                            <button id="more_ds3" onClick={(e) => { 
                                e.stopPropagation(); 
                                setMoreOptionToggle(true); 
                                setMoreOptionContent(moreOptionAllContent['STEM_CODING']); 
                            }} className="btn btn-outline-light">More &gt;&gt;</button>
                        </div>
                    </div>
                </div>

                {/* New Block Card: STEM Light */}
<div
    className="my_card card card-bg-info-5 text-white"
    style={{ cursor: 'pointer' }}
    onClick={() => {
        // Trigger the same functionality as the "Create" button
        window.open(`https://aiconnect.cc/lightbot/?t=${new Date().getTime()}`, "_blank");
    }}
>
    <div className="card-body">
        <h4 className="card-title">STEM<br />LIGHT</h4>
        <p className="card-text little-desc-1">Explore STEM concepts in a lighter and easy way.</p>
        <div className="Buttons_create_more3">
            <button
                id="create_ai"
                onClick={event => {
                    event.stopPropagation(); // Prevent the click from affecting other elements
                    window.open(`https://aiconnect.cc/lightbot/?t=${new Date().getTime()}`, "_blank");
                }}
                className="btn btn-outline-light"
            >
                Create &gt;&gt;
            </button>
            <button
                id="more_ds"
                onClick={event => {
                    event.stopPropagation(); // Prevent propagation to the card
                    setMoreOptionToggle(true);
                    setMoreOptionContent(moreOptionAllContent['Stem_Light']);
                }}
                className="btn btn-outline-light"
            >
                More &gt;&gt;
            </button>
        </div>
    </div>
</div>







{/* MIT APP INVENTOR */}
<div
    className="my_card card card-bg-info-6 text-white"
    style={{ cursor: 'pointer' }}
    onClick={() => {
        // Trigger the same functionality as the "Create" button
        window.open(`https://code.appinventor.mit.edu/?t=${new Date().getTime()}`, "_blank");
    }}
>
    <div className="card-body">
        <h4 className="card-title">MIT APP<br />Inventor</h4>
        <p className="card-text little-desc-1">Create apps, empower innovation using MIT App Inventor.</p>
        <div className="Buttons_create_more3">
            <button
                id="create_ai"
                onClick={event => {
                    event.stopPropagation(); // Prevent the click from affecting other elements
                    window.open(`https://code.appinventor.mit.edu/?t=${new Date().getTime()}`, "_blank");
                }}
                className="btn btn-outline-light"
            >
                Create &gt;&gt;
            </button>
            <button
                id="more_ds"
                onClick={event => {
                    event.stopPropagation(); // Prevent propagation to the card
                    setMoreOptionToggle(true);
                    setMoreOptionContent(moreOptionAllContent['MIT_Inventor']);
                }}
                className="btn btn-outline-light"
            >
                More &gt;&gt;
            </button>
        </div>
    </div>
</div>

{/* Blockly Games */}
<div
    className="my_card card card-bg-info-7 text-white"
    style={{ cursor: 'pointer' }}
    onClick={() => {
        // Trigger the same functionality as the "Create" button
        window.open(`https://blockly.games/?t=${new Date().getTime()}`, "_blank");
    }}
>
    <div className="card-body">
        <h4 className="card-title">Blockly<br />Games</h4>
        <p className="card-text little-desc-1">Code, Create, Learn, and Play using Google Blockly Games.</p>
        <div className="Buttons_create_more3">
            <button
                id="create_ai"
                onClick={event => {
                    event.stopPropagation(); // Prevent the click from affecting other elements
                    window.open(`https://blockly.games/?t=${new Date().getTime()}`, "_blank");
                }}
                className="btn btn-outline-light"
            >
                Create &gt;&gt;
            </button>
            <button
                id="more_ds"
                onClick={event => {
                    event.stopPropagation(); // Prevent propagation to the card
                    setMoreOptionToggle(true);
                    setMoreOptionContent(moreOptionAllContent['Blockly_Games']);
                }}
                className="btn btn-outline-light"
            >
                More &gt;&gt;
            </button>
        </div>
    </div>
</div>

{/* Python Compiler */} 
<div
    className="my_card card card-bg-info-8 text-white"
    style={{ cursor: 'pointer' }}
    onClick={() => {
        // Redirect to Python Compiler URL when clicking anywhere on the card
        const url = "https://notebook.stemrobo.com/login?token=e83cd72ed1053eeb6e3d0ff734791a7045b8a2b31d90f6ba"; // Direct URL
        window.open(url, "_blank"); // Open in new tab
    }}
>
    <div className="card-body">
        <h4 className="card-title">Jupyter<br />Notebook</h4>
        <p className="card-text little-desc-1">Code to Create, Learn, Innovate using Jupyter Notebook.</p>
        <div className="Buttons_create_more3">
            <button 
                id="create_python" 
                onClick={event => {
                    event.stopPropagation(); // Prevent the click from affecting other elements
                    const url = "https://notebook.stemrobo.com/login?token=e83cd72ed1053eeb6e3d0ff734791a7045b8a2b31d90f6ba"; // Direct URL
                    window.open(url, "_blank"); 
                }} 
                className="btn btn-outline-light"
            >
                Create &gt;&gt;
            </button>
            <button 
                id="more_ds_python" 
                onClick={event => { 
                    event.stopPropagation(); // Prevent propagation to the card
                    setMoreOptionToggle(true); 
                    setMoreOptionContent(moreOptionAllContent['Jupyter_Notebook']); 
                }} 
                className="btn btn-outline-light"
            >
                More &gt;&gt;
            </button>
        </div>
    </div>
</div>



                {/* Render Extra Cards if `showExtraCards` is true */}
                {showExtraCards && (
                    <>
                {/* Extra Activity Card 9 */}
<div
    className="my_card card card-bg-info-7 text-white"
    style={{ cursor: 'pointer' }}
    onClick={() => {
        // Trigger the same functionality as the "Create" button
        window.open(`https://onecompiler.com/java/?t=${new Date().getTime()}`, "_blank");
    }}
>
    <div className="card-body">
        <h4 className="card-title">Java<br />Compiler</h4>
        <p className="card-text little-desc-1">Code, Compile, Conquer: Learn Java the Smart Way.</p>
        <div className="Buttons_create_more3">
            <button
                id="create_blockly"
                onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from affecting other elements
                    window.open(`https://onecompiler.com/java/?t=${new Date().getTime()}`, "_blank");
                }}
                className="btn btn-outline-light"
            >
                Create &gt;&gt;
            </button>
            <button
                id="more_ds_blockly"
                onClick={(e) => {
                    e.stopPropagation(); // Prevent propagation to the card
                    setMoreOptionToggle(true);
                    setMoreOptionContent(moreOptionAllContent['Java_Compiler']);
                }}
                className="btn btn-outline-light"
            >
                More &gt;&gt;
            </button>
        </div>
    </div>
</div>

{/* Make Code Arcade */}
<div
    className="my_card card card-bg-info-6 text-white"
    style={{ cursor: 'pointer' }}
    onClick={() => {
        // Trigger the same functionality as the "Create" button
        window.open(`https://arcade.makecode.com/?t=${new Date().getTime()}`, "_blank");
    }}
>
    <div className="card-body">
        <h4 className="card-title">Make Code<br />Arcade</h4>
        <p className="card-text little-desc-1">Transform Ideas into Games with MakeCode Arcade.</p>
        <div className="Buttons_create_more3">
            <button
                id="create_ai"
                onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from affecting other elements
                    window.open(`https://arcade.makecode.com/?t=${new Date().getTime()}`, "_blank");
                }}
                className="btn btn-outline-light"
            >
                Create &gt;&gt;
            </button>
            <button
                id="more_ds_ai"
                onClick={(e) => {
                    e.stopPropagation(); // Prevent propagation to the card
                    setMoreOptionToggle(true);
                    setMoreOptionContent(moreOptionAllContent['Microsoft_Acrade']);
                }}
                className="btn btn-outline-light"
            >
                More &gt;&gt;
            </button>
        </div>
    </div>
</div>


                       
                    </>
                )}
            </div>

            {/* Next Arrow Button */}
            <div className="d-flex justify-content-center mt-3">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowExtraCards(!showExtraCards)}
                >
                    {showExtraCards ? 'Hide' : 'More'}
                </Button>
            </div>
        </>
    );
}

