// Common:
// access e.message
export function KnownError(message,cause=null) {
    const error = new Error(message);
    error.type = KnownError
    error.cause = cause
    return error;
}
KnownError.prototype = Object.create(Error.prototype);



export function ValidationFail(message) {
    const error = new Error(message);
    error.type = ValidationFail
    return error;
}
ValidationFail.prototype = Object.create(Error.prototype);



// application specific Error:
export function ServerError(serverErrResponse) {

    function getServerErrorName() {
        try {
            const errText = serverErrResponse
            const errorName = errText.split('\n')[0]    // when server throw error, first line contains the error name
            return errorName
        } catch (e) {
            return "Unknown Server error."+e.toString()
        }
    }

    const error = new Error(getServerErrorName());
    error.type = ServerError
    return error;
}
ServerError.prototype = Object.create(Error.prototype);