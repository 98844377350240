import React from 'react'
import {CardColumns} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Link from "@material-ui/core/Link";
import {useMLTrainingOps} from "../../../../hooks/useMLTrainingOps";
import {itrOnObjKeys} from "../../../../../lib/js/jsUtil";
import {multiValueReceiveDialogOps} from "./dialogs/MultiValueReceiveDialog";

// manage number recognition labels:
export default function NumberRecognitionLabels(props) {

    // mltrain related ops
    const mlTrainOps = useMLTrainingOps(props)

    return (
        <>
            {/* show labels in column */}
            <CardColumns>
                {/*Labels goes here  */}
                {/* for each label */}
                {itrOnObjKeys(mlTrainOps.getTrainingJsonContent().labels, (labelId, labelObj, i) => {
                    return (
                        <Card>
                            <div className="my-card">
                                <div className="card-header text-center">
                                    <span onClick={event => {
                                        mlTrainOps.updateLabelName(labelObj.name, (clone, newValue) => {
                                            //debugger
                                            clone.labels[labelId].name = newValue;
                                        })
                                    }}
                                    >{labelObj.name}</span>
                                    <Link className="deletetext delete" onClick={() => {
                                        // on click delete label, remove label from json:
                                        mlTrainOps.deleteLabel(labelId)
                                    }}><i className="far fa-window-close"/></Link>
                                </div>
                                <div className="card-body trainingbucketitems">

                                    {labelObj.values.map((value, valuesIdx) => {
                                        return <div className="trainingbucketitem">
                                            <Link className="deletetext delete" onClick={() => {
                                                mlTrainOps.deleteLabelValue(labelId, valuesIdx);
                                            }}><i className="far fa-window-close"/></Link>
                                            <table>
                                                <tbody>
                                                {value.value.map((value, idx) => {
                                                    return <tr onClick={event => {
                                                        // on click label value, show update value dialog:
                                                        props.inpDiag.receiveInput({
                                                            onReceive(newValue) {
                                                                // update value to new value:
                                                                mlTrainOps.updateLabelDataNumericValue(labelId, valuesIdx, idx, newValue)
                                                            },
                                                            title: "Update " + value.name,
                                                            inputType: "number",
                                                            inputLabel: "Provide updated value",
                                                            value: value.value,
                                                            okBtnText: "Update"
                                                        })
                                                    }}>
                                                        <td className="numberdatalabel">{value.name}</td>
                                                        <td className="numberdatavalue">{value.value}</td>
                                                    </tr>
                                                })}
                                                </tbody>

                                            </table>
                                            <hr/>
                                        </div>
                                    })}

                                </div>
                                <div className="row justify-content-center my-3">
                                    <button className="btn btn-info" onClick={event => {
                                        // on click Add Values button: show multi value receive dialog:
                                        multiValueReceiveDialogOps.receiveInput({
                                            onReceive(valueArr) {
                                                // on receive add provided values in json object, and re-render
                                                mlTrainOps.addNumericLabelValues(labelId, valueArr)
                                            },
                                            inputType: "number",
                                            labelName: labelObj.name,
                                            fieldNames: mlTrainOps.numericLabelValueNames()
                                        })
                                    }}>Add Values
                                    </button>
                                </div>
                            </div>
                        </Card>
                    )
                })}
            </CardColumns>
        </>
    )
}