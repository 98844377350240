import { useRef, useState} from 'react'
import useActivityCommonWorkspace from "./common/useActivityCommonWorkspace";
import CaptureImageDialog from "./dialogs/CaptureImageDialog";
import useCustomActivityOps from "../../../hooks/useCustomActivityOps";
// import {cloneElement} from "../../../../lib/js/jsUtil";
import ShowProcessedImageDialog from "./dialogs/ShowProcessedImageDialog";
import GridDialog from "./dialogs/GridDialog";
// import {usePersistedJsonState} from "../../../../lib/hooks/StorageHooks";
import React from 'react';

export default function ActivityAC(props) {
    // maintain visibility of capture dialog:
    const [showCaptureImageDialog, set_showCaptureImageDialog] = useState(false);
    // maintain visibility of grid dialog:
    const [showGridImageDialog, set_showGridImageDialog] = useState(false);

    // activity content related operations:
    const actOps = useCustomActivityOps(props)

    // read user selected file:
    const inputFile = useRef(null)

    // setup this activity related buttons:
    function setupCustomActivityWorkspace(iframe) {
        const frm_window = iframe.contentWindow
        const frm_document = iframe.contentDocument

        // train button will takes us to given url:
        // cloneElement(frm_document.getElementById("btn-train")).addEventListener("click", function () {
        //     window.open("https://teachablemachine.withgoogle.com/train/image","_blank")
        // })

        // upload button invoke file upload, see onSelectFile function
        // cloneElement(frm_document.getElementById("btn-upload")).addEventListener("click", function () {
        //     inputFile.current.click();
        // })

        // capture button will show capture dialog:
        // cloneElement(frm_document.getElementById("btn-re-capture")).addEventListener("click", function () {
        //     set_showCaptureImageDialog(true)
        // })

        // sprite button will show sprite images grid dialog
        // cloneElement(frm_document.getElementById("btn-sprite")).addEventListener("click", function () {
        //     set_showGridImageDialog(true)
        // })

        // run button will run the code on captured image:
        // cloneElement(frm_document.getElementById("btn-re-runs")).addEventListener("click", function () {
        //     const activityContent = actOps.getUserSelectedActivityContent()
        //     if(activityContent.captureImageUrlPath!==null) {
        //         const code = frm_window.blockpy.components.editor.generateCode();
        //         const imagePath = actOps.getUserSelectedActivityContent().captureImageUrlPath
        //         const modelDir = actOps.getModelFileLocation(props)
        //         actOps.runAiCodeOnImage(modelDir,code,imagePath, processedImage => processedImageDialog.showProcessedImage(processedImage))
        //     } else {
        //         props.toastError("No image to work with. Capture & upload an image.")
        //     }
        // })

    }

    // selects the zip file only, and upload the zip file on to the server:
    // function onSelectFile(event){
    //     if(event.target.files.length>0) {
    //         const file = event.target.files[0];
    //         if( !file.type.toLowerCase().includes("zip") || file.size>(2026697*6)) {   // 2MB * 6
    //             props.toastWarning("Only zip files (approx 10MB) can be uploaded.")
    //         } else {
    //             const directory = actOps.getModelFileLocation(props)
    //             actOps.uploadAITrainModelZipFile(file,directory)
    //         }
    //         inputFile.current.value=""  // clear, last selected file
    //     }
    // }

    const {
        getBlocklyWorkspaceFrame    // need this function to show activity html file in.
    } = useActivityCommonWorkspace(props,setupCustomActivityWorkspace)



    return (
        <>
            {/* used to upload zip file */}
            {/* <input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept="zip" onChange={onSelectFile}/> */}
            {/* Show Image capture dialog */}
            <CaptureImageDialog {...props} {...{showCaptureImageDialog,set_showCaptureImageDialog}} />
            {/* Show Sprite Images Grid dialog */}
            <GridDialog {...props} {...{showGridImageDialog,set_showGridImageDialog}} />
            {/* Show processed image output in dialog. */}
            <ShowProcessedImageDialog />
            {/* Loads the html page in iframe */}
            {getBlocklyWorkspaceFrame("ai_coding.html")}
        </>
    )
}
