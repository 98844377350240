import { useEffect } from 'react'
import "../css/UserActivity.css"
import {useHistory} from "react-router-dom";
import useCustomActivityOps from "../../hooks/useCustomActivityOps";
import {appData} from "../../util/appData";
import {KnownError} from "../../../lib/js/Exceptions";
import ActivityBP from "./activityTypes/ActivityBP";
import ActivityAI from "./activityTypes/ActivityAI";
import ActivityML from "./activityTypes/ActivityML";
import ActivityGD from "./activityTypes/ActivityGD";
import ActivityBC from "./activityTypes/ActivityBC";
import ActivityAC from "./activityTypes/ActivityAC";
import ActivityTC from "./activityTypes/ActivityTC";
import ActivitySC from './activityTypes/ActivitySC';
import useHeaderOps from "../../hooks/useHeaderOps";
import React from 'react';


export default function UserActivity(props) {
    // for navigation between pages:
    const history = useHistory()
    // to do custom activity operations
    const actOps = useCustomActivityOps(props)
    // to do header operations
    const headerOps = useHeaderOps(props)

    // on page load for the first time:
    useEffect(()=>{

        // setup header sub-heading:
        switch(actOps.getSelectedActivityType()) {
            case appData.activity.typesOld.AI[0] :
                headerOps.setUserContentSubHeader("AI Real World")
                break;
            case appData.activity.typesOld.ML[0] :
                headerOps.setUserContentSubHeader("Machine Learning")
                break;
            case appData.activity.typesOld.GD[0] :
                headerOps.setUserContentSubHeader("Graphic Design")
                break;
            case appData.activity.types.BC[0] :
                headerOps.setUserContentSubHeader("Basic Coding")
                break;
            case appData.activity.types.AC[0] :
                headerOps.setUserContentSubHeader("AI Coding")
                break;
            case appData.activity.types.TC[0] :
                headerOps.setUserContentSubHeader("Tinker Orbits Coding")
                break;
            case appData.activity.types.SC[0]:
                headerOps.setUserContentSubHeader("STEMBOT Coding");
                break;        
            default: //case appData.activity.types.BP[0] :
                headerOps.setUserContentSubHeader("Basic Coding")
        }

        // add custom 'activity' css class to body:
        document.body.classList.add("activity")
        return ()=>{
            // tear down
            document.body.classList.remove("activity")
        }
    },[])
    // select which activity to show based on used selected activity type:
    function getActivityWorkspace() {
        switch(actOps.getSelectedActivityType()) {
            case appData.activity.typesOld.AI[0] :
                return <ActivityAI {...props} />
            case appData.activity.typesOld.ML[0] :
                return <ActivityML {...props} />
            case appData.activity.typesOld.GD[0] :
                return <ActivityGD {...props} />
            case appData.activity.typesOld.BP[0] :
                return <ActivityBP {...props} />
            case appData.activity.types.BC[0] :
                return <ActivityBC {...props} />
            case appData.activity.types.AC[0] :
                return <ActivityAC {...props} />
            case appData.activity.types.SC[0]: // Add SC (Stembot Coding) activity
                return <ActivitySC {...props} />;     
            case appData.activity.types.TC[0] :
                return <ActivityTC {...props} />
            default: throw KnownError("ActivityError")
        }
    }

    // returns the selected activity tag
    return getActivityWorkspace()
}