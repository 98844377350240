import React, {useState} from 'react'
import useActivityCommonWorkspaceTC from "./common/useActivityCommonWorkspaceTC";
import {cloneElement} from "../../../../lib/js/jsUtil";
import GridDialog from "./dialogs/GridDialog";
import ShowProcessedImageDialog from "./dialogs/ShowProcessedImageDialog";

export default function ActivityTC(props) {
    // set custom BP specific buttons:
    const [showGridImageDialog, set_showGridImageDialog] = useState(false);
    function setupCustomActivityWorkspace(iframe) {
        // Basic Python don't have specific buttons to set. It only
        // uses the common buttons defined in 'useActivityCommonWorkspace' file.
        const frm_document = iframe.contentDocument
        cloneElement(frm_document.getElementById("btn-sprite")).addEventListener("click", function () {
            set_showGridImageDialog(true)
        })
    }

    const {    
        // get a function from common functionality
        getBlocklyWorkspaceFrameTC    // use to load html page in iframe
        // to load the IFrame
    } = useActivityCommonWorkspaceTC(props,setupCustomActivityWorkspace)

    // loads the provided html file in IFrame
    return (<>
        {/* Show Sprite Images Grid dialog */}
        <GridDialog {...props} {...{showGridImageDialog,set_showGridImageDialog}} />
        {/* Show processed image output in dialog. */}
        <ShowProcessedImageDialog />
        {/* Loads the html page in iframe */}
        {getBlocklyWorkspaceFrameTC("index.html")}
    </>)
}
