import React, {useEffect, useState} from 'react'
import useActivityCommonWorkspace from "./common/useActivityCommonWorkspace";
import {cloneElement} from "../../../../lib/js/jsUtil";
import {useHistory} from "react-router-dom";
import GridDialog from "./dialogs/GridDialog";
import ShowProcessedImageDialog from "./dialogs/ShowProcessedImageDialog";

export default function ActivityML(props) {
    // used in page redirection
    const history = useHistory()
    // used to maintain iFrame state, whether it's loaded or not.
    const [iframe,set_iframe] = useState(null)
    const [showGridImageDialog, set_showGridImageDialog] = useState(false);
    // setup this activity related stuff
    function setupCustomActivityWorkspace(iframe) {
        set_iframe(iframe)

        // setup buttons:
        const frm_window = iframe.contentWindow
        const frm_document = iframe.contentDocument

        cloneElement(frm_document.getElementById("btn-sprite")).addEventListener("click", function () {
            set_showGridImageDialog(true)
        })
        // train button will takes us to given url:
        cloneElement(frm_document.getElementById("btn-train")).addEventListener("click", function () {
            window.open("https://teachablemachine.withgoogle.com/train/image","_blank")
        })
        // retrain button takes us to ml-model-training page
        // cloneElement(frm_document.getElementById("btn-re-train")).addEventListener("click", function () {
        //     history.push("/activity/ml-model-training")
        // })

    }

    const {
        getActivityIFrame,  // used to read IFrame from activity
        getBlocklyWorkspaceFrame    // use to load html page in iframe
    } = useActivityCommonWorkspace(props,setupCustomActivityWorkspace)


    useEffect(()=>{
        if(iframe!=null) {
            // retrieve iFrame from common activity function
            const iframe = getActivityIFrame()
            // read blockly blocks content:
            const content = props.userSelectedActivityContent.config
            // give dynamic blocks json to blockly page

            //For the dynamic block creation uncomment the below line
            // iframe.contentWindow.createDynamicBlocksFromJson(content)
        }
        // whenever iframe or activity content changes, we update the blockly blocks
    },[iframe, props.userSelectedActivityContent])

    // load the ML html page in iFrame
    return (<>

        {/* Show Sprite Images Grid dialog */}
        <GridDialog {...props} {...{showGridImageDialog,set_showGridImageDialog}} />
        {/* Show processed image output in dialog. */}
        <ShowProcessedImageDialog />
        {/* Loads the html page in iframe */}
        {getBlocklyWorkspaceFrame("machine_learning.html")}
    </>)
}
