// Header.js
import React from 'react';
import './css/Header.css';
import { Link, useHistory } from 'react-router-dom';
import useLoginOps from '../hooks/useLoginOps';
import useHeaderOps from '../hooks/useHeaderOps';

export default function Header(props) {
    const history = useHistory();
    const headerOps = useHeaderOps(props);
    const loginOps = useLoginOps(props);
    const header = props.header;

    if (header.isFreeActivity) {
        // returns the free activity Header:
        return (
            <header className="header-bg"> {/* Add header background class here */}
                <nav className="navbar navbar-expand-sm navbar-dark bg-info p-0">
                    <div className="container">
                        <Link className="navbar-brand mont_txt_bold heading"> AI Connect <span style={{ fontSize: '1.2rem' }}>({header.subHeading})</span></Link>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto mont_txt_bold">
                                <li className="nav-item mr-3">
                                    <Link className="nav-link">
                                        <p className="d-inline" onClick={event => {
                                            //gotoLoginPage
                                            headerOps.clearHeader();
                                        }}>Login</p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }

    if (!loginOps.isLoggedIn()) {
        logout();
    }

    const userEmailId = loginOps.userEmailId();

    function logout() {
        // do the logout
        props.logout();  // call logout in parent to clear the data.
        history.push('/login');  // redirect to login page.
    }

    return (
        <header className="header-bg"> {/* Add header background class here */}
            <nav className="navbar navbar-expand-sm navbar-dark navbar-bg-info p-0">
                <div className="container">
                    {/* If subheading is null or empty, don't show sub-heading section. */}
                    <Link to="/dashboard" className="navbar-brand mont_txt_bold heading"> AI Connect | {header.subHeading === null || header.subHeading === '' ? ('') : (<span style={{ fontSize: '1.2rem' }}> {header.subHeading}</span>)}</Link>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto mont_txt_bold">
                            <li className="nav-item dropdown mr-3">
                                <Link className="nav-link dropdown-toggle" data-toggle="dropdown">
                                    <i className="fas fa-user"></i>  <p className="d-inline">{userEmailId}</p>
                                </Link>
                                <div className="dropdown-menu">
                                    <button id="logout" className="dropdown-item bg-light" onClick={event => { // onClick function executes when Logout is clicked.
                                        logout();    // call logout function
                                        props.toastInfo('Logged out.');  // show logout toast on click logout
                                    }}>
                                        <i className="fas fa-sign-out-alt"></i> Logout
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}
