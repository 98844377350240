import { useState } from 'react'
import { Breadcrumb } from "react-bootstrap";
import useProjectAndActivityOps from "../../../hooks/useProjectAndActivityOps";
import { dateToReadableTime } from "../../../../lib/js/jsUtil";
// import { useHistory } from "react-router-dom";
import useCustomActivityOps from "../../../hooks/useCustomActivityOps";
import useAiConnectRestApi from "../../../hooks/useAiConnectRestApi";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import JSZip from 'jszip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';





// sets default project, handle delete project, activity,
export default function UserProjectsTable(props) {
    const actOps = useCustomActivityOps(props)
    const restApi = useAiConnectRestApi(props)
    const projActOps = useProjectAndActivityOps(props)

    // clear search function
    function clearSearch() {
        props.set_userProjectsSearchTermState("")
    }

    // onclick project text (breadcrumb)
    function onClickProjectInBreadcrumb() {
        clearSearch()
        projActOps.setSelectedProject(null)
        projActOps.setSelectedActivity(null)
    }
    // onclick examples text (breadcrumb)
    function onClickExamplesInBreadcrumb() {
        props.set_selectedTutorial(null)
    }

    // onclick project
    function onClickProject(project) {
        projActOps.setSelectedProject(project)

        if (projActOps.countProjectActivities(project.project_uid) === 0) {
            props.toastInfo("No Activity Found")
        } else {
            clearSearch()
        }
    }

    // breadCrumbs for Projects

    function getBreadCrumbs() {
        return props.userSelectedProject === null ? (
            // <Breadcrumb className="mt-5">
            <Breadcrumb>
                <Breadcrumb.Item active>Projects</Breadcrumb.Item>
            </Breadcrumb>
        ) : (
            // <Breadcrumb className="mt-5">
            <Breadcrumb>
                <Breadcrumb.Item href="javascript:;" onClick={onClickProjectInBreadcrumb}>
                 Back to Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{props.userSelectedProject.project_name}</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    // breadCrumbs from Examples
    function getBreadCrumbsTutorials() {
        return props.selectedTutorial === null ? (
            <Breadcrumb>
                <Breadcrumb.Item active>Tutorials</Breadcrumb.Item>
            </Breadcrumb>
        ) : (
            <Breadcrumb>
                <Breadcrumb.Item href="javascript:;" onClick={onClickExamplesInBreadcrumb}>
                    Tutorials
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{props.selectedTutorial.name}</Breadcrumb.Item>
            </Breadcrumb>
        )
    }

    // Tab switch component
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    // (part of tab switch)
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    // (part of tab switch)
    function a11yProps(index) {
        return {
            id: `nav-tab-${index}`,
            'aria-controls': `nav-tabpanel-${index}`,
        };
    }
    // tab switch link (part of tab switch)
    function LinkTab(props) {
        return (
            <Tab
                component="a"
                onClick={(event) => {
                    event.preventDefault();
                }}
                {...props}
            />
        );
    }
    // style for tab (projects, tutorials and examples) (part of tab switch)
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            marginTop: "2rem",
        },
        a: {
            '&:hover': {
                color: "white",
                textDecoration: "unset"
            }
        },
        header: {
            backgroundColor: "#2F3F89"
        },
        indicator: {
            backgroundColor: '#1890ff',
        },
    }));

    const classes = useStyles(); // (part of tab switch)

    // tab component to switch b/w projects, examples or tutorials
    function commonActivityTabs() {
        // TabSwitchHandleChange (part of tab switch)
        const TabSwitchHandleChange = (event, newValue) => {
            props.set_tabSwitch(newValue);
        };
        return (
            <div className={classes.root}>
                <AppBar position="static" className={classes.header}>
                    <Tabs
                        variant="fullWidth"
                        value={props.tabSwitch}
                        onChange={TabSwitchHandleChange}
                        aria-label="nav tabs example"
                    >
                        {/* No need of href  so we can remove*/}
                        <LinkTab className={classes.a} label="Projects" href="/drafts" {...a11yProps(0)} />
                        <LinkTab className={classes.a} label="Tutorials" href="/trash" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel className="tabPanel" value={props.tabSwitch} index={0}>
                    {getBreadCrumbs()}
                    {getProjectTable()}
                    {getActivityTable()}
                </TabPanel>
                <TabPanel className="tabPanel" value={props.tabSwitch} index={1}>
                    {getBreadCrumbsTutorials()}
                    {getTutorialsTable()}
                    {getTutorialsActivityTable()}
                </TabPanel>
            </div>
        )
    }

    // project table component
    function getProjectTable() {
        const searchTerm = props.userProjectsSearchTermState.trim().toLowerCase()
        const searchedProjects = searchTerm === "" ? projActOps.projects() : projActOps.projects().filter(project => project.project_name.toLowerCase().includes(searchTerm))

        // function to get the workspace content from api
        var getActivityWorkspaceContent = function (activityContentDocId, defaultActivityContent) {
            return new Promise(function (resolve, reject) {
                restApi.getCustomJsonDoc(activityContentDocId, defaultActivityContent).then(res => {
                    resolve(res.workspaceContent)
                }).catch(error => {
                    reject('')
                })
            });
        }

        // function to download project's activity in zip format
        async function downloadProjectInZip(project) {
            const activitiesList = []
            props.userActivities.forEach(item => {
                if (item.project_uid === project.project_uid) {
                    activitiesList.push(item)
                }
            })
            // create a new instance of JSZip
            const zip = new JSZip()
            // an array to store all the file promises
            const filePromises = []
            // iterate over the activitiesList and add them to the zip file
            activitiesList.forEach(item => {
                const defaultActivityContent = actOps.getDefaultActivityContent('BP')
                // create a promise for each file
                const filePromise = getActivityWorkspaceContent(item.activity_uid + '_activityContent', defaultActivityContent).then(data => {
                    zip.file(item.activity_name + '.xml', data)
                })
                // add the promise to the array
                filePromises.push(filePromise)
            })
            // wait for all the promises to resolve
            await Promise.all(filePromises);
            // generate the zip file
            const content = await zip.generateAsync({ type: "blob" });
            // create a download link for the zip file
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(content);
            link.download = "myfiles.zip";
            link.click();
        }

        if (props.userSelectedProject === null) {
            return (<>
                <table id="projects_table" className="table table-striped text-center">
                    {/* <table id="projects_table" className="table table-striped my-3 text-center"> */}
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-left">Project Name</th>
                            <th>Creation Time</th>
                            <th>Activities</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody id="projects_table_body">
                        {searchedProjects.map((project, index) => {
                            return <tr onClick={event => { event.stopPropagation(); onClickProject(project) }}>
                                <th scope="row">{index + 1}</th>
                                <td className="text-left">{project.project_name}</td>
                                <td>{dateToReadableTime(project.creation_date)}</td>
                                <td><span className="badge badge-info">{projActOps.countProjectActivities(project.project_uid)}</span></td>
                                <td><a className='a-tag-icon' onClick={event => { event.stopPropagation(); projActOps.deleteProject(project) }}><i className="fas fa-trash-alt" /></a>
                                    <a>&nbsp;</a>
                                    <a className='a-tag-icon' onClick={event => { event.stopPropagation(); downloadProjectInZip(project) }}><i className="fas fa-download" /></a>
                                </td>
                            </tr>
                        })
                        }
                    </tbody>
                </table>
            </>)
        } else {
            return (<></>)
        }
    }

    // activity table content
    function getActivityTable() {
        const searchTerm = props.userProjectsSearchTermState.trim().toLowerCase()
        let activities = projActOps.activities()

        // function to download specific activity
        async function downloadActivityAlternate(activity) {
            const defaultActivityContent = actOps.getDefaultActivityContent('BP');
            restApi.getCustomJsonDoc(activity.activity_uid + '_activityContent', defaultActivityContent).then(res => {
                var textToSaveAsBlob = new Blob([res.workspaceContent], { type: "text/xml" });
                var textToSaveAsURL = window.URL.createObjectURL(textToSaveAsBlob);
                var fileNameToSaveAs = activity.activity_name;
                var downloadLink = document.createElement("a");
                downloadLink.download = fileNameToSaveAs;
                downloadLink.innerHTML = "Download File";
                downloadLink.href = textToSaveAsURL;
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);
                downloadLink.click();
            }).catch(error => {
                // console.log("Error during activity download");
            })
        }

        if (props.userSelectedProject !== null) {
            activities = projActOps.filterProjectActivities(props.userSelectedProject.project_uid)
            activities = searchTerm === "" ? activities : activities.filter(activity => activity.activity_name.toLowerCase().includes(searchTerm))

            return (
                <table id="activities_table" className="table table-striped text-center">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-left">Activity Name</th>
                            <th>Creation Time</th>
                            <th>Last Modified</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody id="activities_table_body">
                        {
                            activities.map((activity, index) => {
                                return <tr onClick={event => { event.stopPropagation(); props.openActivity(activity) }}>
                                    <th scope="row">{index + 1}</th>
                                    <td className="text-left">{activity.activity_name}</td>
                                    <td>{dateToReadableTime(activity.creation_date)}</td>
                                    <td>{dateToReadableTime(activity.last_modified_date)}</td>
                                    <td><span className="badge badge-info">{activity.activity_type}</span></td>
                                    {/* if we remove stopPropagation(), delete will execute & table row on click will also execute.*/}
                                    <td><a className='a-tag-icon' onClick={event => { event.stopPropagation(); projActOps.deleteActivity(activity) }}><i className="fas fa-trash-alt" /></a>
                                        <a>&nbsp;</a>
                                        <a className='a-tag-icon' onClick={event => { event.stopPropagation(); downloadActivityAlternate(activity) }}><i className="fas fa-download" /></a>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            )
        } else {
            return (<></>)
        }
    }

    // function to get data from directory
    function getLocalJsonData(dir) {
        var temp_json = null;
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", dir, false);
        xmlhttp.send();
        if (xmlhttp.status == 200) {
            temp_json = JSON.parse(xmlhttp.responseText);
            return temp_json
        }
        else {
            return []
        }
    }

    // tutorial data list from directory/json file
    var allTutorialsData = getLocalJsonData('/assets/Tutorials/exampleBlocks.json') || null

    // pdf tab states
    const [pdfViewToggle, set_pdfViewToggle] = useState(false);
    const [videoViewToggle, set_videoViewToggle] = useState(false);
    const [pdfViewContent, set_pdfViewContent] = useState({});
    const [videoViewContent, set_videoViewContent] = useState({});
    const [tutorialsPdf, set_tutorialsPdf] = useState('');

    // get examples table component
    function getTutorialsTable() {
        if (props.selectedTutorial === null) {
            return (<>
                <table id="projects_table" className="table table-striped text-center">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-left">Example Name</th>
                            <th>count</th>
                        </tr>
                    </thead>
                    <tbody id="projects_table_body">
                        {allTutorialsData.map((item, i) => {
                            return (
                                <tr onClick={event => { event.stopPropagation(); props.set_selectedTutorial(item) }}>
                                    <th scope="row">{i + 1}</th>
                                    <td className="text-left">{item.name}</td>
                                    <td><span className="badge badge-info">{item.activities.length}</span></td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </>)
        } else {
            return (<></>)
        }
    }

    // get Examples Activity Table component
    function getTutorialsActivityTable() {
        // onclick function to view pdf
        function tutorialsPdfOnClick(event, activity_type, activity_name, item) {
            set_pdfViewToggle(true)
            set_pdfViewContent({ title: activity_name, file_name: item + '.pdf' })
            set_tutorialsPdf('/assets/Tutorials/' + activity_type + '/' + item + '.pdf')
        }

        // onclick function to view pdf
        function tutorialsVideoOnClick(event, item) {
            set_videoViewToggle(true)
            set_videoViewContent({ title: item.activity_name, link: item.video })
        }

        if (props.selectedTutorial !== null) {
            return (
                <table id="activities_table" className="table table-striped text-center">

                    {/* toggle for tutorialPdf */}
                    {pdfViewToggle ? (
                        <Dialog
                            open={pdfViewToggle}
                            onClose={() => set_pdfViewToggle(false)}
                            scroll="paper"
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            maxWidth="none"
                        >
                            <DialogTitle id="scroll-dialog-title">{pdfViewContent.title}</DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText
                                    id="scroll-dialog-description"
                                    // ref={descriptionElementRef}
                                    tabIndex={-1}
                                >
                                    <iframe src={tutorialsPdf} style={{ height: "100vh", width: "100vw" }} frameBorder="0"></iframe>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { set_pdfViewToggle(false) }} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : ''}

                    {/* toggle for tutorialPdf */}
                    {videoViewToggle ? (
                        <Dialog
                            open={videoViewToggle}
                            onClose={() => set_videoViewToggle(false)}
                            scroll="paper"
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            maxWidth="none"
                        >
                            <DialogTitle id="scroll-dialog-title">{videoViewContent.title}</DialogTitle>
                            <DialogContent dividers={true}>
                                <DialogContentText
                                    id="scroll-dialog-description"
                                    // ref={descriptionElementRef}
                                    tabIndex={-1}
                                >
                                    <iframe src={videoViewContent.link} style={{ height: "70vh", width: "80vw" }} autoplay frameBorder="0"></iframe>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { set_videoViewToggle(false) }} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : ''}

                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-left">Activity Name</th>
                            <th>Level</th>
                            <th>PDF</th>
                            <th>Video</th>
                        </tr>
                    </thead>
                    <tbody id="activities_table_body">
                        {props.selectedTutorial.activities.map((item, i) => {
                            return (
                                <tr onClick={event => { event.stopPropagation(); props.openExampleActivity(item) }}>
                                    <th scope="row">{i + 1}</th>
                                    <td className="text-left">{item.activity_name}</td>
                                    <td>{item.level}</td>
                                    <td>
                                        <a className='a-tag-icon' onClick={event => { event.stopPropagation(); tutorialsPdfOnClick(event, item.activity_type, item.activity_name, item.file_name) }}><i className="fas fa-file-pdf" /></a>
                                    </td>
                                    <td>
                                        <a className='a-tag-icon' onClick={event => { event.stopPropagation(); tutorialsVideoOnClick(event, item) }}><i className="fas fa-video" /></a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <>
            <div className="px-5">
                {commonActivityTabs()}
            </div>
        </>
    )
}



