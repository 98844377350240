import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function DialogCreateActivity(props) {
    const [name,set_name] = useState("")
    const [type, set_type] = useState();
    const [projectId, set_projectId] = useState();
    const [activityDialogData,set_activityDialogData] = props.data;

    useEffect(()=>{
        if(activityDialogData!==null) {
            set_type(activityDialogData.defaultActivityType)
            set_projectId(activityDialogData.defaultProjectId)
        }
    },[activityDialogData])

    if(activityDialogData===null) {
        return <></>
    }

    const handleCreate = () => {
        activityDialogData.onCreateActivity(projectId,name,type)
        handleClose()
    };

    const handleClose = () => {
        set_activityDialogData(null)
    };

    const allActivityTypes = activityDialogData.activityTypes
    const activityTypes = []
    for(const activityType in allActivityTypes) {
        const [type,name] = allActivityTypes[activityType]
        activityTypes.push( { value:type,label:name} )
    }

    const projectsToSelect = []
    activityDialogData.userProjects.forEach((project)=>{
        projectsToSelect.push({
            value:project.project_uid,
            label:project.project_name
        })
    })

    return (
        <div>
            <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>

                    <TextField
                        fullWidth
                        autoFocus
                        id="standard-required"
                        label="Activity Name"
                        type="name"
                        value={name}
                        onChange={event => {set_name(event.target.value)}}
                    />

                    <TextField
                        fullWidth
                        id="standard-select-activity"
                        select
                        label="Select Activity Type"
                        value={type}
                        onChange={ e => {set_type(e.target.value)}}
                        style={{ "margin-top":"30px" }}
                    >
                        {activityTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        fullWidth
                        id="standard-select-project"
                        select
                        label="Select Project for activity"
                        value={projectId}
                        onChange={ e => {set_projectId(e.target.value)}}
                        style={{ "margin-top":"30px" }}
                    >
                        {projectsToSelect.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCreate} color="primary">
                        Create
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
