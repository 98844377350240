import {ValidationFail} from "./Exceptions";

export function assertNotEmpty(value, errorMsg ="Empty Data" ){
    if(value===undefined || value ===null || (value+"").trim()==="")
        throw new ValidationFail(errorMsg)
}

export function assertMaxLength(value,maxLen,errMsg){
    if(value===undefined || value ===null) return false
    const len = value.length
    if(len > maxLen) {
        throw new ValidationFail(errMsg)
    }
}

export function assertMinLength(value,minLen,errMsg){
    if(value===undefined || value ===null) return false
    const len = value.length
    if(minLen < len) {
        throw new ValidationFail(errMsg)
    }
}

// values = []
export function assertValueNotIn(value,values,errMsg="Resource with same name already Present.") {
    //debugger
    values.forEach(val =>{
        //debugger
        if(val.toLowerCase().trim()===value.toLowerCase().trim()) {
            throw new ValidationFail(errMsg)
        }
    })
}

export function assertEmailID(emailId) {
    assertNotEmpty(emailId)
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(!emailId.match(emailPattern)) {
        throw new ValidationFail("Invalid Email ID")
    }
}

export function assertIsAlphaNumericWord(str, errorMessage = "Only a word (with character & numbers) is needed.") {
    if(!str.trim().match(/^[a-zA-Z0-9]+$/i)) {
        throw new ValidationFail(errorMessage)
    }
}

export function assertContainsOnlyAlphaNumericAndSpace(text, errorMsg  = `Only AlphaNumeric & space character are allowed, provided '${text}'`) {
    assertNotEmpty(text)
    const re = /^[a-zA-Z\s\d]*$/g
    if(!re.test(text))
        throw new ValidationFail(errorMsg)
}

// number of floating value
export function assertIsValidNumericValue(str,errMessage="Only numeric values are allowed.") {
    if (typeof str != "string") throw ValidationFail(errMessage) // we only process strings!
    const isValid = !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    if(!isValid)
        throw ValidationFail(errMessage)
}


export function valueChecks(value,...fns){
    for (let fn of fns) {
        fn(value)
    }
}

// TODO: try solve following complexities:

export async function tryValidate(errorPopup,ops = async ()=>{}) {
    try {
        await ops()
    } catch (e) {
        if(e.type===ValidationFail) {
            errorPopup(e.message)
        } else {
            throw e
        }
    }
}

export function tryValidateAsync(errorPopup,ops = async ()=>{}) {
    return async ()=>{
        await tryValidate(errorPopup,ops)
    }
}
