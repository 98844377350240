import { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "./LoginPage";
import { usePersistedJsonState } from "../../lib/hooks/StorageHooks";
import Header from "./Header";
import Footer from "./Footer";
import UserDashboard from "./UserDashboard/UserDashboard";
import UserActivity from "./UserActivity/UserActivity";
import ActivitySC from "./UserActivity/activityTypes/ActivitySC";
import MLModelTraining from "./UserActivity/activityTypes/mlTraining/MLModelTraining";
import FreeActivity from "./FreeActivity/FreeActivity";
import useHeaderOps from "../hooks/useHeaderOps";
import SignupPage from "./SignupPage";
import ForgetPasswordPage from "./ForgetPassword";
import ResetPasswordPage from "./ResetCode";
import { GoogleOAuthProvider } from '@react-oauth/google';
import ExampleActivity from './UserActivity/ExampleActivity';
//import appVersion from '../../'
import React from 'react';
export default function AppInit(props) {

    // handler header operations
    const headerOps = useHeaderOps(props)
    // contains header data
    const [header, set_header] = usePersistedJsonState('local_app_header', headerOps.getHeader())

    // set dummy login: {"extraInfo": [], "token": "42E7573C2669238DB89FA498F800FFD44cea6001-560d-4cb7-8e55-34f7d4b6429d", "userEmailId": "ankit@gmail.com"}
    // const [loginDetails, set_loginDetails] = usePersistedJsonState('local_app_loginDetails', {"extraInfo":[],"token":"93CE9200C4D04B8B44C2FC609D36503C1f4658ba-0e57-474f-9263-4a973ca35bc5","userEmailId":"ankit@gmail.com"})

    // contains login details (login response from server)
    const [loginDetails, set_loginDetails] = usePersistedJsonState('local_app_loginDetails', null)

    // contains user projects:
    const [userProjects, set_userProjects] = usePersistedJsonState('local_app_userProjects', null);  // clone it from openActivityDataTemplate
    // contains user activities:
    const [userActivities, set_userActivities] = usePersistedJsonState('local_app_userActivities', null);  // clone it from openActivityDataTemplate

    // contains project selected by user on dashboard:
    const [userSelectedProject, set_userSelectedProject] = usePersistedJsonState('local_app_userSelectedProject', null);  // clone it from openActivityDataTemplate
    // contains activity selected by user on dashboard:
    const [userSelectedActivity, set_userSelectedActivity] = usePersistedJsonState('local_app_userSelectedActivity', null);  // clone it from openActivityDataTemplate
    // contains selected activity content
    const [userSelectedActivityContent, set_userSelectedActivityContent] = usePersistedJsonState('local_app_userSelectedActivityContent', null);  // clone it from openActivityDataTemplate
    // contains user custom & default sprite Images
    const [spriteImages, set_spriteImages] = usePersistedJsonState('local_activity_ai_spriteImages', []);  // clone it from openActivityDataTemplate

    // contains project selected by user on dashboard:
    const [selectedTutorial, set_selectedTutorial] = usePersistedJsonState('local_app_selectedTutorial', null);

    const [tabSwitch, set_tabSwitch] = usePersistedJsonState('local_app_tab_switch', 0);

    function logout() {
        set_header(headerOps.getHeader())
        set_loginDetails(null)
        set_userProjects(null)
        set_userActivities(null)
        set_userSelectedProject(null)
        set_userSelectedActivity(null)
        set_userSelectedActivityContent(null)
        set_spriteImages([])
    }

    // all global props
    const appProps = {
        header, set_header,
        loginDetails, set_loginDetails,
        userProjects, set_userProjects,
        userActivities, set_userActivities,
        userSelectedProject, set_userSelectedProject,
        userSelectedActivity, set_userSelectedActivity,
        userSelectedActivityContent, set_userSelectedActivityContent,
        spriteImages, set_spriteImages,
        selectedTutorial, set_selectedTutorial,
        tabSwitch, set_tabSwitch,
        logout,
        ...props,
    }


    return <>
        {/* PUBLIC URL = domain name (defined in package.json. eg. "homepage": "https://dev.aiconnect.cc") */}
        <Router basename={process.env.PUBLIC_URL} >
            <Switch>

                {/* this route is selected when user enter any option defined in path[] */}
                <Route exact path={[".", "/", "/login"]}>
                    {/* User Defined */}
                    <GoogleOAuthProvider clientId="930109514230-ot50co4gffuq4ug4msad22bmkrda3o83.apps.googleusercontent.com"><LoginPage {...appProps} /></GoogleOAuthProvider>
                    {/* <LoginPage {...appProps} /> */}
                </Route>

                <Route exact path={"/signup-41251234123"}>
                    {/* User Defined */}
                    <SignupPage {...appProps} />
                </Route>
                <Route exact path={"/forget-password"}>
                    {/* User Defined */}
                    <ForgetPasswordPage {...appProps} />
                </Route>const userEmailId = loginOps.userEmailId()
                <Route exact path={"/reset-password"}>
                    {/* User Defined */}
                    <ResetPasswordPage {...appProps} />
                </Route>

                {/* all elements in this fragment have common header & footer */}
                <Fragment>
                    {/* all pages, in this fragment, have this header */}
                    {/* User Defined */}
                    <Header {...appProps} />  

                    <Route exact path={"/dashboard"}>
                        {/* When user route to /dashboard, UserDashboard will render */}
                        {/* User Defined */}
                        <UserDashboard {...appProps} />
                    </Route>
                    <Route exact path={"/activity"}>
                        {/* User Defined */}
                        <UserActivity {...appProps} />
                    </Route>
                    <Route exact path={"/examples"}>
                        {/* User Defined */}
                        <ExampleActivity {...appProps} />
                    </Route>
                    <Route exact path={"/activity/scratch-coding"}>
                        {/* User Defined */}
                        <ActivitySC {...appProps} />
                    </Route>
                    <Route exact path={"/activity/ml-model-training"}>
                        {/* User Defined */}
                        <MLModelTraining {...appProps} />
                    </Route>
                    <Route exact path={"/free-activity"}>
                        {/* User Defined */}
                        <FreeActivity {...appProps} />
                    </Route>
                    {/* all pages, in this fragment, have this footer */}
                    {/* User Defined */}
                    <Footer />
                </Fragment>
            </Switch>
        </Router>
    </>

}

