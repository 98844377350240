import { useEffect, useState } from 'react'
import useCustomActivityOps, { globalActivityCallbacks } from "../../../../hooks/useCustomActivityOps";
import { cloneElement } from "../../../../../lib/js/jsUtil";
import { useHistory } from "react-router-dom";
import useProjectAndActivityOps from "../../../../hooks/useProjectAndActivityOps";
import { appData } from "../../../../../app/util/appData";
import DialogCreateActivity from "../../../../../app/component/UserDashboard/components/DialogCreateActivity";
import React from 'react';

export default function useActivityCommonWorkspaceTC(props, setupCustomActivityWorkspace) {
    // uses operation to redirect
    const history = useHistory();
    const [activityDialogData, set_activityDialogData] = useState(null)
    const actOps = useCustomActivityOps(props)
    const projActOps = useProjectAndActivityOps(props)
    // handle frame loading state
    const [frameLoaded, set_frameLoaded] = useState(false)
    // get element by ID from html page defined in IFrame
    function getActivityIFrame() {
        return document.getElementById('wrkspc_frame');
    }

    // dialog box for new activity create or perform save-as operation
    function openCreateActivityDialog(defaultActivityType) {
        const iframe = getActivityIFrame();
        const frm_window = iframe.contentWindow
        set_activityDialogData({
            onCreateActivity(project_uid, activityName, activityType) {
                const stringXml = frm_window.Ardublockly.generateXml()
                projActOps.createNewActivity(project_uid, activityName, activityType, stringXml);
            },
            activityTypes: appData.activity.types,
            userProjects: props.userProjects,
            defaultActivityType: defaultActivityType != null ? defaultActivityType : appData.activity.types.TC[0],
            defaultProjectId: props.userSelectedProject != null ? props.userSelectedProject.project_uid : props.userProjects[0].project_uid,
        })
    }

    // get xml blockly-workspace content
    function getWorkspaceContent() {
        const iframe = getActivityIFrame();
        const frm_window = iframe.contentWindow
        const workspace = frm_window.blockpy.components.editor.blockly
        const xml = frm_window.Blockly.Xml
        const xmlDom = xml.workspaceToDom(workspace)
        const stringXml = xml.domToText(xmlDom)
        return stringXml
    }

    // set xml blockly-workspace content
    function loadWorkspaceContent(xmlContent) {
        const iframe = getActivityIFrame();
        const frm_window = iframe.contentWindow

        var success = frm_window.Ardublockly.replaceBlocksfromXml(xmlContent);
        if (success) {
            frm_window.Ardublockly.renderContent();
            // workspace.sketchNameSet(filename);
        } else {
            frm_window.Ardublockly.alertMessage(
                frm_window.Ardublockly.getLocalStr('invalidXmlTitle'),
                frm_window.Ardublockly.getLocalStr('invalidXmlBody'),
                false);
        }
    }


    // this function setup 'activityBlocklyContentFetcher' so that,
    // activity content can be fetched from Ops class as well:
    function setupActivityContentFetcher(frm_window) {
        globalActivityCallbacks.getActivityBlocklyXMLContent = () => {
            return frm_window.Ardublockly.generateXml()
        }
    }

    // perform tasks when IFrame is loaded for the first time:
    function onLoadFrame() {
        const iframe = getActivityIFrame();

        const frm_window = iframe.contentWindow
        const frm_document = iframe.contentDocument
        setupActivityContentFetcher(frm_window)

        // setup common save button:
        cloneElement(frm_document.getElementById("button_save")).addEventListener("click", function () {
            if (props.userSelectedActivity.activity_uid) {
                const stringXml = frm_window.Ardublockly.generateXml()
                // update activity blockly-content on server
                actOps.updateActivityContentDocViaClone(
                    clone => clone.workspaceContent = stringXml,
                    // with activity_Content clone, update workspaceContent
                    () => props.toastSuccess("Saved Successfully.")
                    // when update on server, toast successful message.
                )
            }
            else {
                // props.toastWarning("First create new activity")
                openCreateActivityDialog(props.userSelectedActivity.activity_type)
            }
        });

        // setup common home button:
        cloneElement(frm_document.getElementById("btn-home")).addEventListener("click", function () {
            history.push("/dashboard")
        });

        // save as button event listener from blockly workspace
        cloneElement(frm_document.getElementById("button_save_as")).addEventListener("click", function () {
            // props.inpDiag.receiveInput({
            //     onReceive(newActivityName) {
            //         projActOps.saveAsNewActivity(newActivityName)
            //     }, title: "Save Activity As", inputLabel: "Enter activity name", okBtnText: "Save"
            // })
            openCreateActivityDialog(props.userSelectedActivity.activity_type)
        });

        // setup common about button:
        cloneElement(frm_document.getElementById("button_about")).addEventListener("click", function () {
            let actAbout = actOps.getUserSelectedActivityContent().about
            // ask for confirmation about editing the 'about'.
            props.confirmDiagOps.showConfirmationDialog("About Activity", actAbout, "Edit", () => {
                // on Edit: receive new user Input:
                props.inpDiag.receiveInput({
                    onReceive(aboutNote) {
                        // on receive new about:
                        // update the about
                        actOps.updateActivityContentDocViaClone(clone => {
                            clone.about = aboutNote
                        }, () => {
                            props.toastSuccess("Saved Successfully.")
                        })
                    }, value: actAbout, okBtnText: "Save", title: "Describe Activity", inputLabel: ""
                })
            })
        });

        // following function will be called for every activity to set
        // it's specific custom buttons. Like (train button AI, etc)
        setupCustomActivityWorkspace(iframe)
        // finally, update the blockly workspace content to saved activity content workspace:
        loadWorkspaceContent(actOps.getUserSelectedActivityContent().workspaceContent)
    }


    if (!frameLoaded) {
        // if frame is not loaded & view is being visible, show the fullscreen loading
        props.fullScreenLoading.show("Loading Workspace...")
    } else {
        // if frame is loaded, hide the fullscreen loading
        props.fullScreenLoading.forceClose()
    }

    // currently fetched activity workspace content
    const content = actOps.getUserSelectedActivityContent().workspaceContent
    useEffect(() => {
        if (frameLoaded)
            // if iframe is loaded, update the content
            loadWorkspaceContent(actOps.getUserSelectedActivityContent().workspaceContent)
    }, [frameLoaded, content])
    // if frameLoaded dependency changes, or anything in
    // content changes, simply update the blockly workspace content.

    // setup buttons when frame loads for first time.
    useEffect(() => {
        if (frameLoaded) {
            // setup buttons, when frame loads for the first time:
            onLoadFrame()
        }
        // do this whenever frameLoaded state changes
        // or when activity content changes
    }, [frameLoaded, props.userSelectedActivityContent])


    // this will be called as callback from IFrame html:
    async function onFrameLoadFinish() {
        set_frameLoaded(true)
    }

    // loads the provided html page in IFrame, and return the create html:
    function getBlocklyWorkspaceFrameTC(activityHTMLPageName) {
        return <section id="frame_dynamic_content" style={{ "background-color": "#2f3f89" }}>
            <DialogCreateActivity data={[activityDialogData, set_activityDialogData]} title="Save As" />
            <iframe
                src={process.env.PUBLIC_URL + "/tinkerorbit-frontend/" + activityHTMLPageName + "?" + props.appVersion}
                id="wrkspc_frame"
                scrolling="no"
                onLoad={async e => {
                    // calls following to setup iFrame
                    await onFrameLoadFinish()
                }}
            />
        </section>
    }

    return {
        getActivityIFrame,
        getBlocklyWorkspaceFrameTC,
    }
}
