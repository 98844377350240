import React, {useState} from 'react'
import useActivityCommonWorkspace from "./common/useActivityCommonWorkspace";
import GridDialog from "./dialogs/GridDialog";
import ShowProcessedImageDialog from "./dialogs/ShowProcessedImageDialog";
import {cloneElement} from "../../../../lib/js/jsUtil";

export default function ActivityGD(props) {
    const [showGridImageDialog, set_showGridImageDialog] = useState(false);
    // set custom BP specific buttons:
    function setupCustomActivityWorkspace(iframe) {
        // Basic Python don't have specific buttons to set. It only
        // uses the common buttons defined in 'useActivityCommonWorkspace' file.
        const frm_window = iframe.contentWindow
        const frm_document = iframe.contentDocument
        cloneElement(frm_document.getElementById("btn-sprite")).addEventListener("click", function () {
            set_showGridImageDialog(true)
        })
    }

    const {
        getBlocklyWorkspaceFrame    // get a function from common functionality
        // to load the IFrame
    } = useActivityCommonWorkspace(props,setupCustomActivityWorkspace)

    // loads the provided html file in IFrame
    return (<>

        {/* Show Sprite Images Grid dialog */}
        <GridDialog {...props} {...{showGridImageDialog,set_showGridImageDialog}} />
        {/* Show processed image output in dialog. */}
        <ShowProcessedImageDialog />
        {/* Loads the html page in iframe */}
        {getBlocklyWorkspaceFrame("python_graphics.html")}
    </>)
}
