import React, {useEffect, useRef, useState} from 'react'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Webcam from "react-webcam";
import useCustomActivityOps from "../../../../hooks/useCustomActivityOps";

// read image in base64 format string:
function getBase64(file,onImg,onErr) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        onImg(reader.result);
    };
    reader.onerror = function (error) {
        onErr("Unable to process image. Make sure image file is ok.")
    };
}

export default function CaptureImageDialog(props) {
    // do operations with activity content:
    const actOps = useCustomActivityOps(props)

    // maintain visibility of this dialog:
    const showCaptureImageDialog = props.showCaptureImageDialog
    const set_showCaptureImageDialog = props.set_showCaptureImageDialog

    // used in opening file system to select an image file:
    const inputFile = useRef(null)

    // set captured image base64 in src or url of the image:
    const [imageSrc, set_imageSrc] = useState("")

    // reference to webcam
    const webcamRef = React.useRef(null);

    // when we call capture, webcam image (base64) will be set in image src.
    const capture = React.useCallback(
        async () => {
            const imageSrc = webcamRef.current.getScreenshot();
            set_imageSrc(imageSrc)
        },
        [webcamRef] // whenever anything changes in webcam, callback function updates
    );

    // get previous / uploaded image url (saved in Activity content)
    const image = actOps.getUserSelectedActivityContent().captureImageUrlPath
    useEffect(()=>{
        if(image!=null)
            // set image url to image src:
            set_imageSrc(actOps.getImageRemotePath(image))
        else
            set_imageSrc(image)
    },[image]) // when ever upload images changes, the code block changes.

    if(showCaptureImageDialog===false) return <></>
    // if dialog visibility is false -> return empty html


    // config camera:
    const videoConstraints = {
        width: 360,
        height: 200,
        facingMode: "user"
    };

    // upload the image to server:
    async function upload() {
        if(imageSrc===null || imageSrc===undefined) {
            props.toastWarning("Capture or Browse an image first.")
            return
        }
        // if imageSrc is base64 and not a URL:
        if(imageSrc!=="" && imageSrc.startsWith("data:")) {
            // use this function to upload base64 image:
            actOps.uploadBase64Image(imageSrc,(remoteFileUrl)=>{
                // once file uploaded, we get the uploaded file url:
                set_imageSrc(remoteFileUrl) // set uploaded file url in imageSrc
                props.toastSuccess("Image uploaded.")
                handleClose()   // close the dialog
            })
        } else {
            if(imageSrc!=="")   // this means, image src is Image URL:
                props.toastInfo("Image Already uploaded.")
            handleClose()   // close dialog
        }
    }

    async function browse(){    // on click browse button open file system to select file:
        inputFile.current.click();
    }

    // on select image file:
    function onSelectFile(event){
        if(event.target.files.length>0) {
            const file = event.target.files[0];

            // console.log(file.size)
            // file.size = byte
            if( "image/png, image/jpg, image/jpeg".indexOf(file.type)===-1  || file.size>100000) {
                props.toastWarning("Only png/jpg/jpeg files (<=100kb) can be uploaded.")
            } else {   // 100 kb file
                // get base64 string of image:
                getBase64(file,(base64Img)=>{
                    // set base64 to image src
                    set_imageSrc(base64Img)
                },(err)=>{
                    // if any error occur in getting base64, show the error:
                    props.toastError(err)
                })
            }
            // clear last selected file
            inputFile.current.value=""  // clear
        }
    }

    // close the dialog:
    const handleClose = ()=>{
        set_showCaptureImageDialog(false)
    }

    // console.log("Image Src : "+imageSrc)

    return (
        <>
                {/* used in browse & select file */}
                <input type='file' id='file' ref={inputFile} style={{display: 'none'}} accept="image/png, image/jpg, image/jpeg" onChange={onSelectFile}/>
                {/* show capture dialog: */}
                <Dialog open={showCaptureImageDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Capture Image</DialogTitle>
                    <DialogContent>
                        <div>
                            <b><u>Webcam:</u></b>
                            <div id="my_camera" className="col">
                                <Webcam
                                    audio={false}
                                    height={200}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    width={320}
                                    videoConstraints={videoConstraints}
                                />
                            </div>
                            <br/>
                            <b><u>Captured Image:</u></b>
                            <div id="my_result" className="col">
                                {imageSrc===null || imageSrc===""?(""):(<img src={imageSrc}/>)}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={browse} color="primary">
                            Browse
                        </Button>
                        <Button onClick={capture} color="primary">
                            Capture
                        </Button>
                        <Button onClick={upload} color="primary">
                            Upload
                        </Button>
                        <Button onClick={handleClose} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

        </>
    )
}