import React, {useContext, useState} from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const inputDialogCallback = { setOpen : (data={})=>{} }

export const ops = {

    // following can be invoked from anywhere
    receiveInput({onReceive=(input)=>{},title="Input",inputLabel="Enter here",inputType="name",value="", name="name", okBtnText="OK", cancelBtnTxt="Cancel"}) {
        inputDialogCallback.setOpen({
            onReceive,title,inputLabel,inputType,value,name,okBtnText,cancelBtnTxt
        })
    },
}




export default function InputDialog() {
    //console.log("InputDialog Render")

    const [open,set_open] = useState(false)
    const [input,set_input] = useState("")
    const [data,set_data] = useState(null)


    // override invoking first
    inputDialogCallback.setOpen = (states)=>{
        set_open(true)
        set_data(states)
    }

    if(data===null) return <></>


    if(data.value!=="") {
        set_input(data.value)
        const clone = {...data}
        clone.value=""
        set_data(clone)
    }



    const handleCreate = () => {
        data.onReceive(input)
        handleClose()
    };
    const handleClose = () => {
        set_data(null)
        set_input("")
        set_open(false)
    };


    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{data.title}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id={data.name}
                        label={data.inputLabel}
                        type={data.inputType}
                        fullWidth
                        value={input}
                        onChange={event => {set_input(event.target.value)}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreate} color="primary">
                        {data.okBtnText}
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        {data.cancelBtnTxt}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}