import React from 'react'
import {arraysExactMatch} from "../../../../../lib/js/jsUtil";
import ChipsArray from "../../../custom/Chips";
import {useMLTrainingOps} from "../../../../hooks/useMLTrainingOps";

export default function NumberProperties(props) {

    const mlTrainOps = useMLTrainingOps(props)
    const [chipData, set_chipData] = React.useState(mlTrainOps.getTrainingJsonContent().value_names);
    if(!arraysExactMatch(chipData,mlTrainOps.getTrainingJsonContent().value_names)) {
        set_chipData(mlTrainOps.getTrainingJsonContent().value_names)
    }

    return (
        <>
            <div id="model_properties_box" className="form-group">
                <label>Enter some ML Model properties: <i className="fas fa-exclamation-circle"
                                                          data-toggle="tooltip" data-placement="top"
                                                          data-html="true" title=""
                                                          data-original-title="Properties are immutable. Read the note below."/></label>
                <div>
                    {/* Enter Property button: */}
                    <button id="btn_train" className="btn btn-info" onClick={event => {
                            props.inpDiag.receiveInput({onReceive(input){
                                    mlTrainOps.addNewProperty(input.trim())
                                },title:"Enter Property Name",okBtnText:"Add Property"})
                    }}>Enter Property</button>
                    <div>

                        <ChipsArray data={{chipData,set_chipData}} onDeleteChip={(chipName)=>{
                            if(mlTrainOps.getTrainingJsonContent().value_names.length>1) {
                                mlTrainOps.deleteNumericProperty(chipName.trim())
                            } else {
                                props.toastWarning("You cannot delete all the properties.")
                            }
                        }} onClickChip={(chipName)=>{
                            props.inpDiag.receiveInput({onReceive(newName){
                                    mlTrainOps.updateNumericPropertyName(chipName.trim(),newName.trim())
                                },title:`Change property ('${chipName}') Name `, value:chipName, okBtnText:"Change"})
                        }} />

                    </div>
                </div>
            </div>
        </>
    )
}