import useCallWraps from "./useCallWraps";
import {hasKeys, itrOnObjKeys} from "../../lib/js/jsUtil";
import {
    assertContainsOnlyAlphaNumericAndSpace,
    assertIsAlphaNumericWord, assertIsValidNumericValue,
    assertMaxLength,
    assertNotEmpty,
    assertValueNotIn
} from "../../lib/js/FormValidationUtil";
import useCustomActivityOps from "./useCustomActivityOps";
import {ValidationFail} from "../../lib/js/Exceptions";
import useAiConnectRestApi from "./useAiConnectRestApi";



export const useMLTrainingOps = (props) => {
    // utility functions:
    const call = useCallWraps(props)
    // activity content utility functions:
    const actOps = useCustomActivityOps(props)

    // restApi functions:
    const restApi = useAiConnectRestApi(props)

    const features = {
        // return the json training content from activity content
        getTrainingJsonContent() {
            return props.userSelectedActivityContent.trainingJsonContent
        },

        // create a clone of json training content to perform manipulations
        withClone(ops) {
            const clone = {...this.getTrainingJsonContent()}    // create clone
            ops(clone)  // modify clone
            // update clone:
            actOps.withClone(mainClone=>{
                mainClone.trainingJsonContent = clone
            })
        },

        // value_names in json -> properties of recognition type
        numericLabelValueNames() {
            return this.getTrainingJsonContent().value_names;
        },

        // tell if json content has labels
        hasLabels() {
            return hasKeys(this.getTrainingJsonContent().labels)
        },

        // return all label names
        getLabelNames() {
          const names = []  // create empty array
          itrOnObjKeys(this.getTrainingJsonContent().labels,(k,v,i)=>{
              names.push(v.name)  // add all label names
          })
          return names  // return names
        },

        // get labels with some property values present, and return their count:
        getFilledLabelsCount(jsonContent,recogniseType) {
            const labels = new Set()
            if(recogniseType==="number") {
                itrOnObjKeys(jsonContent.labels,(k,v,i)=>{
                    v.values.forEach((val,i)=>{
                        if(val.value.length>0) {
                            labels.add(v.name)
                        }
                    })
                })
            } else {
                itrOnObjKeys(jsonContent.labels,(k,v,i)=>{
                    if(v.values.length>0) {
                        labels.add(v.name)
                    }
                })
            }
            return labels.size
        },

        // validate property name
        validateProperty: function (property) {
            assertNotEmpty(property,"Empty value cannot be set as property name.")
            assertValueNotIn(property, this.getTrainingJsonContent().value_names, "Value with the same name already exists.")
            assertMaxLength(property, 20, "Property name should not be more than 20 characters.")
            assertIsAlphaNumericWord(property,"Property name should be 1 alphanumeric word.")
        },

        // validate label name
        validateLabel: function (label) {
            //debugger
            assertNotEmpty(label,"Empty value cannot be set as label.")
            assertValueNotIn(label, this.getLabelNames(), "Value with the same name already exists.")
            assertMaxLength(label, 50, "Label name should not be more than 50 characters.")
            assertContainsOnlyAlphaNumericAndSpace(label,"Only characters, spaces & numbers are allowed in Label.")
        },

        // validate textual property value
        validateTextualPropertyValue: function (newValue) {
            assertNotEmpty(newValue,"Empty value cannot be set as property value.")
            assertMaxLength(newValue, 70, "Property value should not be more than 70 characters.")
            assertContainsOnlyAlphaNumericAndSpace(newValue,"Only characters, spaces & numbers are allowed.")
        },

        // validate numeric property value
        validateNumericPropertyValue: function (newValue) {
            assertNotEmpty(newValue,"Empty value cannot be set as property value.")
            assertMaxLength(newValue, 12, "Value should not be more then 12 digits.")
            assertIsValidNumericValue(newValue,"Only numeric values are allowed.")
        },

        // add new property in numeric content:
        addNewProperty(property) {
            call.validationCheck(()=>{
                this.validateProperty(property);

                this.withClone((clone)=>{
                    clone.value_names.push(property)
                    itrOnObjKeys(clone.labels,(key,value,idx)=>{
                        value.values.forEach((valItem,idx)=>{
                            valItem.value.push({
                                name:property,
                                value:0 // default value of the current property is set to 0
                            })
                        })
                    })
                })
            })
        },

        // delete a numeric property from all label values:
        deleteNumericProperty(property) {
            this.withClone((clone)=>{
                clone.value_names.splice(
                    clone.value_names.indexOf(property),
                    1
                )
                itrOnObjKeys(clone.labels,(k,v,i)=>{
                    v.values.forEach((valObj,i)=>{
                        valObj.value = valObj.value.filter((v)=>v.name!==property)
                    })
                })
            })
        },


        // change numeric property name to new name in all labels:
        updateNumericPropertyName(oldPropertyName, newPropertyName) {
            call.validationCheck(()=>{
                this.validateProperty(newPropertyName);

                this.withClone((clone)=>{
                    clone.value_names.splice(
                        clone.value_names.indexOf(oldPropertyName),
                        1,
                        newPropertyName
                    )
                    itrOnObjKeys(clone.labels,(k,v,i)=>{
                        v.values.forEach((valObj,i)=>{
                            valObj.value.forEach((obj)=>{
                                if(obj.name===oldPropertyName)
                                    obj.name = newPropertyName
                            })
                        })
                    })
                })
            })
        },

        // process to add label
        addLabel() {
            props.inpDiag.receiveInput({onReceive:(newLabelName)=>{
                    call.validationCheck(()=>{
                        // validate received label name:
                        features.validateLabel(newLabelName)
                        // add new label in cloned object of jsonTrainingContent
                        features.withClone( clone => {
                            clone.labels['label_'+new Date().getTime()] = {
                                name:newLabelName,
                                values : []
                            }
                        })
                    })
                },title:"Create new Label",inputLabel:"Label Name",okBtnText:"Create"})
        },

        // update Label name from old to new:
        updateLabelName(oldName, updateUsingClone) {
            props.inpDiag.receiveInput({onReceive(newValue){
                    //debugger
                    call.validationCheck(()=>{
                        features.validateLabel(newValue);
                        features.withClone(clone=>{
                            updateUsingClone(clone,newValue)
                        })
                    })
                }, title:"Update Label", inputLabel:"Update Label Name", value:oldName, okBtnText:"Update"})
        },

        // delete a label
        deleteLabel(labelId) {
            this.withClone(clone=>{
                // every label has unique ID, it remove label based on unique ID.
                delete clone.labels[labelId]
            })
        },

        // delete a value from label:
        deleteLabelValue(labelId, valuesIdx) {
            this.withClone(clone=>{
                // delete label value based on label_id & value index
                delete clone.labels[labelId].values[valuesIdx]
            })
        },

        // change numeric value data:
        updateLabelDataNumericValue(labelId, valueIdx, idx, newValue) {
            call.validationCheck(()=>{
                this.validateNumericPropertyValue(newValue)
                this.withClone(clone=>{
                    clone.labels[labelId].values[valueIdx].value[idx].value=newValue;
                })
            })
        },

        // change textual data value:
        updateLabelDataTextualValue(labelId, valueIdx, newValue) {
            call.validationCheck(()=>{
                //debugger
                this.validateTextualPropertyValue(newValue)
                this.withClone(clone=>{
                    clone.labels[labelId].values[valueIdx]=newValue;
                })
            })
        },

        // add value in numeric type label:
        addNumericLabelValues(labelId,valueArr) {
            call.validationCheck(()=>{
                valueArr.forEach(val=> this.validateNumericPropertyValue(val))
                this.withClone(clone=>{
                    const value = []
                    this.numericLabelValueNames().forEach((key,i)=>{
                        value.push({
                            name: key,
                            value: valueArr[i],
                        })
                    })
                    clone.labels[labelId].values.push({ value : value})
                })
            })
        },

        addTextualLabelValues(labelId,value) {
            call.validationCheck(()=>{
                this.validateTextualPropertyValue(value)
                this.withClone(clone=>{
                    clone.labels[labelId].values.push(value)
                })
            })
        },


        // perform model training:
        trainModel(onFinish=()=>{}) {

            // function return the prepared CSV content:
            function prepareCSV(recogniseType,trainingJson) {
                const csvContent = []
                if(recogniseType==="number") {
                    csvContent.push(trainingJson.value_names.join(",")+", label")
                    itrOnObjKeys(trainingJson.labels,(k,v,i)=>{
                        v.values.forEach((val,i)=>{
                            if(val.value.length>0) {
                                const propValues = val.value.map((propVal)=>{ return propVal.value})
                                csvContent.push(propValues.join(',')+','+v.name)
                            }
                        })
                    })
                } else {
                    csvContent.push("Value, label")
                    itrOnObjKeys(trainingJson.labels,(k,v,i)=>{
                        v.values.forEach((val)=>{
                            csvContent.push(`${v.name}, ${val}`)
                        })
                    })
                }
                return csvContent.join("\n")
            }


            // function return the prepared config file content, to be used in blockly
            function prepareConfig(recogniseType,trainingJson) {
                let type = ""
                let configContent = {}
                configContent.all_labels = features.getLabelNames()
                if(recogniseType==="number") {
                    type = "Numbers"
                    configContent.all_fields = trainingJson.value_names.map((v)=>{ return {"name":v, "type":"number"} })
                } else {
                    type = "Text"
                    configContent.all_fields = [{"name":"Value","type":"text"}]
                }
                return JSON.stringify([type,configContent])
            }


            call.validationCheck(()=>{
                const trainingJson = this.getTrainingJsonContent()
                const recogniseType = trainingJson.recognise

                const filledLabels = this.getFilledLabelsCount(trainingJson,recogniseType)
                if(filledLabels < 2) {
                    throw new ValidationFail("At least two labels with non-empty values are needed to work with.")
                }
                const csv = prepareCSV(recogniseType,trainingJson)
                const config = prepareConfig(recogniseType,trainingJson)

                call.asyncThen(call.request(async ()=>{
                    const fileResponse = await restApi.runMLCSVModel(csv,recogniseType)
                    const modelFile = fileResponse.file

                    actOps.updateActivityContentDocViaClone(clone=> {
                        clone.trainingJsonContent = trainingJson
                        clone.model_file = modelFile
                        clone.csv = csv
                        clone.config = config
                        clone.isTrained = true
                    },()=>{
                        props.toastSuccess("Model build successfully.")
                        onFinish()
                    })
                }))
            })
        }
    }

    return features
};