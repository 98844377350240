import React, {useContext, useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';


const toastCallback = { setOpen(custom={}){}}
export const toastOps = {
    toastInfo(text,custom={}){
        toastCallback.setOpen({
            severity:state.choose_severity.info,    // use choose_severity.value to assign this value
            text,
            ...custom
        })
    },
    toastSuccess(text,custom={}){
        toastCallback.setOpen({
            severity:state.choose_severity.success,    // use choose_severity.value to assign this value
            text,
            ...custom
        })
    },
    toastError(text,custom={}){
        toastCallback.setOpen({
            severity:state.choose_severity.error,    // use choose_severity.value to assign this value
            text,
            ...custom
        })
    },
    toastWarning(text,custom={}){
        toastCallback.setOpen({
            severity:state.choose_severity.warning,    // use choose_severity.value to assign this value
            text,
            ...custom
        })
    },
}

const state = {
    choose_severity : {
        success : "success",
        error : "error",
        warning : "warning",
        info : "info",
    },
    // customizable params:
    autoHideDuration : 3000,
    severity:"",    // use choose_severity.value to assign this value
    text:"",
    vertical:"top",
    horizontal:"right",
}



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

/*
severity : success,error,warning,info,success,
 */

export default function Alerts() {
    //console.log("Alerts Rendered")


    const classes = useStyles();

    const [open,set_open] = useState(false);
    const [data,set_data] = useState(state);

    toastCallback.setOpen = (custom)=>{
        set_open(true)
        set_data({...state,...custom})
    }


    const handleClose = () => {
        set_open(false)
    };

    const pos = {
        vertical : data.vertical,
        horizontal : data.horizontal
    }

    return (
        <div className={classes.root}>
            <Snackbar
                open={open}
                anchorOrigin={pos}
                autoHideDuration={data.autoHideDuration}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={data.severity}>
                    {data.text}
                </Alert>
            </Snackbar>
        </div>
    );
}
