import React from "react";
import {CardColumns} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Link from "@material-ui/core/Link";
import {multiValueReceiveDialogOps} from "./dialogs/MultiValueReceiveDialog";
import {useMLTrainingOps} from "../../../../hooks/useMLTrainingOps";
import {itrOnObjKeys} from "../../../../../lib/js/jsUtil";

// manage text recognition labels:
export default function TextRecognitionLabels(props) {
    // mltrain related ops
    const mlTrainOps = useMLTrainingOps(props)

    return (
        <>
            {/* show labels in column */}
            <CardColumns>
                {/*Labels goes here  */}
                {/* for each label */}
                {itrOnObjKeys(mlTrainOps.getTrainingJsonContent().labels, (labelId, labelObj, i) => {
                    return (
                        <Card>
                            <div className="my-card">
                                <div className="card-header text-center">
                                    <span onClick={event => {
                                        // change label name on click label
                                        mlTrainOps.updateLabelName(labelObj.name, (clone, newValue) => {
                                            clone.labels[labelId].name = newValue;
                                        })
                                    }}
                                    >{labelObj.name}</span>
                                    <Link className="deletetext delete" onClick={() => {
                                        // delete label from json
                                        mlTrainOps.deleteLabel(labelId)
                                    }}><i className="far fa-window-close"/></Link>
                                </div>
                                <div className="card-body trainingbucketitems">

                                    {labelObj.values.map((value, valueIdx) => {
                                        return <div className="trainingbucketitem">
                                            <Link className="deletetext delete" onClick={() => {
                                                // delete label value from json
                                                mlTrainOps.deleteLabelValue(labelId, valueIdx)
                                            }}><i className="far fa-window-close"/></Link>
                                            <table>

                                                <tbody>
                                                {/*<tr onClick={() => {onClickLabelValue(labelId,value,valueIdx)}} >*/}
                                                <tr onClick={event => {
                                                    // on click value, update value:
                                                    props.inpDiag.receiveInput({
                                                        onReceive(newValue) {
                                                            //debugger
                                                            mlTrainOps.updateLabelDataTextualValue(labelId, valueIdx, newValue)
                                                        },
                                                        title: "Update Value",
                                                        inputType: "name",
                                                        inputLabel: "Provide updated value",
                                                        value: value,
                                                        okBtnText: "Update"
                                                    })
                                                }}>
                                                    <td className="numberdatalabel">Value</td>
                                                    <td className="numberdatavalue">{value}</td>
                                                </tr>
                                                </tbody>

                                            </table>
                                            <hr/>
                                        </div>
                                    })}

                                </div>
                                <div className="row justify-content-center my-3">
                                    <button className="btn btn-info" onClick={event => {
                                        // add value in label:
                                        multiValueReceiveDialogOps.receiveInput({
                                            onReceive(valuesArr) {
                                                if (valuesArr.length > 0) {
                                                    mlTrainOps.addTextualLabelValues(labelId, valuesArr[0])
                                                }
                                            }, fieldNames: ["Value"], labelName: labelObj.name
                                        })
                                    }}>Add Values
                                    </button>
                                </div>
                            </div>
                        </Card>
                    )
                })}
            </CardColumns>
        </>
    )
}